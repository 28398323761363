<template>
    <div style="border:0px solid black">
        <div style="margin:auto;text-align: center;font-weight: bold;">{{tool.name}} Tool </div>
        <TargetStructureName />
        <table>        
        <tr>
            <td><label>End:</label></td><td><input id="skirtEndLeft" type="radio" name="skirtend" value="leftEnd" v-model="end" :disabled="_isGableTieInStructure"/><label for="skirtEndLeft">Left</label><input id="skirtEndRight"  type="radio" name="skirtend" value="rightEnd" v-model="end"/><label for="skirtEndRight">Right</label></td>
        </tr>
        <tr>
            <td><label>Height (in):</label></td><td><input :disabled="isSkirtBeingRemoved" type="number" v-model="height"/></td>
        </tr>
        <tr>
            <td><label>Bay count:</label></td><td><input :min="0" :max="maxBayCount" type="number" v-model="bays"/></td>
        </tr>
        <tr>
            <td><label for="includeFront">Skirt Front:</label></td><td><input :disabled="isSkirtBeingRemoved" type="checkbox" id="includeFront" v-model="includeFront"/></td>
        </tr>
        <tr>
            <td><label for="includeBack">Skirt Back:</label></td><td><input :disabled="isSkirtBeingRemoved" type="checkbox" id="includeBack" v-model="includeBack"/></td>
        </tr>
        <tr>
            <td><label for="includeEnd">Skirt end wall:</label></td><td><input :disabled="isSkirtBeingRemoved" type="checkbox" id="includeEnd" v-model="includeEnd"/></td>
        </tr>
        </table>
        <div style="display:flex; justify-content: space-evenly;padding:4px;">
            
            <button class="btn cancel" @click="cancel">Cancel</button>
            <button class="btn btnGreen" :disabled="isSkirtToolDisabled" @click="apply">Apply</button>
        </div>

    </div>
</template>

<script>
import { CORE } from '../js/_spec';
import StructureHelper from '../js/helpers/StructureHelper';
import TargetStructureName from './targetStructureName.vue';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

export default {
    props:{
        iTool: Object,
        },
    computed:{
        ...mapState('designModule',[
            'tool',
        ]),
        ...mapGetters('designModule', [
            
            'structure',
            'isGableTieInStructure'
        ]),

        _isGableTieInStructure(){
            let isGableTieIn = this.isGableTieInStructure;
            this.end = isGableTieIn ? CORE.sides.rightEnd : CORE.sides.leftEnd;
            return isGableTieIn
        },
        maxBayCount(){
            let structure = this.structure;
            let bayMaxCount=0;

            // To find the number of remaining bays we can skirt on one end,
            // we need to find the number of skirted bays on the opposite end.
            let startFromLeftWall = (this.end === CORE.sides.leftEnd) ? false : true;
            let fswBays = structure.design.getFrontWall().getFrontSideBays();
            let bswBays = structure.design.getBackWall().getBackSideBays();
            let fswInsetBayCount = StructureHelper.getSkirtedInsetSideWallBayCount(startFromLeftWall, structure.design, fswBays);
            let bswInsetBayCount = StructureHelper.getSkirtedInsetSideWallBayCount(startFromLeftWall, structure.design, bswBays);
            let totalBayCount = fswInsetBayCount >= bswInsetBayCount ? fswBays.length : bswBays.length;

            if(this.end === CORE.sides.leftEnd)
                bayMaxCount = (totalBayCount - Math.max(fswInsetBayCount, bswInsetBayCount)) - 1

            else
                bayMaxCount = (totalBayCount - Math.max(fswInsetBayCount, bswInsetBayCount)) - 1

            //this.bays = bayMaxCount > 0 ? 1 : 0;*/
            return bayMaxCount < 0 ? 0 : bayMaxCount;
            
        },
        isSkirtToolDisabled(){
            if(this.bays <= this.maxBayCount){
                return false;
            }

            return true;
        },
        isSkirtBeingRemoved(){
            if(this.bays == 0)
                return true;

            return false;
        }
    },
    data(){
        return {
            end: CORE.sides.leftEnd,
            height: 0,
            bays: 0,
            includeEnd: true,
            includeFront: true,
            includeBack: true,
            isLeftSide: true,
        }
    },
    mounted(){
        console.log('mounted tool')
        this.init();
    },
    methods:{
        ...mapActions('designModule',[
            'applyToolData',        
        ]),
    ...mapMutations('designModule',[
        'showToolModal',      
        ]),
        init(){
            this.end = this.iTool.data.end;
            this.bays = this.iTool.data.bays;
            this.height = this.iTool.data.height;
            this.includeFront = this.iTool.data.includeFront;
            this.includeBack = this.iTool.data.includeBack;
            this.includeEnd= this.iTool.data.includeEnd;
        },
        apply(){        
            this.tool.data = {
                end: this.end,
                height: this.height,
                bays: this.bays,
                includeFront: this.includeFront,
                includeBack: this.includeBack,
                includeEnd: this.includeEnd,
            }; 

            this.applyToolData();
            this.showToolModal(false);
        },
        cancel(){
            this.showToolModal(false)
        },
    },
    components:{
        TargetStructureName
    }
   
}
</script>

<style scoped>

td:nth-child(1) {
    text-align:right;
}
.main {    
    background-color: #222;
    border-color: #080808;
    width: 100%;
    left:0px;
    top:0px;
    color:white;
    display: flex;
}

.meta{
    display:inline-block;
    margin-left:10px;
    flex-grow: 2;
}

.name {
    font-size: 1em;
}

.specs {
    font-size: .7em;
}

.logo{
    width:150px;
    position:relative;
}

.logo .img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.menuItem {
    margin:2px 10px;
    position:relative;
}

.menuItem.boxed{
    border-radius: 5px;
    border: 1px solid lightgray;
    padding: 3px;
    text-align: center;
}

.modeLabel{
    display: block;
    text-align: center;
}


.menuItem.title{
    overflow: hidden
}

.title .primary{
    font-weight: bold;
    font-style: italic;
    max-width: 95vw;
    font-size: 25px;
    display: block;
}

.title .beta{
    font-weight: bold;
    font-style: italic;
    max-width: 95vw;
    color:coral;
    font-size: 15px;
}

.title .beta{
    font-weight: bold;
    max-width: 95vw;
    font-size: 13px;
}

.btn {
    display:inline-block;
    width:auto;
    height: fit-content;
    /* nitty-gritty-override */
    min-height: unset;
}

.quote{
  display:block;
}

input[type="number"]{
    width: 80%;
}

td {
    padding:2px 5px;
    margin:2px 0px;
    max-width: 160px;
}

label{
    margin:10px
}

input{
    padding-left:5px;
}

</style>