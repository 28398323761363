<template>
    <div>
        <!-- cancel and save -->
        <div v-if="isAuthenticated" style="display:flex; justify-content: space-between; align-items: center; margin-bottom: 5%;">
            <div>
                <button type="button" class="btn btn-secondary" @click="$emit('close-modal')">
                    <Icon name="line-arrow-left" custom-class="icon icon-size-1 shift-be-2" />
                    <span>Cancel</span>
                </button>
            </div>
            <div style="display:flex; ">
                <div v-if="!isNewProject">
                    <StatusIndicator ref="si_saveProject"></StatusIndicator>
                    <button type="button" class="btn btn-secondary" @click="commitDetails">
                        <Icon name="cube" custom-class="icon icon-size-1 shift-be-2" />
                        <span>Save</span>
                    </button>
                </div>
                <div v-else>
                    <button type="button" class="btn btn-secondary" @click="commitAndLaunchDesigner">
                        <Icon name="cube" custom-class="icon icon-size-1 shift-be-2" />
                        <span>Save and Edit in 3D Designer</span>
                    </button>
                </div>
            </div>
            
        </div>
        
        <div>
            
            <div class="grid gap-5" style="grid-template-columns: 1fr;">
                
                <div v-if="input.error.show" style="color:red; margin: 10px">{{ input.error.msg }}</div>

                <div class="" v-if="isAuthenticated">
                    <!-- <span class="text-label-1" style="text-align: start; margin-right:3%;" for="projectName">Supplier</span> -->
                    <label v-if="!supplierUserValid" class="label-error" style="margin-left: 20px;">Required</label>
                    <BusinessUserPicker :disable="inputIsDisabled" :businesses="suppliers" :isSupplierPicker="true"
                        ref="supplierPicker" @business-selected="onSupplierSelected"
                        @user-selected="onSupplierUserSelected">                        
                            
                    </BusinessUserPicker>
                </div>
                <div class="" v-if="isAuthenticated">
                    <!-- <span class="text-label-1" style="text-align: start; margin-right:3%;" for="projectName">Contractor</span> -->
                    <BusinessUserPicker :disable="inputIsDisabled" :businesses="contractors" :isSupplierPicker="false"
                        ref="contractorPicker" @business-selected="onContractorSelected"
                        @user-selected="onContractorUserSelected">
                    </BusinessUserPicker>
                </div>

                <div>
                    <label class="" style="display:inline-block" for="projectName">Project Name</label>
                    <label v-if="!nameValid" class="label-error" style="margin-left: 20px;">Required</label>
                    <input type="text" id="projectName" v-model="project.name" v-on:input="inputfieldChanged"
                        :disabled="inputIsDisabled" style="">
                </div>
                <div>
                    <label for="address" class="text-label-1" style="text-align: start; margin-right:3%;">Location </label>
                    <label v-if="!addressValid" class="label-error" style="margin-left: 20px;">Required</label>
                    <AddressInput :ref="`address-${$attrs.id}`" :id="`address-${$attrs.id}`" style="" :pAddress="addressData" @address-selected="setAddress" :editMode="true" :editable="!inputIsDisabled"></AddressInput>
                </div>
                <div class="checkradio-item" >
                    <input type="checkbox" v-model="project.delivered" :disabled="inputIsDisabled" id="delivery">
                    <label class="text-label-1" style="text-align: start; margin-right:3%;" for="delivery"> Delivery</label>
                </div>
                <div class="" v-if="isAuthenticated">
                    <label class="text-label-1" style="text-align: start; margin-right:3%;">Sales Tax</label>
                    <label v-if="!taxOptionValid" class="label-error" style="margin-left: 20px;">Required</label>
                    <div v-if="enabledTaxOptionsCount < 1">Select a supplier to see tax options</div>
                    <span class="select" style="width: 100%;" v-if="enabledTaxOptionsCount > 0">
                        <select id="taxRateOptions" v-model="project.taxOptionRef" :disabled="inputIsDisabled">
                            <option v-for="opt in enabledTaxOptions" :key="opt.id" :value="opt.reference">
                                {{ opt.name }} - {{ opt.rate }}%
                            </option>
                        </select>
                    </span>
                </div>
                <template v-if="isNewProject" >
                    <div class="grid gap-5" style="grid-template-columns: 1fr 1fr" >
                        <div >
                            <label for="code">Code</label>
                            <span class="select">
                                <select id="code" v-model="project.buildingCode"
                                    :disabled="inputIsDisabled">
                                    <option value="IBC 2012">IBC 2012</option>
                                    <option value="IBC 2015">IBC 2015</option>
                                    <option value="IBC 2018">IBC 2018</option>
                                    <option value="IBC 2021">IBC 2021</option>
                                </select>
                            </span>
                        </div>
                        <div class="checkradio-item">
                            <input type="checkbox" v-model="project.seals" :disabled="inputIsDisabled" id="seals">
                            <label class="text-label-1" style="text-align: start; margin-right:3%;" for="seals">Engineering Seals</label>
                        </div>
                    </div>
                </template>
                
                
                <div id="notes-wrapper">
                    <label class="text-label-1" style="text-align: start; margin-right:3%;">Bidsheet Summary Notes</label>
                    <textarea id="projectNotes" name="notes" rows="3" cols="10" v-model="project.notes"
                        :disabled="inputIsDisabled"></textarea>
                </div>
                <div id="notes-wrapper" v-if="isSouthWest">
                    <label class="text-label-1" style="text-align: start; margin-right:3%;">Internal Quote Template
                        Notes</label>
                    <textarea id="internalQuoteTemplateNotes" name="internalNotes" rows="3" cols="10"
                        v-model="project.internalQuoteTemplateNotes" :disabled="inputIsDisabled"
                        placeholder="Internal notes can be found on all project Quote Templates."></textarea>
                </div>


            </div>

        </div>


    </div>
</template>

<script>
import ContextLogic from '@/lib/contextLogic';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import Util from '../lib/utility';
import VModal from 'vue-js-modal'
import Vue from 'vue'
import Vuex from 'vuex'
import BusinessUserPicker from '@/components/BusinessUserPicker.vue';
import ProgressSpinner from '@/components/ProgressSpinner.vue';
import MessageModal from '@/components/MessageModal.vue';
import { CORE } from '@/constants';
import QuoteAdjustmentModal from '@/components/QuoteAdjustmentModal.vue';
import ProjectCopyModal from '@/components/ProjectCopyModal.vue';
import Dictionary from '../Dictionary.js'
import api from '@/api';
import AddressInput from '@/components/AddressInput.vue';
import MultiButton from '@/components/MultiButton.vue';
import StatusIndicator from '@/components/StatusIndicator.vue';
import SnuiWrapper from '@/components/SnuiWrapper.vue';
import { faTrumpet } from '@fortawesome/pro-duotone-svg-icons';
Vue.use(Vuex)
Vue.use(VModal, { dialog: true });
export default {
    name: 'ProjectDetailsView',
    components: {
        BusinessUserPicker,
        ProgressSpinner,
        MessageModal,
        QuoteAdjustmentModal,
        ProjectCopyModal,
        AddressInput,
        MultiButton,
        StatusIndicator,
        SnuiWrapper
    },
    data() {
        return {
            dictionary: [],
            loadingProject: true,
            loadingDetails: true,
            isAuthenticated: false,
            project: {},
            isNewProject: false,
            isShareMode: false,
            isEditing: false,
            creating: false,
            copying: false,
            saveInProgress: false,
            createBuildingFailed: false,
            isSouthWest: false,
            businessType: -1,
            input: {
                disableSave: false,
                error: {
                    show: false,
                    msg: ""
                }
            },
            copyData: {
                ref: null,
                show: false,
            },
            supplierBusiness: null,
            supplierUser: null,
            contractorBusiness: null,
            contractorUser: null,
            fileVariants: [],
            additionalDocuments: [],
            suppliers: [],
            contractors: [],
            isDragging: false,
            dragCounter: 0,
            msgModal: {
                showMessage: false,
                message: '',
                issues: [],
            },
            qaData: {
                ref: null,
                show: false
            },
            enabledTaxOptions: [],
            eventLog: [
                {
                    msg: 'Saved by John Doe',
                    ts: new Date().valueOf(new Date())
                },
                {
                    msg: 'Saved by John Doe',
                    ts: new Date().valueOf(new Date()) - 1000000000
                },
                {
                    msg: 'Unarchived by John Doe',
                    ts: new Date().valueOf(new Date()) - 2000000000
                },
                {
                    msg: 'Archived by John Doe',
                    ts: new Date().valueOf(new Date()) - 3000000000
                },
                {
                    msg: 'Created by John Doe',
                    ts: new Date().valueOf(new Date()) - 4000000000
                }

            ],
            priceLockOptions: [
                {
                    optionText: 'Unlock',
                    value: 0
                },
                {
                    optionText: '30 days',
                    value: 30
                },
                {
                    optionText: '60 days',
                    value: 60
                },
                {
                    optionText: '90 days',
                    value: 90
                },
            ]

        };
    },
    metaInfo() {
        return {
            title: this.pageTitle,
            meta: [
                { name: 'description', content: "something" },
                { property: 'og:title', content: this.project.Name },
                { property: 'og:description', content: "something" },
                { property: 'og:image', content: this.project.image },
                { property: 'og:url', content: `http://localhost:8080/project/${this.project.ref}/share` },
                { property: 'og:type', content: 'website' },
                { name: 'twitter:card', content: 'summary_large_image' },
                { name: 'twitter:title', content: this.project.name },
                { name: 'twitter:description', content: "something" },
                { name: 'twitter:image', content: this.project.image },
                { property: 'og:url', content: `http://localhost:8080/project/${this.project.ref}/share` }
            ]
        }
    },
    computed: {
        ...mapState('contextModule', ['current', 'contractorParentData', 'user', 'userBusiness']),
        ...mapGetters('contextModule', [
            'userIsSysAdmin']),
        addressData() {
            const regex = new RegExp(`[,\\s]*${this.project.zipCode}\\b.*`, 'i'); // Match the ZIP and everything after it, including preceding commas/spaces
            let withoutZip;
            if (this.project.address)
                withoutZip = this.project.address.replace(regex, '').trim();
            return {
                formatted: this.project.address,
                zipCode: this.project.zipCode,
                formattedWithoutZip: withoutZip
            }
        },
        assignmentData() {

            let obj = {
                supplier: {
                    business: this.supplierBusiness ? this.supplierBusiness.businessName : "",
                    user: this.supplierUser ? this.supplierUser.name : "",
                },
                contractor: {
                    business: this.contractorBusiness ? this.contractorBusiness.businessName : "",
                    user: this.contractorUser ? this.contractorUser.name : "",
                },
            }

            return obj;
        },
        lastModifiedTimestamp() {
            let date = new Date(this.project.modified);
            const options = {
                weekday: 'long',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
            };
            return date.toLocaleDateString(undefined, options) + ' ' + date.toLocaleTimeString('en-US')
        },
        preventBidsheetDownload() {
            return this.isEditing || this.copying;
        },
        designUrl() {
            return `/project/${this.project.reference}/design`;
        },
        nameValid() {
            return this.project.name && this.project.name.length !== 0;
        },
        addressValid() {
            return this.project.address && this.project.address.length !== 0;
        },
        supplierUserValid() {
            return this.project.lvl1UserId != -1;
        },
        taxOptionValid() {
            return this.project.taxOptionRef != '';
        },
        inputIsDisabled() {
            let disabled = this.isShareMode || !this.isAuthenticated || this.saveInProgress;

            return disabled
        },
        formattedPrice() {
            return Util.formattedPrice(this.project.price);
        },
        formattedWeight() {
            return Util.formattedWeight(this.project.weight)
        },
        formattedPriceLockExpiration() {
            console.log('formattedPriceLockExpiration')
            return Util.formattedFullDate_TimeSensitive(this.project.priceLockExpiration);
        },
        enabledTaxOptionsCount() {
            return this.enabledTaxOptions.length;
        },
        taxOption() {
            console.log('checking tax option')
            let to = this.enabledTaxOptions.filter(to => to.reference == this.project.taxOptionRef);

            if (to.length == 1)
                return to[0];
            return null;
        },
        taxOptionName() {
            let to = this.taxOption
            if (!to)
                return "";
            return to.name;
        },
        taxOptionRate() {
            let to = this.taxOption
            if (!to)
                return "";
            return to.rate + "%";
        }
    },
    methods: {
        ...mapActions('projectModule', ['fetchProject', 'createBuildingQuote', 'updateBuildingQuote',
            'requestCsvBomExport', 'fetchMBS', 'generateBuildingQuotePDFV3',
            'fetchStructureDetails', 'fetchFileNamesInProjectDocFolder', 'uploadDocuments', 'requestFileFromDocFolder', 'deleteAdditionalDocument']),
        ...mapActions('businessModule', ['fetchAllBusinesses', 'fetchAllSuppliers', 'fetchSubBusinesses', 'fetchContractorsByRep', 'fetchBusiness', 'getBusinessSetting']),
        ...mapActions('userModule', ['fetchUser', 'fetchUsersFromSupplier', 'fetchUsersFromContractor']),
        formattedEventTimestamp(v) {
            return Util.formattedFullDate_TimeSensitive(v);
        },
        setAddress(v) {
            this.project.address = v.formattedAddress;
            this.project.zipcode = v.zipCode;
        },
        async setProjectEditMode() {

            // this.isEditing = true;

            // this.$nextTick(() => {
               
            // })
        },
        inputfieldChanged() {
            return;
            // this.isEditing = true;
            this.input.error.show = false;
            let msg = this.buildErrorMsg();
            if (msg) {
                this.input.disableSave = true;
                this.input.error = {
                    msg,
                    show: true
                }
            } else {
                this.input.disableSave = false;
                this.input.error = {
                    msg: null,
                    show: false
                }
            }
            return;
        },
        buildErrorMsg() {
            let fieldList = [];
            if (this.project.name.length === 0) fieldList.push("Name");

            if (this.project.address.length === 0) {
                fieldList.push('address');
            }


            if (fieldList.length > 0)
                return "Invalid Fields: " + fieldList.join(', ');

            if (this.project.frameType === "Weld up" || this.project.frameType === "Weld PLUS") {
                if (parseFloat(this.project.width) > 60)
                    return "Weld-up building width cannot exceed 60ft.";
            }

            if (/[\\/:*?"<>|]/.test(this.project.name))
                return "A building name cannot contain any of the following characters: \\ / : * ? \" < > |";

            return null;
        },
        async commitAndLaunchDesigner() {
            console.log('new building quote', this.project)
            await this.commitDetails();
            if (!this.createBuildingFailed)
                this.launchDesigner();
        },
        async commitDetails() {
            if (!(this.nameValid &&
                this.addressValid &&
                this.supplierUserValid)) {
                this.input.disableSave = true;
                this.input.error = {
                    msg: "Please fill in all required fields",
                    show: true
                }
                return;
            }
            else
                this.input.disableSave = false;

            this.input.error = {
                msg: '',
                show: false
            }

            let si = this.$refs["si_saveProject"];
            if(si) si.loading()

            // this.geocodeLocation();
            this.saveInProgress = true;
            let response;
            if (this.creating) {
                response = await this.createBuildingQuote(this.project);
            }
            else
                response = await this.updateBuildingQuote(this.project);

            if (response.isError) {
                this.commitDetailsError(response.data)
                if(si) si.fail();
            }
            else {
                if(si) si.hide();
                this.$toast.success("Project saved successfully!")
                this.creating = false;
                this.isEditing = false;
                this.saveInProgress = false;
                this.createBuildingFailed = false;
                this.project = response; // client learns id and reference
                this.$emit("close-modal");
            }
        },

        launchDesigner() {
            if (this.creating || this.isEditing || this.copying)
                return;
            let designerUrl;
            this.$router.push(this.designUrl);
        },
        commitDetailsError(errData) {
            this.createBuildingFailed = true;
            if (errData.locked) {
                this.showAlert_QuoteTemplateLocked();
            }
            else {
                this.$modal.show('dialog', {
                    title: 'Warning',
                    text: `${errData.msg}`,
                    buttons: [
                        {
                            title: 'OK',
                            handler: () => {
                                this.$modal.hide('dialog')
                            }
                        },
                    ]
                })
            }

        },
        showAlert_QuoteTemplateLocked() {
            this.$modal.show('dialog', {
                title: 'Warning',
                text: `Could not complete the operation becasue this design's quote file is locked. Please close the quote file and try again.`,
                buttons: [
                    {
                        title: 'OK',
                        handler: () => {
                            this.$modal.hide('dialog')
                        }
                    },
                ]
            })
        },
        async initTaxOptions(supplierId) {
            // this method is called if the supplier business is drop down is changed
            if (supplierId) {
                let allTaxOpts = await api.getAllBusinessTaxOptions(supplierId);
                let enabledTaxOpts = allTaxOpts.filter(opts => opts.enabled)
                this.enabledTaxOptions = enabledTaxOpts;

                // Set the project tax option to the supplier's default sales tax rate
                // ONLY IF the current tax option isn't in the enabledTaxOptions
                if (!this.enabledTaxOptions.find(opt => opt.reference == this.project.taxOptionRef)) {
                    let defaultSalesTaxOption = await this.getBusinessSetting({ businessId: supplierId, settingName: "qt_defaultSalesTaxRate" });
                    this.project.taxOptionRef = defaultSalesTaxOption.value;
                }
            }
        },
        async onSupplierSelected(id) {
            this.initTaxOptions(id);// change in supplier impacts available tax options (whether new project or existing)
        },
        async onSupplierUserSelected(id) {
            this.contractors = await this.fetchContractorsByRep(id);
            this.project.lvl1UserId = id;
            this.inputfieldChanged();
        },
        async onContractorSelected(id) {
        },
        async onContractorUserSelected(userId) {
            this.project.lvl2UserId = userId;
            this.inputfieldChanged();
        },
        async updatePricing() {
            let response = await api.updatePricing(this.project.reference);

            if (response.isError) {
                let errData = response.data;
                if (errData.locked)
                    this.$toast.fail("Project file is open.")
                else
                    this.$toast.fail("An error occurred while trying to update project pricing.")
            }
            else {
                this.$toast.success("Project pricing updated successfully!")
            }
        },
        async init_Anonymous() {
            await this.initAnonymous_PickerData();
        },
        async initAnonymous_PickerData() {
            this.$refs.supplierPicker.disablePickers();
            this.$refs.contractorPicker.disablePickers();

            //end-user should never be shown supplier info
            this.$refs.supplierPicker.showBusiness = false;
            this.$refs.supplierPicker.showUser = false;

            this.$refs.contractorPicker.showBusiness = false;
            this.$refs.contractorPicker.showUser = false;

            //this.$refs.supplierPicker.users = [await this.fetchUser(this.quote.lvl1UserId)];
            //this.$refs.supplierPicker.onUserSelected(this.quote.lvl1UserId);

            // this.$refs.contractorPicker.users = [await this.fetchUser(this.quote.lvl2UserId)];
            // this.$refs.contractorPicker.onUserSelected(this.quote.lvl2UserId);
        },
        async init_Authenticated() {
            let supplierBusinessId;


            if(this.isNewProject){
                if(this.userBusiness.type == 1)
                supplierBusinessId = this.userBusiness.id;
                
            }
            else             {
                // use the project's supplier rep to know the business for which to load tax options
                let lvl1User = await this.fetchUser(this.project.lvl1UserId);
                supplierBusinessId = lvl1User.businessId

                this.$nextTick(() => {
                    if(this.$refs.address){
                        this.$refs.address.setAddressDetails({
                            formattedAddress: this.project.address,
                            zipcode: this.project.zipCode
                        })
                    }


                })
            }
            if(supplierBusinessId)
                await this.initTaxOptions(supplierBusinessId);
            this.initPickerData();
        },
        async initPickerData() {
            console.log(this.userBusiness.type);
            // Use context to figure out who the heck this project is being made for.
            if (this.businessType == CORE.entities.enterprise) {
                this.$refs.supplierPicker.showBusiness = true;
                this.$refs.supplierPicker.showUser = true;
                this.$refs.contractorPicker.showBusiness = true;
                this.$refs.contractorPicker.showUser = true;
                
                this.suppliers = await this.fetchAllSuppliers();

                // If it's a pre-exisitng project, we have some clue who the supplier is
                // because every project should have a supplier attached to it
                if (!this.isNewProject) {
                    if (this.project.lvl1UserId > -1) {
                        let lvl1User = await this.fetchUser(this.project.lvl1UserId);
                        this.$refs.supplierPicker.onBusinessSelected(lvl1User.businessId);
                        this.$refs.supplierPicker.onUserSelected(this.project.lvl1UserId);
                    }
                    if (this.project.lvl2UserId > -1) {
                        let lvl2User = await this.fetchUser(this.project.lvl2UserId);
                        this.$refs.contractorPicker.onBusinessSelected(lvl2User.businessId);
                        this.$refs.contractorPicker.onUserSelected(this.project.lvl2UserId);
                    }

                }

            }
            else if (this.businessType == CORE.entities.supplier) {                
                // We know who the project supplier is, but not the comapny user nor the contractor user the project is assigned to.
                this.$refs.supplierPicker.showBusiness = false;
                this.$refs.supplierPicker.showUser = true;
                this.$refs.contractorPicker.showBusiness = true;
                this.$refs.contractorPicker.showUser = true;

                // get the users from the supplier
                this.$refs.supplierPicker.users = await this.fetchUsersFromSupplier(this.userBusiness.id);

                // If it's a pre-exisitng project, we have some clue who the supplier is
                // because every project should have a supplier attached to it
                if (!this.isNewProject) {
                    if (this.project.lvl1UserId > -1) {
                        //let lvl1User = await this.fetchUser(this.quote.lvl1UserId);
                        this.$refs.supplierPicker.onUserSelected(this.project.lvl1UserId);
                    }
                    if (this.project.lvl2UserId > -1) {
                        let lvl2User = await this.fetchUser(this.project.lvl2UserId);
                        this.$refs.contractorPicker.onBusinessSelected(lvl2User.businessId);
                        this.$refs.contractorPicker.onUserSelected(this.project.lvl2UserId);
                    }

                }
            }
            else {
                // We know who the project supplier, supplier user, and contractor is, but not the contractor user the project is assigned to.
                this.project.lvl1UserId = this.contractorParentData.rep.sub

                this.$refs.supplierPicker.showBusiness = false;
                this.$refs.supplierPicker.showUser = false;
                this.$refs.contractorPicker.showBusiness = false;
                this.$refs.contractorPicker.showUser = true;
                this.$refs.contractorPicker.users = await this.fetchUsersFromContractor(this.userBusiness.id);

                // If it's a pre-exisitng project, we have some clue who the supplier is
                // because every project should have a supplier attached to it
                if (!this.isNewProject) {
                    if (this.project.lvl2UserId > -1) {
                        this.$refs.contractorPicker.onUserSelected(this.project.lvl2UserId);
                    }

                }
            }
        },
        showSuccessMessage(message) {
            this.msgModal.message = message
            this.msgModal.issues = []
            this.msgModal.showMessage = true
        },
        showErrorMessage(message, issues = []) {
            this.msgModal.message = message
            this.msgModal.issues = issues
            this.msgModal.showMessage = true
        },
        closeMessage() {
            this.msgModal.showMessage = false
        },
        async postAuthInit() {
            // now that the user is authenticated...
            await ContextLogic.initContext(this);
            this.businessType = this.userBusiness.type;
            let swFeatureflag = this.userBusiness.featureFlags.find(flag => flag.name == 'isSouthwestMetal');
            let isSystemAdmin = this.userIsSysAdmin;
            this.isSouthWest = isSystemAdmin || ((swFeatureflag) ? swFeatureflag.enabled : false);
            // await this.init_Authenticated(); // this cannot be done here. this.quote must be filled in before we set the pickers
        },
        async initPage() {
            this.isAuthenticated = this.$auth.isAuthenticated;
            if (this.isAuthenticated) {
                // when clicking links to navigate, this.$auth will already be initialized
                await this.postAuthInit()
            }

            // isEditing should always be true
            this.isEditing = this.$route.path.includes("/edit") || this.$route.path.includes("/new");

            this.isNewProject = this.$route.path.includes('/new');
            console.log('ProjectView State is New:', this.isNewProject)
            this.isShareMode = this.$route.path.includes('share');

            console.log('contractor parent data', this.contractorParentData)
            let pathParts = this.$route.path.split("/");
            let projectRef = pathParts[2];


            if (this.isNewProject) {
                if (!this.isAuthenticated)
                    this.$router.go(-1);

                this.project = {
                    id: 0,
                    name: "",
                    address: '',
                    zipCode: '',
                    lvl1UserId: -1,
                    lvl2UserId: -1,
                    archive: false,
                    tax: "No - Exempt",
                    taxOptionRef: '',
                    delivered: false,
                    design: "",
                    image: "",
                    reference: '',
                    buildingCode: "IBC 2015",
                    seals: true,
                    state: 0,    // 0 is NEW
                    notes: "",
                    internalQuoteTemplateNotes: "",
                    version: 3,
                    priceScheme: 0
                };

                this.creating = true;
                this.loadingProject = false;
                this.loadingDetails = false;
            }
            else {
                this.project = await this.fetchProject(projectRef);
                if (!this.project.id)
                    return;

                this.loadingProject = false;
                this.additionalDocuments = await this.fetchFileNamesInProjectDocFolder(this.project.reference);
                this.loadingDetails = false;

            }

            console.log(this.project);

            if (this.isShareMode || !this.isAuthenticated) {
                // pickers don't even render when not authenticated
                if (this.isShareMode)
                    this.init_Anonymous();
            }
            else {
                this.init_Authenticated();
            }
        }
    },
    watch: {
        async '$auth.loading'(loading) {
            if (!loading) {
                await this.initPage();
            }
        },
        async "quote.lvl1UserId"(newId, oldId) {
            if (!newId || newId == -1)
                return;

            this.supplierUser = await this.fetchUser(newId);
            this.supplierBusiness = await this.fetchBusiness(this.supplierUser.businessId);
        },
        async "quote.lvl2UserId"(newId, oldId) {
            if (!newId || newId == -1)
                return;

            this.contractorUser = await this.fetchUser(newId);
            this.contractorBusiness = await this.fetchBusiness(this.contractorUser.businessId);
        }
    },
    async created() {

    },
    async mounted(){
        this.dictionary = new Dictionary();
        // ProjectView route does not have an AuthGuard attached to its BeforeEnter,
        // because ProjectView needs to be accessible for both authenticated and anonymous users.
        // Hard refreshes cause auth0 to lose all of its context (isAuthenticated,auth0Client,etc).
        // If we hard refresh the ProjectView page while we are authenticated, the page will always
        // initialize in anonymous mode, because the auth0 context is lost and the page never has a chance 
        // to wait for auth0 context to reload since it doesn't have an AuthGuard. 
        // So, we need a watch on $auth.loading to retry page initialization in case we are authenticated. 
        // If not authenticated, no harm.
        if (!this.$auth.loading) {
            await this.initPage();
        }


    //     let test = this.$refs[`address-${this.$attrs.id}`]
    //   this.$refs[`address-${this.$attrs.id}`].loadGoogleMapsScript();

    }

}
</script>

<style scoped>
.input-group {
    margin-bottom: 3%;
    width:70%;
}
.existingProject{
    display:flex;
    justify-content:center;
    align-items: center;
}
</style>