<!-- ColorManager.vue -->
<template>
  <div class="color-manager p-4">
    <h1 class="h1">Colors</h1>
    <div style="top:200px; display:inline-block; rotate: -15deg; text-align: center;font-weight:bold;  font-size:24px; background:#b6c6d3;box-shadow: 5px 5px 2px 1px #00ae41; color:#00ae41;">
        Coming Soon!
      </div>
    <!-- Add New Color Form -->
    <div class="bg-gray-100 p-4 rounded-lg mb-6">
      
      <h2 class="text-xl font-semibold mb-3">Add New Color</h2>
      
      <form @submit.prevent="addColor" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <div>
          <label class="block text-sm font-medium mb-1">Name*</label>
          <input 
            v-model="newColor.name"
            type="text"
            class="w-full px-3 py-2 border rounded"
            required
          >
        </div>
        
        <div>
          <label class="block text-sm font-medium mb-1">Abbreviation* (2 chars)</label>
          <input 
            v-model="newColor.abbreviation"
            type="text"
            maxlength="2"
            class="w-full px-3 py-2 border rounded"
            required
          >
        </div>
        
        <div>
          <label class="block text-sm font-medium mb-1">Caulk Code (2 chars)</label>
          <input 
            v-model="newColor.caulkCode"
            type="text"
            maxlength="2"
            class="w-full px-3 py-2 border rounded"
          >
        </div>
        
        <div>
          <label class="block text-sm font-medium mb-1">MBS Code (2 chars)</label>
          <input 
            v-model="newColor.mbsCode"
            type="text"
            maxlength="2"
            class="w-full px-3 py-2 border rounded"
          >
        </div>
        
        <div>
          <label class="block text-sm font-medium mb-1">Media Hex Color*</label>
          <div class="flex items-center space-x-2">
            <input 
              v-model="newColor.mediaHex"
              type="text"
              pattern="^#[0-9A-Fa-f]{6}$"
              class="w-full px-3 py-2 border rounded"
              required
            >
            <div 
              class="w-8 h-8 border rounded"
              :style="{ backgroundColor: newColor.mediaHex }"
            ></div>
          </div>
        </div>
        
        <div>
          <label class="block text-sm font-medium mb-1">3D Designer Hex Color*</label>
          <div class="flex items-center space-x-2">
            <input 
              v-model="newColor.designerHex"
              type="text"
              pattern="^#[0-9A-Fa-f]{6}$"
              class="w-full px-3 py-2 border rounded"
              required
            >
            <div 
              class="w-8 h-8 border rounded"
              :style="{ backgroundColor: newColor.designerHex }"
            ></div>
          </div>
        </div>
        
        <div class="flex items-center space-x-4">
          <label class="flex items-center">
            <input 
              v-model="newColor.isForTrim"
              type="checkbox"
              class="mr-2"
            >
            <span>Suitable for Trim</span>
          </label>
          
          <label class="flex items-center">
            <input 
              v-model="newColor.isForPrime"
              type="checkbox"
              class="mr-2"
            >
            <span>Suitable for Prime</span>
          </label>
        </div>
        
        <div class="col-span-full">
          <button 
            type="submit"
            class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Add Color
          </button>
        </div>
      </form>
    </div>
    
    <!-- Color List -->
    <div class="overflow-x-auto">
      <table class="min-w-full bg-white border">
        <thead>
          <tr class="bg-gray-100">
            <th class="px-4 py-2 text-left">Name</th>
            <th class="px-4 py-2 text-left">Abbr.</th>
            <th class="px-4 py-2 text-left">Caulk</th>
            <th class="px-4 py-2 text-left">MBS</th>
            <th class="px-4 py-2 text-center">For Trim</th>
            <th class="px-4 py-2 text-center">For Prime</th>
            <th class="px-4 py-2 text-left">Media Color</th>
            <th class="px-4 py-2 text-left">3D Color</th>
            <th class="px-4 py-2 text-center">Status</th>
            <th class="px-4 py-2 text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="color in colors" 
            :key="color.id"
            :class="{ 'bg-gray-50': !color.isActive }"
          >
            <td class="px-4 py-2">{{ color.name }}</td>
            <td class="px-4 py-2">{{ color.abbreviation }}</td>
            <td class="px-4 py-2">{{ color.caulkCode }}</td>
            <td class="px-4 py-2">{{ color.mbsCode }}</td>
            <td class="px-4 py-2 text-center">
              <span v-if="color.isForTrim">✓</span>
            </td>
            <td class="px-4 py-2 text-center">
              <span v-if="color.isForPrime">✓</span>
            </td>
            <td class="px-4 py-2">
              <div class="flex items-center space-x-3">
                <div 
                  class="w-8 h-8 border rounded flex-shrink-0"
                  :style="{ backgroundColor: color.mediaHex }"
                ></div>
                <span>{{ color.mediaHex }}</span>
              </div>
            </td>
            <td class="px-4 py-2">
              <div class="flex items-center space-x-3">
                <div 
                  class="w-8 h-8 border rounded flex-shrink-0"
                  :style="{ backgroundColor: color.designerHex }"
                ></div>
                <span>{{ color.designerHex }}</span>
              </div>
            </td>
            <td class="px-4 py-2 text-center">
              <span 
                :class="{
                  'text-green-600': color.isActive,
                  'text-red-600': !color.isActive
                }"
              >
                {{ color.isActive ? 'Active' : 'Inactive' }}
              </span>
            </td>
            <td class="px-4 py-2 text-center">
              <button
                @click="toggleColorStatus(color)"
                class="px-2 py-1 rounded text-sm mr-2"
                :class="{
                  'bg-red-100 text-red-600 hover:bg-red-200': color.isActive,
                  'bg-green-100 text-green-600 hover:bg-green-200': !color.isActive
                }"
              >
                {{ color.isActive ? 'Deactivate' : 'Activate' }}
              </button>
              <button
                @click="deleteColor(color)"
                class="bg-red-100 text-red-600 px-2 py-1 rounded text-sm hover:bg-red-200"
              >
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style scoped>
/* Layout */
.p-4 { padding: 1rem; }
.px-2 { padding-left: 0.5rem; padding-right: 0.5rem; }
.px-3 { padding-left: 0.75rem; padding-right: 0.75rem; }
.px-4 { padding-left: 1rem; padding-right: 1rem; }
.py-1 { padding-top: 0.25rem; padding-bottom: 0.25rem; }
.py-2 { padding-top: 0.5rem; padding-bottom: 0.5rem; }
.mb-1 { margin-bottom: 0.25rem; }
.mb-3 { margin-bottom: 0.75rem; }
.mb-4 { margin-bottom: 1rem; }
.mb-6 { margin-bottom: 1.5rem; }
.mr-2 { margin-right: 0.5rem; }
.space-x-2 > * + * { margin-left: 0.5rem; }
.space-x-4 > * + * { margin-left: 1rem; }

/* Typography */
.text-sm { font-size: 0.875rem; }
.text-xl { font-size: 1.25rem; }
.text-2xl { font-size: 1.5rem; }
.font-medium { font-weight: 500; }
.font-semibold { font-weight: 600; }
.font-bold { font-weight: 700; }
.text-left { text-align: left; }
.text-center { text-align: center; }

/* Colors */
.text-white { color: white; }
.text-red-600 { color: #dc2626; }
.text-green-600 { color: #16a34a; }
.bg-white { background-color: white; }
.bg-gray-50 { background-color: #f9fafb; }
.bg-gray-100 { background-color: #f3f4f6; }
.bg-blue-500 { background-color: #3b82f6; }
.bg-red-100 { background-color: #fee2e2; }
.bg-green-100 { background-color: #dcfce7; }

.hover\:bg-blue-600:hover { background-color: #2563eb; }
.hover\:bg-red-200:hover { background-color: #fecaca; }
.hover\:bg-green-200:hover { background-color: #bbf7d0; }

/* Borders */
.border { border: 1px solid #e5e7eb; }
.rounded { border-radius: 0.25rem; }
.rounded-lg { border-radius: 0.5rem; }

/* Layout containers */


.grid {
    display: grid;
    gap: 1rem;
}

.grid-cols-1 { grid-template-columns: repeat(1, 1fr); }

@media (min-width: 768px) {
    .md\:grid-cols-2 { grid-template-columns: repeat(2, 1fr); }
}

@media (min-width: 1024px) {
    .lg\:grid-cols-3 { grid-template-columns: repeat(3, 1fr); }
}

.col-span-full { grid-column: 1 / -1; }

/* Flex */
.flex { display: flex; }
.items-center { align-items: center; }

/* Table */
.min-w-full { min-width: 100%; }
.overflow-x-auto { overflow-x: auto; }

/* Form elements */
.block { display: block; }
.w-full { width: 100%; }
.w-6 { width: 1.5rem; }
.w-8 { width: 2rem; }
.h-6 { height: 1.5rem; }
.h-8 { height: 2rem; }

/* Color preview boxes */
.color-preview {
    border: 1px solid #e5e7eb;
    border-radius: 0.25rem;
}

/* Button styles */
button {
    cursor: pointer;
    border: none;
    transition: background-color 0.2s;
}

button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

/* Form inputs */
input[type="text"],
input[type="checkbox"] {
    border: 1px solid #e5e7eb;
    border-radius: 0.25rem;
}

input[type="text"]:focus {
    outline: 2px solid #3b82f6;
    outline-offset: -1px;
}

/* Table styles */
table {
    border-collapse: collapse;
    width: 100%;
}

th {
    font-weight: 600;
    text-align: left;
}

td, th {
    padding: 0.5rem 1rem;
    border: 1px solid #e5e7eb;
}
</style>

<script>
export default {
  name: 'ColorManager',
  
  data() {
    return {
      colors: [
        {
          id: 1,
          name: 'Black',
          abbreviation: 'BL',
          caulkCode: 'BL',
          mbsCode: 'BL',
          isForTrim: true,
          isForPrime: true,
          mediaHex: '#000000',
          designerHex: '#191919',
          isActive: true
        },
        {
          id: 2,
          name: 'Brown',
          abbreviation: 'BR',
          caulkCode: 'BR',
          mbsCode: 'BR',
          isForTrim: true,
          isForPrime: true,
          mediaHex: '#4A3626',
          designerHex: '#513C2C',
          isActive: true
        },
        {
          id: 3,
          name: 'Burnished Slate',
          abbreviation: 'BS',
          caulkCode: 'BS',
          mbsCode: 'BS',
          isForTrim: true,
          isForPrime: true,
          mediaHex: '#4E5754',
          designerHex: '#535D5A',
          isActive: true
        },
        {
          id: 4,
          name: 'Charcoal',
          abbreviation: 'CH',
          caulkCode: 'CH',
          mbsCode: 'CH',
          isForTrim: true,
          isForPrime: true,
          mediaHex: '#2F3437',
          designerHex: '#343A3D',
          isActive: true
        },
        {
          id: 5,
          name: 'Clear',
          abbreviation: 'CL',
          caulkCode: 'CL',
          mbsCode: 'CL',
          isForTrim: false,
          isForPrime: false,
          mediaHex: '#E8E8E8',
          designerHex: '#F0F0F0',
          isActive: true
        },
        {
          id: 6,
          name: 'Colony Green',
          abbreviation: 'CG',
          caulkCode: 'CG',
          mbsCode: 'CG',
          isForTrim: true,
          isForPrime: true,
          mediaHex: '#1E4734',
          designerHex: '#234D39',
          isActive: true
        },
        {
          id: 7,
          name: 'Copper',
          abbreviation: 'CM',
          caulkCode: 'CL',
          mbsCode: 'CM',
          isForTrim: true,
          isForPrime: false,
          mediaHex: '#B87333',
          designerHex: '#BE7838',
          isActive: true
        },
        {
          id: 8,
          name: 'Crimson',
          abbreviation: 'CR',
          caulkCode: 'CR',
          mbsCode: 'CR',
          isForTrim: true,
          isForPrime: true,
          mediaHex: '#DC143C',
          designerHex: '#E11941',
          isActive: true
        },
        {
          id: 9,
          name: 'Desert Sand',
          abbreviation: 'DS',
          caulkCode: 'DS',
          mbsCode: 'DR',
          isForTrim: true,
          isForPrime: true,
          mediaHex: '#C2B280',
          designerHex: '#C7B785',
          isActive: true
        },
        {
          id: 10,
          name: 'Fern Green',
          abbreviation: 'FG',
          caulkCode: 'HG',
          mbsCode: 'FG',
          isForTrim: true,
          isForPrime: true,
          mediaHex: '#4F7942',
          designerHex: '#547E47',
          isActive: true
        },
        {
          id: 11,
          name: 'Gallery Blue',
          abbreviation: 'GB',
          caulkCode: 'GB',
          mbsCode: 'GB',
          isForTrim: true,
          isForPrime: true,
          mediaHex: '#23395D',
          designerHex: '#283E62',
          isActive: true
        },
        {
          id: 12,
          name: 'Galvalume',
          abbreviation: 'GL',
          caulkCode: 'GL',
          mbsCode: 'GL',
          isForTrim: true,
          isForPrime: false,
          mediaHex: '#8B8C89',
          designerHex: '#90918E',
          isActive: true
        },
        {
          id: 13,
          name: 'Galvanized',
          abbreviation: 'GV',
          caulkCode: 'SM',
          mbsCode: 'GV',
          isForTrim: false,
          isForPrime: false,
          mediaHex: '#717476',
          designerHex: '#76797B',
          isActive: true
        },
        {
          id: 14,
          name: 'Gray',
          abbreviation: 'GR',
          caulkCode: 'DS',
          mbsCode: 'GR',
          isForTrim: true,
          isForPrime: true,
          mediaHex: '#808080',
          designerHex: '#858585',
          isActive: true
        },
        {
          id: 15,
          name: 'Hawaiian Blue',
          abbreviation: 'HB',
          caulkCode: 'HB',
          mbsCode: 'HA',
          isForTrim: true,
          isForPrime: true,
          mediaHex: '#006DB0',
          designerHex: '#0072B5',
          isActive: true
        },
        {
          id: 16,
          name: 'Hot Dip',
          abbreviation: 'HD',
          caulkCode: 'SM',
          mbsCode: 'SM',
          isForTrim: false,
          isForPrime: false,
          mediaHex: '#71797E',
          designerHex: '#767E83',
          isActive: true
        },
        {
          id: 17,
          name: 'Hunter Green',
          abbreviation: 'HG',
          caulkCode: 'HG',
          mbsCode: 'HG',
          isForTrim: true,
          isForPrime: true,
          mediaHex: '#355E3B',
          designerHex: '#3A6340',
          isActive: true
        },
        {
          id: 18,
          name: 'Lt Stone',
          abbreviation: 'LS',
          caulkCode: 'LS',
          mbsCode: 'LS',
          isForTrim: true,
          isForPrime: true,
          mediaHex: '#C4C4BC',
          designerHex: '#C9C9C1',
          isActive: true
        },
        {
          id: 19,
          name: 'Red Ox',
          abbreviation: 'RO',
          caulkCode: 'RR',
          mbsCode: 'RO',
          isForTrim: false,
          isForPrime: false,
          mediaHex: '#8B0000',
          designerHex: '#900000',
          isActive: true
        },
        {
          id: 20,
          name: 'Rred',
          abbreviation: 'RR',
          caulkCode: 'RR',
          mbsCode: 'RR',
          isForTrim: true,
          isForPrime: true,
          mediaHex: '#FF0000',
          designerHex: '#FF0505',
          isActive: true
        },
        {
          id: 21,
          name: 'Sel Color',
          abbreviation: 'SC',
          caulkCode: 'CL',
          mbsCode: 'NC',
          isForTrim: true,
          isForPrime: true,
          mediaHex: '#CCCCCC',
          designerHex: '#D1D1D1',
          isActive: true
        },
        {
          id: 22,
          name: 'Tan',
          abbreviation: 'TN',
          caulkCode: 'TN',
          mbsCode: 'TN',
          isForTrim: true,
          isForPrime: true,
          mediaHex: '#D2B48C',
          designerHex: '#D7B991',
          isActive: true
        },
        {
          id: 23,
          name: 'White',
          abbreviation: 'WH',
          caulkCode: 'WH',
          mbsCode: 'WH',
          isForTrim: true,
          isForPrime: true,
          mediaHex: '#FFFFFF',
          designerHex: '#FAFAFA',
          isActive: true
        }
      ],
      newColor: this.getEmptyColorObject(),
      nextId: 24
    }
  },
  
  methods: {
    getEmptyColorObject() {
      return {
        name: '',
        abbreviation: '',
        caulkCode: '',
        mbsCode: '',
        mediaHex: '#000000',
        designerHex: '#000000',
        isForTrim: false,
        isForPrime: false,
        isActive: true
      }
    },
    
    validateColor(color) {
      // Check for duplicate name
      if (this.colors.some(c => c.name.toLowerCase() === color.name.toLowerCase())) {
        alert('A color with this name already exists.')
        return false
      }
      
      // Check for duplicate abbreviation
      if (this.colors.some(c => c.abbreviation.toLowerCase() === color.abbreviation.toLowerCase())) {
        alert('A color with this abbreviation already exists.')
        return false
      }
      
      // Validate hex colors
      const hexRegex = /^#[0-9A-Fa-f]{6}$/
      if (!hexRegex.test(color.mediaHex) || !hexRegex.test(color.designerHex)) {
        alert('Please enter valid hex color values (e.g., #FF0000)')
        return false
      }
      
      // Validate 2-character codes
      if (color.abbreviation.length !== 2) {
        alert('Abbreviation must be exactly 2 characters')
        return false
      }
      
      if (color.caulkCode && color.caulkCode.length !== 2) {
        alert('Caulk code must be exactly 2 characters')
        return false
      }
      
      if (color.mbsCode && color.mbsCode.length !== 2) {
        alert('MBS code must be exactly 2 characters')
        return false
      }
      
      return true
    },
    
    addColor() {
      if (!this.validateColor(this.newColor)) {
        return
      }
      
      const colorToAdd = {
        ...this.newColor,
        id: this.nextId++
      }
      
      this.colors.push(colorToAdd)
      this.newColor = this.getEmptyColorObject()
    },
    
    toggleColorStatus(color) {
      color.isActive = !color.isActive
    },
    
    deleteColor(colorToDelete) {
      if (confirm('Are you sure you want to delete this color?')) {
        this.colors = this.colors.filter(color => color.id !== colorToDelete.id)
      }
    }
  }
}
</script>

<style scoped>
/* Layout */
.p-4 { padding: 1rem; }
.px-2 { padding-left: 0.5rem; padding-right: 0.5rem; }
.px-3 { padding-left: 0.75rem; padding-right: 0.75rem; }
.px-4 { padding-left: 1rem; padding-right: 1rem; }
.py-1 { padding-top: 0.25rem; padding-bottom: 0.25rem; }
.py-2 { padding-top: 0.5rem; padding-bottom: 0.5rem; }
.mb-1 { margin-bottom: 0.25rem; }
.mb-3 { margin-bottom: 0.75rem; }
.mb-4 { margin-bottom: 1rem; }
.mb-6 { margin-bottom: 1.5rem; }
.mr-2 { margin-right: 0.5rem; }
.space-x-2 > * + * { margin-left: 0.5rem; }
.space-x-4 > * + * { margin-left: 1rem; }

/* Typography */
.text-sm { font-size: 0.875rem; }
.text-xl { font-size: 1.25rem; }
.text-2xl { font-size: 1.5rem; }
.font-medium { font-weight: 500; }
.font-semibold { font-weight: 600; }
.font-bold { font-weight: 700; }
.text-left { text-align: left; }
.text-center { text-align: center; }

/* Colors */
.text-white { color: white; }
.text-red-600 { color: #dc2626; }
.text-green-600 { color: #16a34a; }
.bg-white { background-color: white; }
.bg-gray-50 { background-color: #f9fafb; }
.bg-gray-100 { background-color: #f3f4f6; }
.bg-blue-500 { background-color: #3b82f6; }
.bg-red-100 { background-color: #fee2e2; }
.bg-green-100 { background-color: #dcfce7; }

.hover\:bg-blue-600:hover { background-color: #2563eb; }
.hover\:bg-red-200:hover { background-color: #fecaca; }
.hover\:bg-green-200:hover { background-color: #bbf7d0; }

/* Borders */
.border { border: 1px solid #e5e7eb; }
.rounded { border-radius: 0.25rem; }
.rounded-lg { border-radius: 0.5rem; }

/* Layout containers */
.color-manager {
    max-width: 1200px;
    margin: 0 auto;
}

.grid {
    display: grid;
    gap: 1rem;
}

.grid-cols-1 { grid-template-columns: repeat(1, 1fr); }

@media (min-width: 768px) {
    .md\:grid-cols-2 { grid-template-columns: repeat(2, 1fr); }
}

@media (min-width: 1024px) {
    .lg\:grid-cols-3 { grid-template-columns: repeat(3, 1fr); }
}

.col-span-full { grid-column: 1 / -1; }

/* Flex */
.flex { display: flex; }
.items-center { align-items: center; }

/* Table */
.min-w-full { min-width: 100%; }
.overflow-x-auto { overflow-x: auto; }

/* Form elements */
.block { display: block; }
.w-full { width: 100%; }
.w-6 { width: 1.5rem; }
.w-8 { width: 2rem; }
.h-6 { height: 1.5rem; }
.h-8 { height: 2rem; }

/* Color preview boxes */
.color-preview {
    border: 1px solid #e5e7eb;
    border-radius: 0.25rem;
}

/* Button styles */
button {
    cursor: pointer;
    border: none;
    transition: background-color 0.2s;
}

button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

/* Form inputs */
input[type="text"],
input[type="checkbox"] {
    border: 1px solid #e5e7eb;
    border-radius: 0.25rem;
}

input[type="text"]:focus {
    outline: 2px solid #3b82f6;
    outline-offset: -1px;
}

/* Table styles */
table {
    border-collapse: collapse;
    width: 100%;
}

th {
    font-weight: 600;
    text-align: left;
}

td, th {
    padding: 0.5rem 1rem;
    border: 1px solid #e5e7eb;
}
</style>