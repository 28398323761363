export default class Util{

    static wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    static debounce (func, wait) {
        // Originally inspired by  David Walsh (https://davidwalsh.name/javascript-debounce-function)
        // Returns a function, that, as long as it continues to be invoked, will not
        // be triggered. The function will be called after it stops being called for
        // `wait` milliseconds.
        let timeout;
    
        function executedFunction(...args) {
            const later = () => {
                clearTimeout(timeout);
                func(...args);
            };
        
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
        };

        executedFunction.cancel = function() {
            clearTimeout(timeout);
        }

        return executedFunction;
    }

    static b64toBlob(b64Data, contentType = '', sliceSize = 512) {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
    
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
    
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
    
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
    
        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    static downloadBlob(blob, fileName) {
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        // first replace makes turns any character not a-z, 0-9, underscore, dash into an undersore and the 2nd replace removes any consecutive underscores
        link.download = fileName;
    
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    
    static formattedPrice(price){
        let USDollar = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        maximumFractionDigits: 0
                    });
        return USDollar.format(price);
    }

    static formattedWeight(weight){
        return new Intl.NumberFormat('en-US', {
                        style: 'decimal',
                        maximumFractionDigits: 0
                    }).format(weight) + ' Lbs';
    }

    static formattedFullDate_TimeSensitive(d){        
        let date = new Date(d);
        const options = {};
        let today = new Date();
        
        // if it's not this year, add the year
        if(date.getFullYear() != today.getFullYear())
        {
            options.year = 'numeric';
            options.weekday= 'short';
            options.month= 'short';
            options.day= 'numeric';
        }
            
        let result = '';
        // if it's not today, include weekday, month, and day
        if(date.getFullYear() == today.getFullYear() && date.getMonth() != today.getMonth()){
            options.weekday= 'short';
            options.month= 'short';
            options.day= 'numeric';
        }

        let sDate = date.toLocaleDateString(undefined, options);
        let sTime = date.toLocaleTimeString('en-US');

        result = sDate
        if(result && sTime)
            result+= ' ';
        if(sTime)
            result += sTime;

        return result;
    }
    
    static deepCopy(value, seen = new WeakMap()) {
        // Handle null and non-object types
        if (value === null || typeof value !== 'object') {
            return value;
        }

        // Handle circular references
        if (seen.has(value)) {
            throw new Error('Circular reference detected in object structure');
        }
        seen.set(value, true);

        // Handle Date objects
        if (value instanceof Date) {
            return new Date(value.getTime());
        }

        // Handle RegExp objects
        if (value instanceof RegExp) {
            return new RegExp(value.source, value.flags);
        }

        // Handle Map objects
        if (value instanceof Map) {
            const mapCopy = new Map();
            for (const [key, val] of value) {
                mapCopy.set(this.deepCopy(key, seen), this.deepCopy(val, seen));
            }
            return mapCopy;
        }

        // Handle Set objects
        if (value instanceof Set) {
            const setCopy = new Set();
            for (const val of value) {
                setCopy.add(this.deepCopy(val, seen));
            }
            return setCopy;
        }

        // Handle Arrays
        if (Array.isArray(value)) {
            return value.map(item => this.deepCopy(item, seen));
        }

        // Handle plain Objects
        const result = Object.create(Object.getPrototypeOf(value));
        for (const key in value) {
            if (Object.prototype.hasOwnProperty.call(value, key)) {
                result[key] = this.deepCopy(value[key], seen);
            }
        }
        return result;
    }
}