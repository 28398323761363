import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store/store'
import './pf.js'
import VueMeta from 'vue-meta';
import LogRocket from 'logrocket';
// Import the plugin here
import { Auth0Plugin } from './auth';
// Import the Auth0 configuration
import Icon from './components/Icon.vue'
import { initSnui } from '@/lib/snui'
import SnuiWrapper from '@/components/SnuiWrapper'

LogRocket.init('nhd9sy/steel-ninja');

const authInfo = {
  domain: process.env.VUE_APP_AUTH0_DOMAIN, // 'steel-ninja.us.auth0.com',//
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID, // '66iY0nP52N4XPjGPVFsm7p872J26hNOp', //
  authorizationParams:{
    audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  }      
  // Add any other necessary options here
};

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain: authInfo.domain,
  clientId: authInfo.clientId,
  authorizationParams: authInfo.authorizationParams,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true // ensure meta tags refresh on route changes
});

// Register Icon component globally
Vue.component('Icon', Icon)

Vue.component('SnuiWrapper', SnuiWrapper)

Vue.config.productionTip = false

// Initialize SNUI before Vue
initSnui()

// Then create Vue instance
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
