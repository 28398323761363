<template>
    <div >
    <div @click="toggle" class="btn">
      {{ text }}      
    </div>
    <div id="optionsContainer" style="display:inline;position:absolute; top:30px; left:30px; width:100px">
      <div v-for="option in options" :key="option.value" v-if="expanded" class="option" @click="select(option)" >
          {{ option.optionText }}
      </div>
    </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'MultiButton',
    data(){
      return {
        selection:'',
        expanded:false
      }
    },
    props: [
      'text',
      'selected',
      'options'
    ],
    methods:{
      select(v){        
        this.expanded=false;
        this.$emit('selected', v);
      },
      toggle(){
        this.expanded = !this.expanded;
        console.log(this)
      }
    },
    created(){
      
    }
  };
  </script>
  
<style scoped>
  .option{
    background: rgb(76, 120, 185);
    cursor: pointer;
    color: white;
  }

  .option:hover{
    background: rgb(94, 146, 223);
  }
</style>