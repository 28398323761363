<template>
    <div class="categoryMarkup">  
      Category Costs & Margin Markups per Pricing Level
      <div class="category-markup">
        <table id="pricingLevelMargins">
          <thead>
            <tr>
              <th>Category</th>
              <th style="width:130px">Cost</th>
              <th style="text-align: center;padding:5px 20px">Wholesale<br/>(%)</th>
              <th style="text-align: center;padding:5px 20px">Pro<br/>(%)</th>
              <th style="text-align: center;padding:5px 20px">Contractor<br/>(%)</th>
              <th style="text-align: center;padding:5px 20px">Retail<br/>(%)</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(category,i) in CategoryMarkupTypes" 
              :key="category.name">
              <td>
                {{ category.name.replace(/_/g, ' ') }} 
                <StatusIndicator :ref="category.si" />
              </td>
              <td>
                <div v-if="category.key!=null">
                $<input type="number" class="digit3"  @input="updateCostSettingByName($event, category.name)" :value="category.cost" min="0" :disabled="!canUpdateSupplierSettings" style="display:inline;width:50%"/> <span style="display:inline-block">{{ category.unit }}</span>
              </div>
            </td>
              <td v-for="level in pricingLevelHeaders" 
                  style="text-align: center;"
                  :key="`${category.name}-${level}`">
                  <input style="width:50px;text-align: center; border-width:0;border-radius: 3px;" :value="getMarkupValue(category.name, level)" v-on:input="setMarkupValue(category.name, level, $event.target.value)">
              </td>
              
            </tr>
          </tbody>

        </table>
      </div>
    </div>
  </template>
  
  <script>
  import { CORE } from '@/constants';
import Util from '@/lib/utility';
import { mapState, mapGetters , mapActions, mapMutations } from 'vuex';
import { computed } from 'vue';
import StatusIndicator from './StatusIndicator.vue';
import api from '../../src/api'

  export default {
    name: 'CategoryMarkup',
    props: {
        // scheme: {
        // type: Object,
        // required: true
        // }
    },
    data(){
        return{
            priceSchemeTypes: {
                NotSet: -1,
                ComponentPrice: 0,
                CostMarkup: 1,   
            },
            CategoryMarkupTypes:[
              {
                name: "Taper_Frame",
                key: "qt_tfCostLb",
                si: "si_Taper_Frame",
                cost: 0,
                unit: "/Lb"
              },
              {
                name: "Plate",
                key: null,
                si: "si_Plate",
                cost: 0,
                unit: "/Lb"
              },
              {
                name: "Beam",
                key: "qt_beamCostLb",
                si: "si_Beam",
                cost: 0,
                unit: "/Lb"
              },
              {
                name: "Tube",
                key: "qt_tubeCostLb",
                si: "si_Tube",
                cost: 0,
                unit: "/Lb"
              },
              {
                name: "Weld_Plates",
                key: null,
                si: "si_Weld_Plates",
                cost: 0,
                unit: "/Lb"
              },
              {
                name: "EaveStrut",
                key: "qt_eavestrutCostLb",
                si: "si_EaveStrut",
                cost: 0,
                unit: "/Lb"
              },
              {
                name: "Purlin",
                key: "qt_purlinCostLb",
                si: "si_Purlin",
                cost: 0,
                unit: "/Lb"
              },
              {
                name: "Import_Purlin",
                key: null,
                si: "si_Import_Purlin",
                cost: 0,
                unit: "/Lb"
              },
              {
                name: "Clips",
                key: null,
                si: "si_Clips",
                cost: 0,
                unit: "/Lb"
              },
              {
                name: "Insulation",
                key: null,
                si: "si_Insulation",
                cost: 0,
                unit: "/Lb"
              },
              {
                name: "Panel_Galvalume",
                key: "qt_panelCostGalvUnit",
                si: "si_Panel_Galvalume",
                cost: 0,
                unit: "/Lft"
              },
              {
                name: "Panel_Kynar",
                key: "qt_panelCostKynarUnit",
                si: "si_Panel_Kynar",
                cost: 0,
                unit: "/Lft"
              },
              {
                name: "Panel",
                key: "qt_panelCostSmpUnit",
                si: "si_Panel",
                cost: 0,
                unit: "/Lft"
              },
              {
                name: "Trim",
                key: "qt_trimCostUnit",
                si: "si_Trim",
                cost: 0,
                unit: "/Lft"
              },
              {
                name: "Fasteners",
                key: null,
                si: "si_Fasteners",
                cost: 0,
                unit: "/Lb"
              },
              {
                name: "Accessories",
                key: null,
                si: "si_Accessories",
                cost: 0,
                unit: "/Lb"
              }
            ],
            pricingLevelMarkups:[],
            debounceSavers: {}
        }
    },
    computed: {
      ...mapState('contextModule',[
        ,'current'
      ]),
      ...mapGetters('contextModule',[
        'userIsSysAdmin'
        ,'userIsSupAdmin'
        ,'userIsContAdmin'
      ]),
      supplier(){
        return this.current.supplier;
      },
      pricingLevelHeaders(){
        if(this.pricingLevelMarkups && this.pricingLevelMarkups.length > 0){
          const pricingLevels = [...new Set(this.pricingLevelMarkups.map(c => c.level))]
          .map(level => (CORE.pricingLevels[level] || `Level ${level}`)); // fallback if no name exists
          return pricingLevels;
        }
        
        return [];

      },
    },
    methods: {
      ...mapActions('businessModule', ['getBusinessSettings']),
      ...mapActions('pricingModule', ['fetchPricingLevels', 'updatePricingLevels']),
      updateCostSettingByName(e, categoryName){
        
        let cat = this.getCategoryByName(categoryName);
        if(!cat){
          console.log('no category');
          return;
        }
        console.log(cat)
        cat.cost = e.target.value;
        let saver = this.getSettingSaver(cat);
        saver(cat)
      },
      getCostSettingUnitByName(name){
        let setting = this.getCostSettingByName(name);
        if(!setting)
          return null;
        return setting.unit
      },
      getCostSettingSiByName(name){
        let setting = this.getCostSettingByName(name);
        if(!setting)
          return null;
        return setting.si
      },
      getCostSettingCostByName(name){
        let setting = this.getCostSettingByName(name);
        if(!setting)
          return null;
        return setting.value
      },
      getCostSettingKeyByName(name){
        let setting = this.getCostSettingByName(name);
        if(!setting)
          return null;
        return setting.key
      },
      getCategoryByKey(key){          
          let costSettings = this.CategoryMarkupTypes.filter( x => x.key == key);
          if(costSettings.length==1)
            return costSettings[0];
          return null;
        },
        getCategoryByName(name){
          
          let costSettings = this.CategoryMarkupTypes.filter( x => x.name == name);
          if(costSettings.length==1)
            return costSettings[0];

          return null;
        },
        async setBusinessSetting(cat){
                
            let settingsGroup = {
                businessId: this.supplier.id,
                name: cat.key,
                value: `${cat.cost}`
            }
            
            // refs in a v-for become an array because reasons
            let si = this.$refs[cat.si];
            if(si.length==1)
            {
              si  = si[0]
              if(si != undefined)
                  si.loading();
            }

            let response = await api.setBusinessSetting(settingsGroup);

            if(response.isError){
              if(si)
                si.fail();
            }
            else{
              if(si)
                si.success();

            }
        },
        async saveScheme() {
          let markupArray;
          markupArray = this.pricingLevelMarkups.flatMap(item => item.markups);
          await this.savePricingLevels(markupArray);
        },
        async savePricingLevels(pricingLevelMarkups){
            let payload = {businessId: this.supplier.id, pricingLevels: pricingLevelMarkups};
            return await this.updatePricingLevels(payload);
        },
        getMarkupValue(category, pricingLevel) {
          let markupsForLevel = this.pricingLevelMarkups.find(m => m.sLevel == pricingLevel)
          const markup = markupsForLevel.markups.find(m => m.sCategory === category);
          return markup ? markup.markup : 0;
        },
        setMarkupValue(catName, pricingLevel, newMarkup){
          let markupsForLevel = this.pricingLevelMarkups.find(m => m.sLevel == pricingLevel)          
          const markup = markupsForLevel.markups.find(m => m.sCategory === catName);
          markup.changed =true;
          markup.markup = newMarkup;

          this.dbSaveChangedMarkups();
        },
        async saveChangedMarkups(){
          let  markupArray = [ ];

          // iterate markup levels looking for changed markups
          this.pricingLevelMarkups.forEach((level) => {
            let changedMarkups = level.markups.filter(m => m.changed)
            if(changedMarkups && changedMarkups.length>0)
            {
              // add to the array, any markups in this level that have been changed
              markupArray.push(...changedMarkups);
            }
          })

          let indicators = [];
          markupArray.forEach((changedMarkup) => {

            // find the category data by markup category name
            let cat = this.getCategoryByName(changedMarkup.sCategory);
            let si = this.$refs[cat.si];
            if(si.length!=1)
              return;
            si  = si[0]; // v-for takes control of ref and makes it an array by force
            if(indicators.includes(si))
              return;
            indicators.push(si);
          })
          
          indicators.forEach((si) => {            
            if(si != undefined)
              si.loading();
          });

          let response = await this.savePricingLevels(markupArray);
          
          if(response){
              indicators.forEach((si) => {            
                  if(si)
                    si.success();
                });          
            }
            else{
              markupArray.forEach((changedMarkup) => {
                changedMarkup.changed=false; // we have to clear the dirtyBit 
              });

              indicators.forEach((si) => {            
                if(si)
                  si.success();
              });          
            }

          
        },
        initSettings(settings) {
            // assign the data values
            settings.forEach((settingsGroup) => {
                this.initSetting(settingsGroup);
            })               
          },
          initSetting(setting) {
              if (setting.value === "true" || setting.value === "false")
                  setting.value = setting.value === 'true';

              if (typeof setting.value !== 'undefined') {
                let cat = this.getCategoryByKey(setting.name); // the name of the business setting is the key in our array of category state objects
                if(!cat)
                    return;
                cat.cost = setting.value;
              }
          },
          async initPricingLevels(){
            if(this.current.mode == 1){
                let pricingLevels = await this.fetchPricingLevels(this.supplier.id);
                this.pricingLevelMarkups = pricingLevels.pricingLevels;
            }
          },
          getSettingSaver(setting){
            if(!setting.key)            
              console.error(`setting ${setting.name} has no key to use for debounced save`)
            let key = setting.key;
            if(!this.debounceSavers[key])
              this.debounceSavers[key]= Util.debounce(this.setBusinessSetting,1000);
            
            return this.debounceSavers[key];
          }
    },
    async created(){
      let isSupplierAdmin = this.userIsSupAdmin;
        let isSystemAdmin = this.userIsSysAdmin;        
        this.showSupplierOnlySetting = this.mode == 1;
        this.canUpdateSupplierSettings = isSupplierAdmin || isSystemAdmin;

        this.dbBusinessSettings = Util.debounce(this.setBusinessSetting,1000);
        this.dbSaveChangedMarkups = Util.debounce(this.saveChangedMarkups, 1000);
        let response = await this.getBusinessSettings(this.supplier.id);
              if(response.isError){
                  this.$toast.error(response.data.msg);
              }
              else{
                  let settings = response;
                  this.initSettings(settings);
              }
        await this.initPricingLevels();
    },
    components:{
      StatusIndicator
    }
  }
  </script>
  
  <style scoped>
  .categoryMarkup {
    flex: 1;
    min-width: 0;
    width: 70%;
    padding: 10px
  }
  
  h2 {
    margin-top: 0;
    text-wrap: nowrap;
  }
  
  .category-markup {
    margin-top: 10px;
    
  }
  
  .markup-input {
    text-align: right;
  }
  
  table {
    width: fit-content;
    border-collapse: collapse;
    font-size: 0.9em;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 6px;
    text-align: left;
  }
  
  th {
    background-color: #f2f2f2;
  }
  
  input, select {
    padding: 5px;
    width: 100%;
    box-sizing: border-box;
    border-width:0;
    border-radius:3px;
  }
  
  select {
    margin-top: 5px;
  }

  #pricingLevelMargins{
    margin: auto;
  }
  </style>