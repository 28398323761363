<template>
  <svg 
    aria-hidden="true" 
    focusable="false" 
    class="icon" 
    :class="customClass"
    :style="customStyle"
    :title="`icon: ${name}`"
  >
    <use 
      :href="iconUrl" 
      :xlink:href="iconUrl"
    />
  </svg>
</template>

<script>
const CACHE_BUSTER = ''

export default {
  name: 'Icon',
  props: {
    name: {
      type: String,
      required: true
    },
    customClass: {
      type: [String, Object, Array],
      default: ''
    },
    customStyle: {
      type: [String, Object],
      default: ''
    }
  },
  computed: {
    iconUrl() {
      return `/snui/snui-icons/snui-icons.svg?v=${CACHE_BUSTER}#${this.name}`
    }
  }
}
</script>

<style scoped>
/* Base icon styles are already in snui-styles/style.css */
</style> 