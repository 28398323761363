import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from '../views/LoginView.vue'
import Profile from '../views/Profile.vue';
import DashboardView from '@/views/DashboardView.vue';
import BusinessManagerView from '@/views/BusinessManagerView.vue';
import OnboardingView from '@/views/OnboardingView.vue';
import ItemManagerView from '@/views/ItemManagerView.vue';
import ItemEditorView from '@/views/ItemEditorView.vue';
import ProjectManagerView from '@/views/ProjectManagerView.vue';
import EmployeeManagerView from '@/views/UserManagerView.vue';
import ProjectView from '@/views/ProjectView'
import { authGuard } from "../auth/authenticationGuard";
import DesignView from '@/designer/DesignView.vue';
import HtmlTest from '@/views/HtmlTest.vue';

import NotFound from '@/views/NotFound.vue';
import PricingView from '@/views/PricingView.vue';
import BusinessSettingsView from '@/views/BusinessSettingsView.vue';
import ColorManagement from '@/views/ColorManagement.vue';
import SupplierTaxOptions from '@/views/SupplierTaxOptions.vue';

Vue.use(VueRouter)

// beforeEnter guard on parent routes will run before any of the child routes are navigated to
const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    props: true
  },
  {
    path: '/',
    name: 'projects',
    component: ProjectManagerView,
    beforeEnter: authGuard,
    
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    beforeEnter: authGuard,
  },
  {
    path: '/businesses/:id',
    name: 'businessesManager',
    component: BusinessManagerView,
    beforeEnter: authGuard,
    alias:['/supplier/:id/contractors'],
  },
  {
    path: '/contractor/:id/projects',
    name: 'contractorProjects',
    component: ProjectManagerView,
    beforeEnter: authGuard,
    props: true,
  },
  {
    path: '/supplier/:id/projects',
    name: 'supplierProjects',
    component: ProjectManagerView,
    beforeEnter: authGuard,
    props: true,
  },
  {
    path: '/project/:ref',
    name: 'project',
    component: ProjectView,
    alias: ['/project/new', '/project/:ref/edit', '/project/:ref/share'],
  },
  {
    path: '/project/:id/design',
    name: 'design',
    component: DesignView,
  },   
  {
    path: '/employeeManager',
    name: 'employeeManager',
    component: EmployeeManagerView,
    beforeEnter: authGuard,
  },
  
  {
    path: '/onboarding',
    name: 'onboarding',
    component: OnboardingView,
    beforeEnter: authGuard,
  },
  {
    path: '/entity/:id/itemManager',
    name: 'itemManager',
    component: ItemManagerView,
    beforeEnter: authGuard,
    alias: ['/supplier/:id/itemManager', '/itemManager'],
  },
  {
    path: '/entity/:id/settings',
    name: 'settings',
    component: BusinessSettingsView,
    beforeEnter: authGuard,
    alias: ['/supplier/:id/settings', '/contractor/:id/settings'],
  },
  {
    path: '/entity/:id/pricing',
    name: 'pricing',
    component: PricingView,
    beforeEnter: authGuard,
    alias: ['/supplier/:id/pricing', '/pricing'],
  },
  {
    path: '/entity/:id/taxes',
    name: 'taxes',
    component: SupplierTaxOptions,
    beforeEnter: authGuard,
    alias: ['/supplier/:id/taxes'],
  },
  {
    path: '/itemEditor',
    name: 'itemEditor',
    component: ItemEditorView,
    beforeEnter: authGuard,
  },
  {
    path: '/entity/:id/colors',
    name: 'colors',
    component: ColorManagement,
    beforeEnter: authGuard,
    alias: ['/supplier/:id/colors', '/colors'],
  },
  {
    path: '/html-test',
    name: 'htmlTest',
    component: HtmlTest,
    beforeEnter: authGuard,
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound // Create a NotFound component for 404 errors
  }
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// const authService = getInstance();

router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    // No matching route found
    next({ name: 'NotFound' })
  } else {
    // Route exists, proceed as normal
    next()
  }
})

// router.beforeEach(async (to, from) => {
//   // canUserAccess() returns `true` or `false`
//   const canAccess = false;
//   console.log('router beforeEach')
//   if (!canAccess){
//     console.log('cant access')
//      return {name: 'login'}

//   }
// })

export default router
