<script>
export default {
  name: 'SnuiWrapper',
  render() {
    return this.$slots.default[0];
  },
  mounted() {
    this.initSnuiFeatures(this.$el);
  },
  methods: {
    initSnuiFeatures(element) {
      
	// Initialize collapses
      window.snui.collapse.initCollapses(element);
      window.snui.collapse.initCollapseAccordions(element);

      // Initialize modals
      window.snui.modals.initModals(element);
      window.snui.modals.initModalOpenButtons(element);

      // Initialize popovers
      window.snui.popover1.setupTriggers(element);
    }
  }
}
</script>