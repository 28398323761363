<template>
    <select :id="uniqueId" v-model="selectedValue" class=" form-control" :style="getBorderColor(color)" style="padding:5px;" v-on:change=colorChange(selectedValue) :disabled="disable">
        <option v-for="col in colors" :value="col.id" :style="getOptionBackgroundColor(col)">
            {{ col.name }}
        </option>
    </select>
</template>

<!-- Color Select 2 is a copy of the original color select vue component -->
<!-- It's used on the Customer Settings page and the way changes are emited up the chain
        needed to work differently on that page than it does elsewhere. This vue component has a v-on:change on the select/option element instead of a watch on the value.
        This change was needed for whenever an employee is switching customers, the initialization of the color selector should not emit a color change -->
<script>
import util from '@/lib/utility';

    export default {
        name: 'color-select2',
        props: ['colors', 'selected', 'disable', 'settingName'],
        data: function () {
            return {
                uniqueId: '',
                color: {},
                selectedValue: this.selected ? this.selected.id : ''
            };
        },
        created(){
            this.uniqueId = `color_${this.jFnRandomId()}`;
            this.color = this.selected;
        },
        computed: {

        },
        methods: {
            jFnRandomId: function() {
                return '_' + Math.random().toString(36).substr(2, 9);
            },
            getHex: function (hex) {
                if (hex == null || hex == "")
                    return '';
                if (hex.length > 0 && hex[0] == '#')
                    return hex;
                return `#${hex}`;
            },
            getBorderColor: function (col) {
                if (col == null || !col.hex)
                    return {};
                return { 'border-left': `25px solid ${this.getHex(col.hex)}` };
            },
            getOptionBackgroundColor: function (col) {
                if (col) {
                    const hex = this.getHex(col.hex);
                    let s = `background-color: ${hex};`;
                    if (this.isDark(hex))
                        s += ` color: white;`;
                    return s;
                }
            },
            isDark(hexColor) {
                // Claude AI
            // Remove # prefix if it exists
            hexColor = hexColor.replace(/^#/, '');
            
            // Validate hex color format
            if (!/^[0-9A-Fa-f]{6}$/.test(hexColor)) {
                throw new Error('Invalid hex color format. Expected 6 characters (RGB)');
            }
            
            // Convert hex to RGB
            const r = parseInt(hexColor.slice(0, 2), 16);
            const g = parseInt(hexColor.slice(2, 4), 16);
            const b = parseInt(hexColor.slice(4, 6), 16);
            
            // Calculate perceived brightness using YIQ formula
            // YIQ = (R * 299 + G * 587 + B * 114) / 1000
            const yiq = (r * 299 + g * 587 + b * 114) / 1000;
            
            // YIQ < 128 is considered dark
            return yiq < 128;
        },
            colorChange(selected) {
                let found = this.colors.find(c => c.id == selected);
                
                this.color = util.deepCopy(found);//$.extend(true, {}, found);
                this.$emit('input', util.deepCopy(found));
                this.$emit('change', {id:this.settingName, val:util.deepCopy(found)});
            }
        },
        watch: {
            selectedValue: function () {
                let found = this.colors.find(c => c.id == this.selectedValue);
                this.color = util.deepCopy(found);
            },
            selected: function () {
                this.selectedValue = this.selected.id;
            }
        },
        mounted: function () {
            if (this.selected)
                this.selectedValue = this.selected.id;
        },
    }
</script>