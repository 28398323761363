import { render, staticRenderFns } from "./ColorManagement.vue?vue&type=template&id=2afb32b2&scoped=true"
import script from "./ColorManagement.vue?vue&type=script&lang=js"
export * from "./ColorManagement.vue?vue&type=script&lang=js"
import style0 from "./ColorManagement.vue?vue&type=style&index=0&id=2afb32b2&prod&scoped=true&lang=css"
import style1 from "./ColorManagement.vue?vue&type=style&index=1&id=2afb32b2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2afb32b2",
  null
  
)

export default component.exports