<template>
    <div class="subPanel">
        <!-- <div style="text-align: center;"><b>Layout</b></div> -->
        
        <div v-if='showConstraintEditor'>            
            <constraintInput :constraint="editingConstraint" />
        </div>
        <div v-else>
            <!-- <div style="display: block; font-weight: bold; text-align: center; cursor: pointer;"><font-awesome-icon icon="fa-duotone fa-warehouse" size="lg" /> <span style=""> Links</span></div> -->            
            <div v-if="$store.state.addingComponent">
                Place the structure.
                <button v-on:click="cancelAddingComponent" class="btn btn90" style='background:#df0404;' >Cancel</button>
            </div>  
            <div v-else>
                <button class="trayItem btn btn90 btnGreen" v-on:click="addStructure">Add a Main Structure</button>
                
                <TargetStructureName />
                <!-- <button class="trayItem btn btn90 btnGreen" v-on:click="addGarage">Add 24' x 24' Garage</button> -->
                <!-- <button class="trayItem btn btn90 btnGreen" v-on:click="addGabledPorch">Add Gabled Porch</button> -->
                <porchControls v-if="inCameraModeLayout" />
                <leanToControls v-if="inCameraModeLayout"/>
            </div>
            
            <input id="autolink" type="checkbox" :checked="autolink" v-on:change="toggleAutolink" title="Structure may be linked when placed " /><label for="autolink">Link Structures</label>
            <!-- <button class="trayItem btn btn90 btnGreen" v-on:click="addStructureConnection"><font-awesome-icon icon="fa-duotone fa-arrows-to-line" size="xl" style='position:absolute; left:17px'/> Link Structures</button>     -->
            
                

            <hr />
            <div>Existing Links</div>
            <div v-for='con in constraints' v-bind:key='con.id' >
                <div class='btn' style='margin:3px;width:80%;display: inline-block;color:black' v-bind:style='{"background": cssColor(con.color)}' @click='()=> {editConstraint(con)}'>
                    
                    {{con.child.structureName}}
                    
                </div>
                <font-awesome-icon style="" class="trash" :icon="['fad', 'trash-can-xmark']" size="lg" @click='()=> {deleteConstraint(con)}'/>
            </div>
        </div>
        
    </div>
</template>

<script>
import ColorHelper from '../js/helpers/colorHelper.js'
import {CORE, cameraModes} from '../js/_spec.js'
import constraintInput from './constraintInput.vue'
import porchControls from './porchControls.vue'
import leanToControls from './leanToControls.vue'
import TargetStructureName from './targetStructureName.vue'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

export default {
    data: ()=>{
        return {
        }
    },
    computed:{
        ...mapGetters('designModule', [
        'inCameraModeLayout',
        'addingBuilding',
        'constraintGuiData',
        'showConstraintEditor',
        'addingConstraint',
        'editingConstraint',
        'autolink',
        ]),
        constraints()
        {
            let root = this.$store.getters.constraintGuiData; 
            console.log('layoutControls computing constraints');
            return root;            
        },        

    },
    methods:{   
        ...mapActions('designModule',[
            'cancelAddingComponent',        
            'toggleAutolink',        
            'editConstraint',        
            'deleteConstraint',        
            'cancelAddingConstraint',        
            'addConstraint',        
            'addStructure',        
            'addGarage',        
            'addGabledPorch',
        ]),
        
        cssColor(hex){
            let c =ColorHelper.hexToBang(hex) 
            return c;
        }
      
    },
    components:{
            constraintInput,
            porchControls,
            leanToControls,
            TargetStructureName,
        }
}
</script>

<style scoped>
.btn90{
  width:90%;
}
.link{
    color: white;
    background: #4c78b9    
}

.btnGreen{
    background: green;
}

.btnGreen:hover{
    background: #34ab34;
}

.trash:hover{
    color:darkred;
}
</style>