<template>
  <div>
    <div v-if="isNewProject">
      <EditProjectForm id="newEditProjectForm" @close-modal="navigateBackToProjects"></EditProjectForm>
    </div>
    <div v-else>
      <!-- button & search -->
      <div v-if="isAuthenticated" class="grid gap-5" style="--grid-template-columns-sm: auto 1fr;">
        <div>
          <button type="button" class="btn btn-secondary" @click="navigateBackToProjects">
            <Icon name="line-arrow-left" custom-class="icon icon-size-1 shift-be-2" />     
            <span>Back to Projects</span>
          </button>
        </div>
        <div>
          <!-- just add the hidden attribute to this div until the search field is ready -->
          <div hidden>
            <label class="sr-only" for="field_250109084010">Composite Field (Icon)</label>
            <label class="composite-field no-dividers darker" style="--this-border-radius: var(--br-full);">
              <div class="text">
                <Icon name="magnifying-glass" custom-class="icon icon-size-1 mar-is-4 shift-be-2" style="margin-inline-end: calc(var(--sp-1) * -1);" />
              </div>
              <input class="darker"  type="text" id="field_250109084010" name="input" placeholder="Search Projects">
            </label>
          </div>
        </div>
      </div>

      <!-- main content grid -->
      <div class="grid mar-bs-7" style="
          column-gap: var(--sp-6); 
          --grid-template-columns-lg: 2fr 3fr; 
          ">
          
          <div style="--this-label-column-width: 8rem; --order-lg: 2;">

              <!-- heading & action icons -->
              <div class="grid gap-4" style="--grid-template-columns-sm: 1fr auto; align-items: end;">
                <div>
                  <div class="color-subdued" style="font-weight: 600;">Project:</div>
                  <h1 class="h1" style="line-height: 1.1;" >{{quote.name}}</h1>
                </div>
                <div>
                  <!-- action icons -->
                  <div class="flex gap-3 mar-ie-3" style="align-items:center; margin-block-end: calc(var(--sp-2) * -1);">
                    <div v-if="isAuthenticated">
                      <!-- <SnuiWrapper>
                        <div>
                          <span class="tooltip-wrap align-bs">
                            <button aria-labelledby="tooltip-edit-project" class="icon-button-1" data-open-modal="#modal-edit-project">
                              <span class="sr-only">Edit Project</span>
                              <Icon name="clipboard-task-note-edit" custom-class="icon icon-size-2 block"/>
                            </button>
                            <span role="tooltip" id="tooltip-edit-project">Edit Project</span>
                          </span>
                          
                          <dialog class="modal modal-medium modal-slide-from-block-start" id="modal-edit-project" autofocus>
                            <div class="modal-content">
                              <div class="rich-text remove-outer-children-margin">
                                <h2>Edit Project Details</h2>
                                <EditProjectForm id="existingEditProjectForm" @close-modal="closeEditProjectForm"></EditProjectForm>
                              </div>
                            </div>
                          </dialog>
                        </div>
                      </SnuiWrapper>					 -->

                      
                      <div>
                          <span class="tooltip-wrap align-bs">
                            <button aria-labelledby="tooltip-edit-project" class="icon-button-1" data-open-modal="#modal-edit-project" @click="editProject">
                              <span class="sr-only">Edit Project</span>
                              <Icon name="clipboard-task-note-edit" custom-class="icon icon-size-2 block"/>
                            </button>
                            <span role="tooltip" id="tooltip-edit-project">Edit Project</span>
                          </span>
                          
                        </div>


                    </div>
                    <div>
                      <span class="tooltip-wrap align-bs">
                        <button :disabled="isEditing" aria-labelledby="tooltip-edit-project" class="icon-button-1" v-on:click="shareProject()">
                          <span class="sr-only">Share</span>
                          <Icon name="upload-tray" custom-class="icon-button-1"/>
                        </button>
                        <span role="tooltip" id="tooltip-edit-project">Share</span>
                      </span>
                    </div>
                    <div v-if="isAuthenticated">
                      <span class="tooltip-wrap align-bs">
                        <button :disabled="isEditing" aria-labelledby="tooltip-edit-project" class="icon-button-1" v-on:click="copyProject()">
                          <span class="sr-only">Copy</span>
                          <Icon name="copy-document" custom-class="icon icon-size-2 block"/>
                        </button>
                        <span role="tooltip" id="tooltip-edit-project">Copy</span>
                      </span>
                    </div>
                    <div v-if="isSouthWest">
                      <span class="tooltip-wrap align-bs">
                        <button aria-labelledby="tooltip-edit-project" class="icon-button-1" v-on:click="openProjectFolder()">
                          <span class="sr-only">Open project folder</span>
                          <Icon name="open-folder" custom-class="icon icon-size-2"/>
                        </button>
                        <span role="tooltip" id="tooltip-edit-project">Open project folder</span>
                      </span>
                    </div>
                    <div v-if="isAuthenticated">
                      <span class="tooltip-wrap align-bs">
                        <button :disabled="isEditing" aria-labelledby="tooltip-edit-project" class="icon-button-1" v-on:click="setProjectArchiveState()">
                          <span class="sr-only">Archive</span>
                          <Icon name="treasure-chest" custom-class="icon icon-size-2 block"/>
                        </button>
                        <span role="tooltip" id="tooltip-edit-project">Archive</span>
                      </span>
                    </div>
                    <div v-if="!isShareMode && !isNewProject && isAuthenticated">
                      <span class="tooltip-wrap align-bs">
                        <button :disabled="isEditing" aria-labelledby="tooltip-edit-project" class="icon-button-1" @click="launchDesigner">
                          <span class="sr-only">Edit in 3D Designer</span>
                          <Icon name="cube" custom-class="icon icon-size-2 block"/>
                        </button>
                        <span role="tooltip" id="tooltip-edit-project">Edit in 3D<br />Designer</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="border-color-normal mar-bs-4 mar-be-5" style="border-width: var(--ln-3);" />
              
              <div class="img-wrap" style="border-radius: var(--br-2); --margin-block-end-xs: var(--sp-5); --display-lg: none;">
                <ProgressSpinner v-if="loadingProject"></ProgressSpinner>
                <img v-else-if="quote.state == 0" src="../assets/testBuilding.jpg" alt="Building">
                <img v-else :src="quote.image" alt="Building">
              </div>
              
              <!-- This is a 4 column grid on sm and up. Column 3 expands to fill the remaining space. All the other columns are sized to prevent wrapping. 
              All labels should short (15 characters or less) if possible. We want items that have short labels and values to go into columns 3 and 4, so 
              order the items to make that happen. You can force items into columns 1 and 2 by inserting 2 empty divs to occupy columns 3 and 4. -->
              <div class="grid" style="column-gap: var(--sp-5); --grid-template-columns-sm: var(--this-label-column-width) 1fr max-content max-content;">	
                <div>
                  <div class="text-label-1">Location</div>
                </div>
                <div>
                  <div class="mar-be-4" style="font-weight: 600;">{{ quote.delivered ? `${addressData.formattedWithoutZip} (Delivery)` : `${addressData.formattedWithoutZip} (No delivery)`  }}</div>
                </div>
                <div>
                  <div class="text-label-1">Zip Code</div>
                </div>
                <div>
                  <div class="mar-be-4" style="font-weight: 600;">{{ quote.zipCode }}</div>
                </div>
                <div>
                  <div class="text-label-1">Supplier</div>
                </div>
                <div>
                  <div class="mar-be-4" style="font-weight: 600;">{{ assignmentData.supplier.business ? `${assignmentData.supplier.business}, ${assignmentData.supplier.user}` : ""}}</div>
                </div>
                <div>
                  <div class="text-label-1">Seals</div>
                </div>
                <div>
                  <div class="mar-be-4" style="font-weight: 600;">{{ quote.seals ? `Include` : `None` }}</div>
                </div>
                <div>
                  <div class="text-label-1">Contractor</div>
                </div>
                <div>
                  <div class="mar-be-4" style="font-weight: 600;">{{ assignmentData.contractor.business ? `${assignmentData.contractor.business}, ${assignmentData.contractor.user}` : ""}}</div>
                </div>
                <div v-if="isAuthenticated">
                  <div class="text-label-1">Sales Tax</div>
                </div>
                <div v-else></div>
                <div v-if="isAuthenticated">
                  <div class="mar-be-4" style="font-weight: 600;">{{ taxOptionName }} - {{ taxOptionRate }}</div>
                </div>
                <div v-else></div>
                <div>
                  <div class="text-label-1">Building Code</div>
                </div>
                <div>
                  <div class="mar-be-4" style="font-weight: 600;">{{ quote.buildingCode }}</div>
                </div>
                <!-- force the next item to columns 1 and 2 -->
                <div></div>
                <div></div>
                
              </div>

              <hr class="mar-bs-3"  />

              <div class="mar-bs-5" >
                <div class="text-label-1">Bidsheet Notes</div>
                <div class="mar-bs-3" >
                  {{quote.notes ? `${quote.notes}` : `None` }}
                </div>
              </div>
              <div class="mar-bs-5" v-if="isSouthWest">
                <div class="text-label-1">Internal Notes</div>
                <div class="mar-bs-3" >
                  {{quote.internalQuoteTemplateNotes ? `${quote.internalQuoteTemplateNotes}` : `None` }}
                </div>
              </div>

              <hr class="mar-bs-6 border-color-normal" />

              <div class="grid mar-bs-5" style="column-gap: var(--sp-5); --grid-template-columns-sm: var(--this-label-column-width) 1fr">	
                <div v-if="isAuthenticated">
                  <div class="text-label-1">Price</div>
                </div>
                <div v-if="isAuthenticated">
                  <div class="mar-be-4">
                    <div class="flex gap-2" style="flex-wrap: nowrap; align-items: center;">
                      <div style="font-weight: 800;">{{ formattedPrice }}</div>
                      <!-- price actions-->
                      <div style="display:flex; align-items: center;">
                        <div class="tooltip-wrap align-bs shift-be-1" style="margin-block: calc(var(--sp-2) * -1);">
                          <button v-if="quote.state > 0" aria-labelledby="tooltip-adjust-quote" class="icon-button-1" @click="adjustQuote(quote.reference)">
                            <span class="sr-only">Adjust Quote</span>
                            <Icon name="pencil" custom-class="icon icon-size-1 block"/>
                          </button>
                          <span role="tooltip" id="tooltip-adjust-quote">Adjust Quote</span>
                        </div>
                        <SnuiWrapper>
                          <div class="popover1-anchor" >
                              <button class="icon-button-1" style="position: relative; z-index: 1; border-radius: var(--br-2);" data-popover1-trigger="toggle" aria-controls="popover-menu-price-lock" aria-expanded="false">
                                <span class="sr-only">Price Lock Menu</span>
                                <Icon name="screwdriver-wrench" custom-class="icon icon-size-1 block"/>
                              </button>                  
                              <!-- popover menu -->
                              <div id="popover-menu-price-lock" class="popover1 attach-outside-ie align-be click-outside-close click-outside-close-only" data-popover1-state="closed" role="dialog" aria-labelledby="popover-menu-price-lock">
                                <div class="mar-be-3 color-context-neutral-dark" style="inline-size: max-content; border-radius: var(--br-3); border-color: var(--color-fg-normal); overflow: hidden; height: 150px; width:100px; display: flex; justify-content: center; align-items: space-between;">
                                  <h2 class="sr-only" id="popover-menu-price-lock">User Actions</h2>
                                  <div class="grid mar-b-2" style="font-size: var(--fs-default);">
                                    <button v-for="opt in priceLockOptions" class="block pad-b-2 pad-i-4 hover-bg-shaded" data-popover1-trigger="close" aria-controls="popover-menu-price-lock" aria-expanded="false" name="select" id="price-lock-options" @click="priceLockChanged(opt)">
                                      {{ opt.optionText }}
                                    </button>
                                  </div>
                                </div>
                              </div>

                          </div>
                        </SnuiWrapper>
                      </div> <!-- end price actions-->
                    </div>
                  </div>
                </div>
                <div v-if="isAuthenticated">
                  <div class="text-label-1">Weight</div>
                </div>
                <div v-if="isAuthenticated">
                  <div class="mar-be-4" style="font-weight: 600;">{{ formattedWeight }}</div>
                </div>
                <div>
                  <div class="text-label-1">Status</div>
                </div>
                <div>
                  <div class="mar-be-4" style="font-weight: 600;">{{ dictionary.getTerm(quote.state) }}</div>
                </div>
              </div>

              <small class="block color-subdued small mar-bs-3" style="font-style: italic;" >{{`Last Modified: ${lastModifiedTimestamp}`}}</small>

              <hr class="mar-bs-4 border-color-normal" />

              <div class="table-wrap mar-bs-6">
                <table class="table-1 lines-b last-column-align-end" style="--cell-pad-b: var(--sp-5); --cell-pad-i: var(--sp-2);--color-table-thead-row-border: var(--color-table-row-border);">                            
                  <colgroup>
                    <col style="width:3rem;">
                    <col style="width:auto;">
                    <col style="width:auto;">
                    <col style="width:auto;">
                    <col style="width:auto;">
                  </colgroup>                            
                  <thead class="color-subdued" >
                    <tr>
                      <th colspan="2">File Name</th>
                      <th>Structure</th>
                      <th>Type</th>
                      <th class="align-end" >Size</th>
                    </tr>
                  </thead>                            
                  <tbody>
                    <tr v-if="isAuthenticated">
                      <td>
                        <Icon name="file-pdf" custom-class="icon icon-size-2 block"/>
                      </td>
                      <td>
                        <button class="text-link" @click="bidsheetPdfView(quote.reference, false)">Full Bid Sheet</button>
                        <StatusIndicator ref="si_fileRequest_bid_full"></StatusIndicator>
                      </td>
                      <td></td>
                      <td>PDF</td>
                      <td class="align-end"></td>
                    </tr>
                    <tr v-if="isAuthenticated">
                      <td>
                        <Icon name="file-pdf" custom-class="icon icon-size-2 block"/>
                      </td>
                      <td>
                        <button class="text-link" @click="bidsheetPdfView(quote.reference, true)">Summary Only</button>
                        <StatusIndicator ref="si_fileRequest_bid_summary"></StatusIndicator>
                      </td>
                      <td></td>
                      <td>PDF</td>
                      <td class="align-end"></td>
                    </tr>
                    <tr v-if="isAuthenticated" v-for="file in fileVariants">
                      <td>
                        <Icon v-if="file.fileName == 'BOM'" name="file-pdf" custom-class="icon icon-size-2 block"/>
                        <Icon v-else name="file-blank" custom-class="icon icon-size-2 block"/>
                      </td>
                      <td>
                        <button class="text-link" @click="file.fileName == 'BOM' ? downloadCsvBomExport(quote.reference,file.structure) : requestMBS(quote.reference,file.structure)">{{file.fileName}}</button>
                        <StatusIndicator :ref="`si_fileRequest_bom_${file.structure}`"></StatusIndicator>
                        <StatusIndicator :ref="`si_fileRequest_mbs_${file.structure}`"></StatusIndicator>
                      </td>
                      <td>{{ file.structure }}</td>
                      <td>{{ file.fileName == "BOM" ? 'CSV' : 'USR' }}</td>
                      <td class="align-end"></td>
                    </tr>
                    <tr v-for="fileName in additionalDocuments">
                      <td>
                        <Icon name="file-blank" custom-class="icon icon-size-2 block"/>
                      </td>
                      <td>
                        <button class="text-link" @click="downloadFileFromDocFolder(fileName)">{{fileName}}</button>
                        <StatusIndicator :ref="`si_fileRequest_doc_${fileName}`"></StatusIndicator>
                      </td>
                      <td></td>
                      <td></td>
                      <td class="align-end"></td>
                    </tr>
                  </tbody>
                </table>
                <div class="mar-bs-5"
                    @dragenter.prevent="onDragEnter"
                    @dragover.prevent
                    @dragleave.prevent="onDragLeave"
                    @drop.prevent="onDrop">
                  <!-- when a file is being dragged over the drop area, add the 'active' class -->
                  <!-- Also, this may be helpful: https://developer.mozilla.org/en-US/docs/Web/API/File_API/Using_files_from_web_applications -->
                  <div id="file-drop-area" class="color-context-neutral-shaded drop-area-1">
                    <div class="grid gap-3" style="justify-items: center;">
                      <div>
                        <Icon name="file-document-upload-publish" custom-class="icon icon-size-3 block"/>
                        <StatusIndicator ref="si_fileAdd"></StatusIndicator>
                      </div>
                      <div class="align-center" style="max-inline-size: 20rem;">
                        Drag and drop to upload your own files or 
                        <label class="input-file">
                          <!-- add the accept="" attribute to limit to certain file types -->
                          <input type="file" @change="handleFiles($event.target.files)">
                          <span class="text-link" style="font-weight: 600;">Browse your computer</span>
                        </label>.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div style="align-self: start; --order-lg: 1; --position-lg: sticky; inset-block-start: var(--sp-6);">
              <div class="img-wrap" style="border-radius: var(--br-2); --margin-block-end-xs: var(--sp-5); --margin-block-end-lg: var(--sp-0); --display-xs: none; --display-lg: block;">
                <ProgressSpinner v-if="loadingProject"></ProgressSpinner>
                <img v-else-if="quote.state == 0" src="../assets/testBuilding.jpg" alt="Building">
                <img v-else :src="quote.image" alt="Building">
              </div>

              <!-- history -->
              <SnuiWrapper>
                <div class="mar-bs-6">
                  <div class="collapse">
                    <button class="collapse-toggle" aria-expanded="false">
                      <span class="grid gap-3" style="grid-template-columns: 1fr auto; align-items: center;">
                        <span class="text-label-1">
                          Project History
                        </span>
                        <span>
                          <Icon name="arrow-down-1" custom-class="icon icon-size-1 block toggle-rotate-180 shift-be-2 color-subdued"/>
                        </span>
                      </span>
                    </button>
                    <div class="collapse-content toggle-transition-height">
                      <div class="tth-inner pad-bs-3">
                        
                        <div class="table-wrap">
                          <p class="text-label-2">Coming soon!</p>
                          <!-- <table class="table-1 lines-b last-column-align-end" style="font-size: var(--fs-2); --cell-pad-b: var(--sp-2); --cell-pad-i: var(--sp-2);--color-table-thead-row-border: var(--color-table-row-border);">                            
                            <colgroup>
                              <col style="width:auto;">
                              <col style="width:auto;">
                              <col style="width:auto;">
                            </colgroup>                            
                            <thead class="color-subdued" style="font-size: var(--fs-2);" >
                              <tr>
                                <th>Activity</th>
                                <th>User</th>
                                <th class="align-end" >Time</th>
                              </tr>
                            </thead>                            
                            <tbody>
                              <tr>
                                <td>Saved</td>
                                <td>Colby Hearn</td>
                                <td class="align-end">Tue, Dec 02, 2024 07:32:33 AM</td>
                              </tr>
                              <tr>
                                <td>Unarchived</td>
                                <td>Adam Steck</td>
                                <td class="align-end">Tue, Dec 02, 2024 07:32:33 AM</td>
                              </tr>
                              <tr>
                                <td>Archived</td>
                                <td>Adam Steck</td>
                                <td class="align-end">Tue, Dec 02, 2024 06:32:33 AM</td>
                              </tr>
                              <tr>
                                <td>Created</td>
                                <td>Adam Steck</td>
                                <td class="align-end">Tue, Dec 02, 2024 05:32:33 AM</td>
                              </tr>
                            </tbody>
                          </table> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </SnuiWrapper>
          </div>

      </div> <!-- main-content grid -->

      <message-modal
        :show="msgModal.showMessage"
        :message="msgModal.message"
        :issues="msgModal.issues"
        @close="closeMessage"
      />
      <QuoteAdjustmentModal :qaData.sync="qaData"></QuoteAdjustmentModal>
      <ProjectCopyModal :copyData.sync ="copyData"></ProjectCopyModal>
    </div>
    
    <dialog ref="editDialog" class="modal modal-medium modal-slide-from-block-start" id="modal-edit-project" autofocus>
                            <div class="modal-content">
                              <div class="rich-text remove-outer-children-margin">
                                <h2>Edit Project Details</h2>
                                <EditProjectForm id="existingEditProjectForm" @close-modal="closeEditProjectForm"></EditProjectForm>
                              </div>
                            </div>
                          </dialog>

  </div>
</template>

<script>
import ContextLogic from '@/lib/contextLogic';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import Util from '../lib/utility';
import VModal from 'vue-js-modal'
import Vue from 'vue'
import Vuex from 'vuex'
import BusinessUserPicker from '@/components/BusinessUserPicker.vue';
import ProgressSpinner from '@/components/ProgressSpinner.vue';
import MessageModal from '@/components/MessageModal.vue';
import { CORE } from '@/constants';
import QuoteAdjustmentModal from '@/components/QuoteAdjustmentModal.vue';
import ProjectCopyModal from '@/components/ProjectCopyModal.vue';
import Dictionary from '../Dictionary.js'
import api from '@/api';
import MultiButton from '@/components/MultiButton.vue';
import StatusIndicator from '@/components/StatusIndicator.vue';
import SnuiWrapper from '@/components/SnuiWrapper.vue';
import EditProjectForm from '@/components/EditProjectForm.vue';
Vue.use(Vuex)
Vue.use(VModal, {dialog:true});
export default {
  name: 'ProjectDetailsView',
  components:{
    BusinessUserPicker,
    ProgressSpinner,
    MessageModal,
    QuoteAdjustmentModal,
    ProjectCopyModal,
    MultiButton,
    StatusIndicator,
    SnuiWrapper,
    EditProjectForm
  },
  data() {
    return {
      dictionary: [],
      loadingProject: true,
      loadingDetails: true,
      isAuthenticated: false,
      quote: {},
      isNewProject: false,
      isShareMode: false,
      isEditing: false,
      creating: false,
      copying: false,
      saveInProgress: false,
      createBuildingFailed: false,
      isSouthWest: false,
      businessType: -1,
      input: {
        disableSave: false,
        error:{
          show: false,
          msg: ""
        }
      },
      copyData:{
        ref: null,
        show: false,
      },
      supplierBusiness: null,
      supplierUser: null,
      contractorBusiness: null,
      contractorUser: null,
      fileVariants: [],
      additionalDocuments:[],
      suppliers: [],
      contractors: [],
      isDragging: false,
      dragCounter: 0,
      msgModal:{
        showMessage: false,
        message: '',
        issues: [],
      },
      qaData: {
        ref: null,
        show: false
      },
      enabledTaxOptions: [],
      eventLog: [
        {
          msg: 'Saved by John Doe',
          ts: new Date().valueOf(new Date())
        },
        {
          msg: 'Saved by John Doe',
          ts: new Date().valueOf(new Date()) - 1000000000
        },
        {
          msg: 'Unarchived by John Doe',
          ts: new Date().valueOf(new Date()) - 2000000000
        },
        {
          msg: 'Archived by John Doe',
          ts: new Date().valueOf(new Date()) - 3000000000
        },
        {
          msg: 'Created by John Doe',
          ts: new Date().valueOf(new Date()) - 4000000000
        }

    ],
    priceLockOptions: [
      {
        optionText: 'Unlock',
        value: 0
      },
      {
        optionText: '30 days',
        value: 30
      },
      {
        optionText: '60 days',
        value: 60
      },
      {
        optionText: '90 days',
        value: 90
      },
    ]

    };
  },
  metaInfo() {
    return {
      title: this.pageTitle,
      meta: [
        { name: 'description', content: "something" },
        { property: 'og:title', content: this.quote.Name },
        { property: 'og:description', content: "something" },
        { property: 'og:image', content: this.quote.image },
        { property: 'og:url', content: `http://localhost:8080/project/${this.quote.ref}/share`},
        { property: 'og:type', content: 'website' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: this.quote.name },
        { name: 'twitter:description', content: "something"},
        { name: 'twitter:image', content: this.quote.image },
        { property: 'og:url', content: `http://localhost:8080/project/${this.quote.ref}/share` }
      ]
    }
  },
  computed: {
    ...mapState('contextModule', ['current', 'contractorParentData', 'user', 'userBusiness']),
    ...mapGetters('contextModule',[
        'userIsSysAdmin']),
    addressData(){
      const regex = new RegExp(`[,\\s]*${this.quote.zipCode}\\b.*`, 'i'); // Match the ZIP and everything after it, including preceding commas/spaces
      let withoutZip;
      if(this.quote.address)
        withoutZip = this.quote.address.replace(regex, '').trim();
      return {
        formatted: this.quote.address,
        zipCode: this.quote.zipCode,
        formattedWithoutZip: withoutZip
      }
    },
    assignmentData(){

      let obj = {
        supplier: {
          business: this.supplierBusiness ? this.supplierBusiness.businessName : "",
          user: this.supplierUser ? this.supplierUser.name : "",
        },
        contractor: {
          business: this.contractorBusiness ? this.contractorBusiness.businessName : "",
          user: this.contractorUser ? this.contractorUser.name : "",
        },
      }

      return obj;
    },
    lastModifiedTimestamp(){
      let date = new Date(this.quote.modified);
      const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      };
      return date.toLocaleDateString(undefined, options) + ' '+ date.toLocaleTimeString('en-US')
    },
    preventBidsheetDownload(){
      return this.isEditing || this.copying;
    },        
    designUrl(){      
      return `/project/${this.quote.reference}/design`;
    },
    nameValid(){
      return this.quote.name && this.quote.name.length !== 0;
    },
    addressValid(){
      return this.quote.address && this.quote.address.length !== 0;
    },
    supplierUserValid(){
      return this.quote.lvl1UserId != -1;
    },
    taxOptionValid(){
      return this.quote.taxOptionRef != '';
    },
    inputIsDisabled(){
      let disabled = !this.isEditing || this.isShareMode || !this.isAuthenticated;

      return disabled
    },
    formattedPrice(){
        return Util.formattedPrice(this.quote.price);
    },
    formattedWeight(){
      return Util.formattedWeight(this.quote.weight)
    },
    formattedPriceLockExpiration(){
      console.log('formattedPriceLockExpiration')
      return Util.formattedFullDate_TimeSensitive(this.quote.priceLockExpiration);
    },
    enabledTaxOptionsCount(){
      return this.enabledTaxOptions.length;
    },
    taxOption(){
      console.log('checking tax option')
      let to = this.enabledTaxOptions.filter(to => to.reference == this.quote.taxOptionRef);

      if(to.length==1)
        return to[0];      
      return null;
    },
    taxOptionName(){
      let to = this.taxOption
      if(!to)
        return "";
      return to.name;
    },
    taxOptionRate(){
      let to = this.taxOption
      if(!to)
        return "";
      return to.rate+"%";
    }
  },
  methods: {
    ...mapActions('projectModule', ['fetchProject', 'createBuildingQuote', 'updateBuildingQuote', 
    'requestCsvBomExport', 'fetchMBS', 'generateBuildingQuotePDFV3',
    'fetchStructureDetails', 'fetchFileNamesInProjectDocFolder', 'uploadDocuments', 'requestFileFromDocFolder', 'deleteAdditionalDocument']),
     ...mapActions('businessModule', ['fetchAllBusinesses', 'fetchAllSuppliers', 'fetchSubBusinesses', 'fetchContractorsByRep', 'fetchBusiness', 'getBusinessSetting']),
     ...mapActions('userModule', ['fetchUser', 'fetchUsersFromSupplier', 'fetchUsersFromContractor']),
    editProject(){
      this.isEditing=true;
      this.$refs.editDialog.showModal();
    },  
     async priceLockChanged(selected){
        console.log(selected);
        await api.setProjectPriceLock(this.quote.reference, selected.value);
        this.quote = await this.fetchProject(this.quote.reference);
     },
     formattedEventTimestamp(v){
      return Util.formattedFullDate_TimeSensitive(v);
     },
    setAddress(v){
      this.quote.address = v.formattedAddress;
      this.quote.zipcode = v.zipCode;
    },
    async closeEditProjectForm(){
      const modal = document.getElementById('modal-edit-project')
      modal.close();
      this.quote = await this.fetchProject(this.quote.reference);
    },
    navigateBackToProjects(){
      let id = this.userBusiness.id;
      if(this.userBusiness.type == 0)
        this.$router.push({path: `/`});
      else if(this.userBusiness.type == 1)
        this.$router.push({path: `/supplier/${id}/projects`});
      else
        this.$router.push({path: `/contractor/${id}/projects`});
    },

    onDragEnter(e) {
      this.dragCounter++;
      this.isDragging = true;
    },
  
    onDragLeave(e) {
      this.dragCounter--;
      if(this.dragCounter ==- 0)
        this.isDragging = false;
    },
    onDrop(e) { 
      debugger;
      this.dragCounter = 0;
      e.preventDefault();
      this.isDragging = false;
      const filesToUpload = e.dataTransfer.files;
      hanldeFiles(filesToUpload);
    },
    handleFiles(filesToUpload){
      debugger;
      let existingFiles = [];
      if(this.additionalDocuments.length>0){
        existingFiles = this.detectExistingFiles(filesToUpload);
      }
   
      if(existingFiles.length > 0){
        let existingNamesList = existingFiles.map(name => `<li><b>${name}</b></li>`).join('');
        this.$modal.show('dialog', {
          title: 'Overwrite existing file(s)?',
          text: `<ul>
                  ${existingNamesList}
                </ul> <br>
                File(s) with this name already exists. Are you sure you want to override to replace it? This action cannot be undone.<br><br>`,
          buttons:[
            {
              title: 'Cancel',
              handler: () => {
                this.$modal.hide('dialog')
              }
            },
            {
              title: 'Overwrite',
              handler: () => {
                this.$modal.hide('dialog')
                this.uploadFiles(filesToUpload);
              }
            },
          ]
        })
      }
      else  
        this.uploadFiles(filesToUpload);
    },
    detectExistingFiles(files){
      let filesThatAlreadyExist = [];
      for(let i = 0; i < files.length; i++){
        const file = this.additionalDocuments.find(f => f === files[i].name)
        if(file){
          filesThatAlreadyExist.push(file);
        }
      }
      return filesThatAlreadyExist;
    },
    async uploadFiles(files) {
      let si = this.$refs["si_fileAdd"];
      if(si)
        si.loading();
      
      let formData = new FormData();
      for(let i = 0; i < files.length; i++){
        const file = files[i];
        formData.append('documents', file, file.name)
      }
      // files.forEach(file => {
      //   formData.append('document', file, file.name)
      // });

      let payload = {ref: this.quote.reference, documents: formData}

      let response = await this.uploadDocuments(payload);
      if(response.isError){
        if(si)
          si.hide();
        let issues = response.data.issues ? response.data.issues : [];
        this.$toast.error(`Error occurred during file upload: ${response.data.msg}`);
        //this.showErrorMessage(response.data.msg, issues);
      }
      else{
        if(si)
          si.hide();
        // this.showSuccessMessage("File(s) uploaded successfully");
        this.$toast.success("File(s) uploaded successfully")
        this.additionalDocuments = await this.fetchFileNamesInProjectDocFolder(this.quote.reference);
      }
    },
    async confirmAdditionalDocDeletion(fileName){
      this.$modal.show('dialog', {
          title: 'Confirm delete?',
          text: `Are you sure you want to delete ${fileName}?`,
          buttons:[
            {
              title: 'Cancel',
              handler: () => {
                this.$modal.hide('dialog')
              }
            },
            {
              title: 'Delete',
              handler: () => {
                this.$modal.hide('dialog')
                this.removeAdditionalDoc(fileName);
              }
            },
          ]
      }) 
    },
    async removeAdditionalDoc(fileName){
      
      let payload = {ref: this.quote.reference, fileName: fileName}
      let response = await this.deleteAdditionalDocument(payload);
      if(response.isError){
        this.$toast.error(response.data.msg);
      }
      else{
        // this.showSuccessMessage("File(s) uploaded successfully");
        this.$toast.success("File deleted sucessfully.")
        this.additionalDocuments = await this.fetchFileNamesInProjectDocFolder(this.quote.reference);
      }
      
    },
    async setProjectEditMode(){
      if(!this.$route.path.includes("edit")){
        //window.history.replaceState({}, '', window.location.pathname + '/edit'); // this allows the url to visually change without losing state
        //  this.$router.push(`${this.$route.path}/edit`); // this causes a navigation and we lose the state we just set
      }

      this.isEditing=true;

      // this.$nextTick(()=>{

      //   this.$refs.address.setAddressDetails({
      //     formattedAddress: this.quote.address,
      //     zipcode:this.quote.zipCode
      //   })

      //   this.initPickerData();
      // })
    },
    inputfieldChanged(){
      // this.isEditing = true;
      this.input.error.show = false;

      let msg = this.buildErrorMsg();
      if (msg) {
          this.input.disableSave = true;
          this.input.error = {
              msg,
              show: true
          }
      } else {
          this.input.disableSave = false;
          this.input.error = {
              msg: null,
              show: false
          }
      }
    },
    buildErrorMsg() {
      let fieldList = [];
      if (this.quote.name.length === 0) fieldList.push("Name");

      if(this.quote.address.length === 0){
          fieldList.push('address');
      }


      if (fieldList.length > 0)
          return "Invalid Fields: " + fieldList.join(', ');

      if (this.quote.frameType === "Weld up" || this.quote.frameType === "Weld PLUS") {
          if (parseFloat(this.quote.width) > 60)
              return "Weld-up building width cannot exceed 60ft.";
      }

      if (/[\\/:*?"<>|]/.test(this.quote.name))
          return "A building name cannot contain any of the following characters: \\ / : * ? \" < > |";

      return null;
    },
    async commitAndLaunchDesigner(){
      console.log('new building quote', this.quote)
      await this.commitDetails();
      if (!this.createBuildingFailed)      
          this.launchDesigner();
    },
    async commitDetails(){
      if(!(this.nameValid && 
      this.addressValid && 
      this.supplierUserValid))
      {
        this.input.disableSave = true;
        this.input.error = {
            msg: "Please fill in all required fields",
            show: true
        }
        return;
      }
      else
        this.input.disableSave = false;

      this.input.error = {
          msg:'',
          show: false
      }

      // this.geocodeLocation();
      this.saveInProgress = true;
      let response;
      if(this.creating){
        response = await this.createBuildingQuote(this.quote);
      }
      else
        response = await this.updateBuildingQuote(this.quote);

      if(response.isError){ 
        this.commitDetailsError(response.data)
      }
      else{
        this.creating = false;
        this.isEditing = false;
        this.saveInProgress = false;
        this.createBuildingFailed = false;
        this.quote = response; // client learns id and reference
       }
    },
    
    launchDesigner(){
      if(this.creating || this.isEditing || this.copying)
          return;
      let designerUrl;
      this.$router.push(this.designUrl);
    },
    commitDetailsError(errData){
      this.createBuildingFailed = true;
      if(errData && errData.locked){
        this.showAlert_QuoteTemplateLocked();
      }
      else{
        this.$modal.show('dialog', {
            title: 'Warning',
            text: `${errData.msg}`,
            buttons:[
              {
                title: 'OK',
                handler: () => {
                  this.$modal.hide('dialog')
                }
              },
            ]
        })
      }
        
    },
    showAlert_QuoteTemplateLocked(){
      this.$modal.show('dialog', {
          title: 'Warning',
          text: `Could not complete the operation becasue this design's quote file is locked. Please close the quote file and try again.`,
          buttons:[
            {
              title: 'OK',
              handler: () => {
                this.$modal.hide('dialog')
              }
            },
          ]
      })
    },
    async shareProject(){
      await navigator.clipboard.writeText('');
      try {
        // Generate the shareable link (you may need to adjust this based on your routing structure)
        const shareableLink = `${process.env.VUE_APP_API_BASE_URL}/share/${this.quote.reference}`;
        
        // Copy the link to clipboard
        await navigator.clipboard.writeText(shareableLink);
        
        // Show a toast notification
        this.$toast.success(`Share link copied to clipboard!`);
      } catch (err) {
        console.error('Failed to copy link:', err);
        this.$toast.error('Failed to copy link. Please try again.');
      }
    },
    async openProjectFolder(){
      window.open(this.quote.projectDirectoryLink, '_blank');
    },
    copyProject(){
      this.copyData.ref = this.quote.reference;
      this.copyData.show = true;
    },
    async setProjectArchiveState(){
      this.quote.archive = !this.quote.archive
      await this.commitDetails();
    },
    async downloadFileFromDocFolder(fileName){
      
      let si = this.$refs[`si_fileRequest_doc_${fileName}`];
      if(si){
        if(Array.isArray(si)){ 
                // Vue takes overwhen refs are in an v-for context and makes the Ref an array no matter what.
                si= si[0];
            }
            si.loading();
      }

      let response = await this.requestFileFromDocFolder({id:this.quote.reference, name: fileName});
      if(response.isError){
        //this.$toast.error(response.data.msg);
        si.fail();
      }
      else{
        let fileData = response;
        let filename = fileData.fileDownloadName;// this.quoteName.replace(/[^a-z0-9_\-]/gi, '_').replace(/_{2,}/g, '_') + ".USR";
        var blob = Util.b64toBlob(fileData.fileContents, fileData.contentType);
        Util.downloadBlob(blob, filename);
        //this.$toast.success("File downloaded successfully!")
        si.success();
      }
    },
    async downloadCsvBomExport(ref, structureName){
      let si = this.$refs[`si_fileRequest_bom_${structureName}`];
      if(si){
        if(Array.isArray(si)){ 
                // Vue takes overwhen refs are in an v-for context and makes the Ref an array no matter what.
                si= si[0];
            }
            si.loading();
      }

      let response = await this.requestCsvBomExport({id:ref, name: structureName});
      if(response.isError){
        this.commitDetailsError(response.data)
        if(si) si.fail();
      }
      else{
        if(si) si.success();
        let bomData = response;
        let filename = `${structureName}_BOM.csv`;// this.quoteName.replace(/[^a-z0-9_\-]/gi, '_').replace(/_{2,}/g, '_') + ".USR";
        var blob = Util.b64toBlob(bomData.fileContents, bomData.contentType);
        Util.downloadBlob(blob, filename);
      }

    },
    async requestMBS(ref, structureName) {
      debugger;
      let si = this.$refs[`si_fileRequest_mbs_${structureName}`];
      if(si){
        if(Array.isArray(si)){ 
                // Vue takes overwhen refs are in an v-for context and makes the Ref an array no matter what.
                si= si[1];
            }
            si.loading();
      }

      let response = await this.fetchMBS({id: ref, name: structureName});
      if(response.isError){
        this.commitDetailsError(response.data)
        if(si) si.fail();
      }
      else{
        if(si) si.success();
        let mbsData = response;
        let filename = "DesCtrlQuote.USR";// this.quoteName.replace(/[^a-z0-9_\-]/gi, '_').replace(/_{2,}/g, '_') + ".USR";
        var blob = Util.b64toBlob(mbsData.fileContents, mbsData.contentType);
        Util.downloadBlob(blob, filename);
      }
    },
    async bidsheetPdfView(ref, summaryOnly) {
      let si = null
      if(summaryOnly)
        si = this.$refs["si_fileRequest_bid_summary"];

      else
        si = this.$refs["si_fileRequest_bid_full"];

      if(si)
        si.loading();

      let response = await this.generateBuildingQuotePDFV3({reference: ref, summaryOnly: summaryOnly});
      if(response.isError){
        this.commitDetailsError(response.data);
        if(si) si.fail();
      }
      else{
        //https://localhost:7291/projects/openBuildingQuotePDFV3/44253%20-%20border%20%232%20-%20%20Full.pdf?quoteRef=30453986-f00d-4524-8eab-1f7ae92ae53d&summaryOnly=false
        //quoteRef=30453986-f00d-4524-8eab-1f7ae92ae53d&summaryOnly=false
        if(si) si.success();
        let filename =encodeURIComponent(response.filename);
        let bidsheetViewUrl = api.getApiRequestPath(`/project/openBuildingQuotePDFV3/${filename}`);
        bidsheetViewUrl+=`?quoteRef=${ref}`
        bidsheetViewUrl+=`&summaryOnly=${summaryOnly}`
        window.open(bidsheetViewUrl);
      }
    },
    async initTaxOptions(supplierId){
      // this method is called if the supplier business is drop down is changed
      if(supplierId){
        let allTaxOpts = await api.getAllBusinessTaxOptions(supplierId);
        let enabledTaxOpts = allTaxOpts.filter(opts => opts.enabled)
        this.enabledTaxOptions = enabledTaxOpts;

        // Set the project tax option to the supplier's default sales tax rate
        // ONLY IF the current tax option isn't in the enabledTaxOptions
        if(!this.enabledTaxOptions.find(opt => opt.reference == this.quote.taxOptionRef)){
          let defaultSalesTaxOption = await this.getBusinessSetting({businessId: supplierId, settingName: "qt_defaultSalesTaxRate"});
          this.quote.taxOptionRef = defaultSalesTaxOption.value;
        }
        
      }
    },
    async onSupplierSelected(id){      
        this.initTaxOptions(id);// change in supplier impacts available tax options (whether new project or existing)
    },
    async onSupplierUserSelected(id){
      this.contractors = await this.fetchContractorsByRep(id);
      this.quote.lvl1UserId = id;
      this.inputfieldChanged();
    },
    async onContractorSelected(id){      
    },
    async onContractorUserSelected(userId){
      this.quote.lvl2UserId = userId;
      this.inputfieldChanged();
    },
    adjustQuote(ref){
      this.qaData.ref = ref;
      this.qaData.show = true;
    },
    async updatePricing(){
      let response = await api.updatePricing(this.quote.reference);

      if(response.isError){
        let errData = response.data;
        if(errData.locked)
          this.$toast.fail("Project file is open.")
        else
          this.$toast.fail("An error occurred while trying to update project pricing.")
      }
      else{
        this.$toast.success("Project pricing updated successfully!")
      }
    },
    async init_Anonymous(){
      await this.initAnonymous_PickerData();
    },
    
    async initAnonymous_PickerData(){
      this.$refs.supplierPicker.disablePickers();
      this.$refs.contractorPicker.disablePickers();

      //end-user should never be shown supplier info
      this.$refs.supplierPicker.showBusiness = false; 
      this.$refs.supplierPicker.showUser = false;

      this.$refs.contractorPicker.showBusiness = false;
      this.$refs.contractorPicker.showUser = false;

      //this.$refs.supplierPicker.users = [await this.fetchUser(this.quote.lvl1UserId)];
      //this.$refs.supplierPicker.onUserSelected(this.quote.lvl1UserId);

      // this.$refs.contractorPicker.users = [await this.fetchUser(this.quote.lvl2UserId)];
      // this.$refs.contractorPicker.onUserSelected(this.quote.lvl2UserId);
    },
    async init_Authenticated(){
      if(!this.isNewProject){
        let lvl1User = await this.fetchUser(this.quote.lvl1UserId);
        this.initTaxOptions(lvl1User.businessId);
      }
    },
    async initPickerData(){
      console.log(this.userBusiness.type);
      // Use context to figure out who the heck this project is being made for.
      if(this.businessType == CORE.entities.enterprise){
        this.$refs.supplierPicker.showBusiness = true;
        this.$refs.supplierPicker.showUser = true;
        this.$refs.contractorPicker.showBusiness = true;
        this.$refs.contractorPicker.showUser = true;


        // We have absolutely no clue.
        this.suppliers = await this.fetchAllSuppliers();


        // If it's a pre-exisitng project, we have some clue who the supplier is
        // because every project should have a supplier attached to it
        if(!this.isNewProject){
          if(this.quote.lvl1UserId > -1){
            let lvl1User = await this.fetchUser(this.quote.lvl1UserId);
            this.$refs.supplierPicker.onBusinessSelected(lvl1User.businessId);
            this.$refs.supplierPicker.onUserSelected(this.quote.lvl1UserId);
          }
          if(this.quote.lvl2UserId > -1){
            let lvl2User = await this.fetchUser(this.quote.lvl2UserId);
            this.$refs.contractorPicker.onBusinessSelected(lvl2User.businessId);
            this.$refs.contractorPicker.onUserSelected(this.quote.lvl2UserId);
          }

        }
          
      }
      else if(this.businessType == CORE.entities.supplier){
        // We know who the project supplier is, but not the comapny user nor the contractor user the project is assigned to.
        this.$refs.supplierPicker.showBusiness = false;
        this.$refs.supplierPicker.showUser = true;
        this.$refs.contractorPicker.showBusiness = true;
        this.$refs.contractorPicker.showUser = true;

        // get the users from the supplier
        this.$refs.supplierPicker.users = await this.fetchUsersFromSupplier(this.userBusiness.id);

        // If it's a pre-exisitng project, we have some clue who the supplier is
        // because every project should have a supplier attached to it
        if(!this.isNewProject){
          if(this.quote.lvl1UserId > -1){
            //let lvl1User = await this.fetchUser(this.quote.lvl1UserId);
            this.$refs.supplierPicker.onUserSelected(this.quote.lvl1UserId);
          }
          if(this.quote.lvl2UserId > -1){
            let lvl2User = await this.fetchUser(this.quote.lvl2UserId);
            this.$refs.contractorPicker.onBusinessSelected(lvl2User.businessId);
            this.$refs.contractorPicker.onUserSelected(this.quote.lvl2UserId);
          }

        }
      }
      else{
        // We know who the project supplier, supplier user, and contractor is, but not the contractor user the project is assigned to.
        this.quote.lvl1UserId = this.contractorParentData.rep.sub

        this.$refs.supplierPicker.showBusiness = false;
        this.$refs.supplierPicker.showUser = false;
        this.$refs.contractorPicker.showBusiness = false;
        this.$refs.contractorPicker.showUser = true;
        this.$refs.contractorPicker.users = await this.fetchUsersFromContractor(this.userBusiness.id);     
        
        // If it's a pre-exisitng project, we have some clue who the supplier is
        // because every project should have a supplier attached to it
        if(!this.isNewProject){
          if(this.quote.lvl2UserId > -1){
            this.$refs.contractorPicker.onUserSelected(this.quote.lvl2UserId);
          }

        }
      }      
    },
    showSuccessMessage(message) {
      this.msgModal.message = message
      this.msgModal.issues = []
      this.msgModal.showMessage = true
    },
    showErrorMessage(message, issues = []) {
      this.msgModal.message = message
      this.msgModal.issues = issues
      this.msgModal.showMessage = true
    },
    closeMessage() {
      this.msgModal.showMessage = false
    },
    async postAuthInit(){
      // now that the user is authenticated...
      await ContextLogic.initContext(this);        
      this.businessType = this.userBusiness.type;
      let swFeatureflag = this.userBusiness.featureFlags.find(flag => flag.name == 'isSouthwestMetal');
      let isSystemAdmin = this.userIsSysAdmin;
      this.isSouthWest = isSystemAdmin || ((swFeatureflag) ? swFeatureflag.enabled : false);
      // await this.init_Authenticated(); // this cannot be done here. this.quote must be filled in before we set the pickers
    },
    createStructureFileVariants(structureDetails){
      if(structureDetails.isError)
        return;

      this.fileVariants = structureDetails.flatMap(item => {
          if (!item.isPorch) {
            return [
              { id: item.id, structure: item.name, fileName: 'BOM' },
              { id: item.id, structure: item.name, fileName: 'MBS' }
            ];
          }
          return [];
        });
    },
    async initPage(){
      this.isAuthenticated = this.$auth.isAuthenticated;
      if(this.isAuthenticated){
        // when clicking links to navigate, this.$auth will already be initialized
        await this.postAuthInit()

      }
      this.isEditing = this.$route.path.includes("edit") || this.$route.path.includes("new");

      this.isNewProject = this.$route.path.includes('/new');
      console.log('ProjectView State is New:', this.isNewProject);
      this.isShareMode = this.$route.path.includes('share');

      console.log('contractor parent data',this.contractorParentData)
      let pathParts = this.$route.path.split("/");
      let projectRef = pathParts[2];


      if(this.isNewProject){
        if(!this.isAuthenticated)
          this.$router.go(-1) ;
        
        //let defaulttaxOptionRef = this.getBusinessSetting({businessId: thi})
        
        const newBuildingQuote = {
          id: 0,
          name: "",
          address: '',
          zipCode: '',
          lvl1UserId: -1,
          lvl2UserId: -1,
          archive: false,
          tax: "No - Exempt",
          taxOptionRef: '',
          delivered: false,
          design: "",
          image: "",
          reference: '',
          buildingCode: "IBC 2015",
          seals: true,
          state: 0,    // 0 is NEW
          notes: "",
          internalQuoteTemplateNotes:"",
          version:3,
          priceScheme: 0
        };
        this.quote = newBuildingQuote;

        this.creating = true;
        // tempEditing = true;
        this.copying = false;
        this.loadingProject=false;
        this.loadingDetails=false;

        this.setProjectEditMode()
      } 
      else{
        this.quote = await this.fetchProject(projectRef);
        if(!this.quote.id)
          return; 

        // if(this.isAuthenticated)
        //   this.init_Authenticated();
        this.loadingProject=false;     
        let structureDetails = await this.fetchStructureDetails(this.quote.id);
        this.createStructureFileVariants(structureDetails);
        this.additionalDocuments = await this.fetchFileNamesInProjectDocFolder(this.quote.reference);    
        this.loadingDetails=false;

        // tempEditing = false;
      }
console.log(this.quote);
      if(this.isShareMode || !this.isAuthenticated){
        // pickers don't even render when not authenticated
        if(this.isShareMode)
          this.init_Anonymous();
      }
      else{
        this.init_Authenticated();
      }
    }
  },
  watch: {
  async '$auth.loading'(loading) {
      if (!loading) {
        await this.initPage();
      }
  },
  "quote.lvl1UserId": async function(newId, oldId){
      if(!newId || newId == -1)
        return; 

      this.supplierUser = await this.fetchUser(newId);
      this.supplierBusiness = await this.fetchBusiness(this.supplierUser.businessId);
  },
  "quote.lvl2UserId": async function(newId, oldId){
      if(!newId || newId == -1)
        return;

      this.contractorUser = await this.fetchUser(newId);
      this.contractorBusiness = await this.fetchBusiness(this.contractorUser.businessId);
  }
},
  async mounted(){

  },
  async created(){
    this.dictionary = new Dictionary();

    // ProjectView route does not have an AuthGuard attached to its BeforeEnter,
    // because ProjectView needs to be accessible for both authenticated and anonymous users.
    // Hard refreshes cause auth0 to lose all of its context (isAuthenticated,auth0Client,etc).
    // If we hard refresh the ProjectView page while we are authenticated, the page will always
    // initialize in anonymous mode, because the auth0 context is lost and the page never has a chance 
    // to wait for auth0 context to reload since it doesn't have an AuthGuard. 
    // So, we need a watch on $auth.loading to retry page initialization in case we are authenticated. 
    // If not authenticated, no harm.
    if (!this.$auth.loading) {
        await this.initPage();
    }
  
  },  

}
</script>

<style lang="scss" scoped>



</style>