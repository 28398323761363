<template>
  <button class="submenu-link" @click="routeTo" :class="{ active: active }">
    <span>{{ text }}</span>
  </button>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'SubmenuLink',
  props: {
    text: {
      type: String,
      required: true
    },
    routePath: {
      type: String,
      required: true
    },
    query: {
      type: Object,
      default: () => undefined
    }
  },
  data() {
    return {
      linkRoute: null
    };
  },
  computed: {
    active() {
      if (!this.$route || !this.linkRoute) return false;

      // Convert route pattern to regex to handle dynamic segments
      const regexPattern = this.routePath
        .replace(/:\w+/g, '[^/]+') // Replace :id or :ref with any non-slash chars
        .replace(/\//g, '\\/');    // Escape forward slashes
      const regex = new RegExp(`^${regexPattern}$`);

      // Check if path matches and query params match (if any)
      return regex.test(this.$route.path) && 
        (typeof this.query == 'undefined' ||
          JSON.stringify(this.$route.query) == JSON.stringify(this.query));
    }
  },
  methods: {
    ...mapMutations('contextModule', ['refresh']),
    
    routeTo() {
      if (!this.linkRoute) return;
      
      if (this.active) {
        this.refresh();
      } else {
        this.$router.push({ 
          path: this.routePath, 
          query: this.query 
        });
      }
    }
  },
  mounted() {
    this.linkRoute = this.$router.matcher.match(this.routePath);
  }
};
</script> 