<template>
  <div >

    <!-- button & search -->
    <div class="grid gap-5" style="--grid-template-columns-sm: auto 1fr;">
      <div>
        <button v-if="canOnboardEntity" disabled type="button" class="btn btn-secondary" style="background: lightgray; color:gray; " @click="goToOnboarding">
          <Icon name="add-1" />          
          <span>Onboard New Business</span>
        </button>
      </div>
      <div>
        <label class="sr-only" for="businessSearch">Composite Field (Icon)</label>
        <label class="composite-field no-dividers darker" style="--this-border-radius: var(--br-full);">
          <div class="text">
            <Icon name="magnifying-glass" custom-class="icon icon-size-1 mar-is-4 shift-be-2" style="margin-inline-end: calc(var(--sp-1) * -1);" />
          </div>
          
          <input id="businessSearch" v-model="searchQuery" class="darker"  type="text" name="input" placeholder="Search Contractors...">
        </label>
      </div>
    </div>

    <!-- heading & filter -->
    <div class="grid gap-5 mar-bs-7" style="--grid-template-columns-sm: auto 1fr; align-items: start;">
      <div>
        <h1 class="h1">{{entity}} ({{ entities.length }})</h1>
      </div>
      <div>
        <div class="flex gap-5" style="justify-content: flex-end;">
          <!-- only super admins see this -->        
          <div v-if="userIsSysAdmin">
            <label class="sr-only" for="filter-supplier">Type</label>
            <span class="select">              
              <select v-model="filterType" class="darker size-small" name="select" id="filter-supplier">
                <option :value="null">All Businesses</option>
                <option :value="1">Suppliers</option>
                <option :value="2">Contractors</option>
              </select>
            </span>
          </div>          
        </div>
      </div>
    </div>

   <!-- table -->    
    <div class="table-wrap mar-bs-5">
    
      <table class="table-1 lines-b last-column-align-end" style="--cell-pad-b: var(--sp-3); --cell-pad-is: var(--sp-5);">
        <colgroup>
            <col style="width:auto;">
            <col style="width:auto;">
          </colgroup>                            
        <thead>
          <tr>
            <th @click="updateSort('name')">Name</th>
            <th @click="updateSort('name')">Actions</th>
            <!-- <th>Actions</th> -->
          </tr>
        </thead>
        <tbody v-if="loading">
            <tr>
              <td colspan="10" class="spinner"><ProgressSpinner /></td>
            </tr>
          </tbody>
        <tbody>
        <tr v-for="bus in filteredAndSortedEntities" :key="bus.id" @click="goToEntityDashboard(bus.id)" class="entity-row">
          <td><strong class="strong" >{{ bus.businessName }}</strong></td>
          <td><button class="text-link color-vibrant" @click="editSettings(bus)">Edit Settings</button></td>
          <!-- <td class="actions">
            <button @click.stop="editEntity(entity)">Edit</button>
            <button @click.stop="deleteEntity(entity)">Delete</button>
          </td> -->
        </tr>
      </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import {CORE} from '../constants.js';
import ContextLogic from '@/lib/contextLogic';
import ProgressSpinner from '@/components/ProgressSpinner.vue';
export default {
  data() {
    return {
      searchQuery: '',
      sortBy: 'name',
      sortOrder: 'asc',
      entities: [],
      filterType: null,
      loading:true
    }
  },
  components: {
    ProgressSpinner
  },
  computed: {
    ...mapState('contextModule', ['current']),
    ...mapGetters('contextModule', ['userIsSysAdmin']),
    filteredAndSortedEntities() {
      let filtered = this.entities.filter(entity => {
        //console.log(Object.values(entity));
        
        if(this.filterType != null) 
        {          
          if(this.filterType == 1 && entity.type != 1 )          
            return false;

          if(this.filterType == 2 && entity.type !=2)
            return false;
        }

        return Object.values(entity).some(value => 
          value.toString().toLowerCase().includes(this.searchQuery.toLowerCase())
        )
      })

      return filtered.sort((a, b) => {
        let modifier = this.sortOrder === 'asc' ? 1 : -1
        if (a[this.sortBy] < b[this.sortBy]) return -1 * modifier
        if (a[this.sortBy] > b[this.sortBy]) return 1 * modifier
        return 0
      })
    },
    entity(){
      if(this.current.mode == 1)
        return "Contractors"
      return "Businesses"
    },
    canOnboardEntity() {
      return true
    }
  },
  methods: {
    ...mapActions('businessModule', ['fetchAllBusinesses', 'fetchSubBusinesses']),
    ...mapActions('contextModule', ['']),
    editSettings(bus){
      let id = bus.id;
      if(bus.type == 1)
      {
        this.$router.push({path: `/supplier/${id}/settings`});
      }
        //ContextLogic.setCurrentSupplier(id);
      else
      {
        this.$router.push({path: `/contractor/${id}/settings`});
        //ContextLogic.setCurrentContractorAndParentSupplier()
        //ContextLogic.setCurrentContractor(id);
      }
      //this.$router.push(`/supplier/${id}/settings`);
    },
    updateSort(key) {
      if (this.sortBy === key) {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc'
      } else {
        this.sortBy = key
        this.sortOrder = 'asc'
      }
    },
    goToOnboarding() {
      // this.$router.push({ name: 'onboarding' })
    },
    goToEntityDashboard(businessId) {
      let entity = this.entities.find(e => e.id === businessId);
      // this is experimental for helping properly track current mode when not all route paths contain /supplier or /contractor      
      //this.$store.commit('contextModule/setMode', entity.type);
      let dashboardAlias;
      if(entity.type === CORE.entities.supplier){
        dashboardAlias = `/supplier/${businessId}/projects`;
      }
      else if(entity.type === CORE.entities.contractor){
        dashboardAlias = `/contractor/${businessId}/projects`;
      }
      if(dashboardAlias)
        this.$router.push(dashboardAlias)
    },    
    async deleteEntity(entity) {
      if (confirm(`Are you sure you want to delete ${entity.name}?`)) {
        try {
          await this.removeEntity(entity.id)
          // Optionally, show a success message
        } catch (error) {
          console.error('Error deleting entity:', error)
          // Optionally, show an error message
        }
      }
    }
  },
  async created() {
    ContextLogic.clearBusinessContext(this.$store);
    await ContextLogic.initContext(this);

    // we will fetch the appropriate entities for the current user
    let pathParts = this.$route.path.split("/")
    
    this.entities = (this.current.mode === CORE.modes.system) ? await this.fetchAllBusinesses() : await this.fetchSubBusinesses(pathParts[2]);
    this.loading = false;
  },
}
</script>

<style lang="scss" scoped>

//  this is a temporary hack against nitty-gritty to disable the on-boarding button since it will exist but doesn't yet
button[disabled]{
text-align: center;
    line-height: var(--btn-line-height);
    padding-inline: var(--btn-medium-padding-inline);
    padding-block: var(--btn-medium-padding-block);
    font-family: var(--font-family-btn);
    font-style: var(--btn-font-style);
    text-transform: var(--btn-text-transform);
    border-style: solid;
    border-width: var(--btn-medium-border-width);
    border-radius: var(--btn-medium-border-radius);
    min-height: var(--btn-medium-min-height);
    font-weight: var(--btn-medium-font-weight);
    font-size: var(--btn-medium-font-size);
    justify-content: center;
    align-items: center;
    column-gap: var(--btn-medium-column-gap);
    border-color: gray;
    background-color: lightgray;
    color: gray;
    cursor:default;
}

button[disabled] svg{
  stroke: gray;
}
</style>