<template>
  <div id="app">
    <div class="page-layout-1">
      
      <header class="header-bar color-context-neutral-dark">
        <button @click="clearBusinessContext">
          <img class="header-bar-logo" src="/snui/snui-images/logos/steel-ninja-logo-white.svg" alt="Steel Ninja">
        </button>
        <button style="--display-md: none;" data-open-modal="#mobile-menu">
          <span class="sr-only">Menu</span>
          <Icon name="equal-sign" custom-class="icon-size-2 color-subdued block" />
        </button>
      </header>
      <!-- nitty-gritty-override page-grid is not compatible with the designer YET -->
      <div :class="{'page-grid': pageNeedsGrid }" style="--grid-template-columns-md: auto 1fr;">
        <Sidebar2 v-if="user && showSidebar" :submenuRoutes="[
              '/supplier/:id/itemManager',
              '/supplier/:id/settings',
              '/supplier/:id/pricing',
              '/supplier/:id/taxes',
              '/supplier/:id/colors',
              '/contractor/:id/settings',
              '/itemEditor',
              '/employeeManager'
            ]">
          <!-- SysAdmin Links -->
          <template v-if="displayAsAdmin">
            <Sidebar2Link :routePath="`/`" icon="open-folder" :activeRoutes="[
                '/',
                '/contractor/:id/projects',
                '/supplier/:id/projects',
                '/project/:ref'
              ]"> Projects </Sidebar2Link>
            <Sidebar2Link :routePath="`/businesses/list`" icon="interface-home-2" :activeRoutes="[
                '/businesses/:id',
                '/onboarding',
                '/contractor/:id/settings',
                '/supplier/:id/itemManager',
                '/supplier/:id/settings',
                '/supplier/:id/pricing',
                '/supplier/:id/taxes',
                '/supplier/:id/colors',
              ]"> Businesses </Sidebar2Link>
<!--               
              <Sidebar2Link v-if="this.current.supplier && !this.current.contractor" :routePath="`/supplier/${current.supplier.id}/settings`" icon="screwdriver-wrench" :activeRoutes="[
              '/supplier/:id/itemManager',
              '/supplier/:id/settings',
              '/supplier/:id/pricing',
              '/supplier/:id/taxes',
              '/supplier/:id/colors',
              '/itemEditor',
              '/employeeManager'
            ]">Supplier Setup</Sidebar2Link>

          <Sidebar2Link v-if="this.current.contractor" :routePath="`/contractor/${current.contractor.id}/settings`" icon="screwdriver-wrench" :activeRoutes="[
              '/contractor/:id/itemManager',
              '/contractor/:id/settings',
              '/contractor/:id/pricing',
              '/contractor/:id/taxes',
              '/contractor/:id/colors',
              '/itemEditor',
              '/employeeManager'
            ]">Contractor Setup</Sidebar2Link> -->

          </template>
          <!-- Supplier Links -->
          <template v-if="displayAsSupplier">
            <Sidebar2Link :routePath="`/supplier/${current.supplier.id}/projects`" icon="open-folder" :activeRoutes="[
                '/',
                '/supplier/:id/projects',
                '/project/:ref'
              ]"> Projects </Sidebar2Link>
            <Sidebar2Link :routePath="`/supplier/${current.supplier.id}/contractors`" icon="construction-helmet-2"
              :activeRoutes="[
                '/supplier/:id/contractors',
                '/contractor/:id/settings'
              ]"> Contractors </Sidebar2Link>

            <Sidebar2Link :routePath="`/supplier/${current.supplier.id}/settings`" icon="screwdriver-wrench" :activeRoutes="[
              '/supplier/:id/itemManager',
              '/supplier/:id/settings',
              '/supplier/:id/pricing',
              '/supplier/:id/taxes',
              '/supplier/:id/colors',
              '/itemEditor',
              '/employeeManager'
            ]">Setup</Sidebar2Link>
            <!-- <Sidebar2Link v-if="userIsSysAdmin && current.supplier" :routePath="`/businesses/list`">{{ current.supplier.businessName }}</Sidebar2Link> -->
            <!-- <Sidebar2Link :routePath="`/supplier/${current.supplier.id}/colors`">Colors</Sidebar2Link>
            <Sidebar2Link :routePath="`/supplier/${current.supplier.id}/itemManager`">Items</Sidebar2Link>
            <Sidebar2Link :routePath="`/supplier/${current.supplier.id}/pricing`" :query="{section:'pricingModel'}">Pricing Model</Sidebar2Link>
            <Sidebar2Link :routePath="`/supplier/${current.supplier.id}/pricing`" :query="{section:'pricingLevels'}">Pricing Levels</Sidebar2Link>
            <Sidebar2Link :routePath="`/supplier/${current.supplier.id}/pricing`" :query="{section:'taxOptions'}">Tax Options</Sidebar2Link>
            <Sidebar2Link :routePath="`/supplier/${current.supplier.id}/pricing`" :query="{section:'logistics'}">Logistics</Sidebar2Link>
            <Sidebar2Link :routePath="`/supplier/${current.supplier.id}/pricing`" :query="{section:'fabrication'}">Fabrication</Sidebar2Link>
            <Sidebar2Link :routePath="`/supplier/${current.supplier.id}/pricing`" :query="{section:'beams'}">Beams</Sidebar2Link>
            <Sidebar2Link :routePath="`/supplier/${current.supplier.id}/pricing`" :query="{section:'fasteners'}">Fasteners</Sidebar2Link>
            
            <Sidebar2Link :routePath="`/supplier/${current.supplier.id}/users`">Users</Sidebar2Link>
            <Sidebar2Link :routePath="`/supplier/${current.supplier.id}/settings`">Business Settings</Sidebar2Link>
            <Sidebar2Link :routePath="`/supplier/${current.supplier.id}/subscription`">Subscription</Sidebar2Link> -->
          </template>
          
          <!-- Contractor Links -->
          <template v-if="displayAsContractor">
            <Sidebar2Link :routePath="`/contractor/${current.contractor.id}/projects`" icon="open-folder" :activeRoutes="[
                '/',
                '/contractor/:id/projects',
                '/project/:ref'
              ]"> Projects </Sidebar2Link>
            <Sidebar2Link :routePath="`/contractor/${current.contractor.id}/settings`" icon="screwdriver-wrench" :activeRoutes="[
              '/contractor/:id/settings',
              '/employeeManager'
            ]">Setup</Sidebar2Link>
            <!-- <Sidebar2Link :routePath="`/contractor/${current.contractor.id}/settings`" icon="screwdriver-wrench">Settings</Sidebar2Link> -->
            <!-- <Sidebar2Link v-if="userBusiness && userBusiness.type < 2 && current.contractor" :routePath="`/businesses/list`">{{ current.contractor.businessName }}</Sidebar2Link> -->
            <!-- <Sidebar2Link :routePath="`/contractor/${current.contractor.id}/users`">Users</Sidebar2Link> -->
          </template>
          
          
          <!-- <Sidebar2Link @click="logout">Logout</Sidebar2Link> -->
          <!-- <Sidebar2Link :routePath="`/html-test`">HTML Test</Sidebar2Link> -->
          <template v-slot:main-menu-bottom>
            <div class="popover1-anchor">
              <div class="grid gap-3" style="grid-template-columns: 1fr auto; align-items: center;">
                <div>
                  <div class="grid gap-3" style="grid-template-columns: auto 1fr; align-items: center;">
                    <div>
                      <Icon name="attribution" custom-class="icon-size-2 block" />
                    </div>
                    <div style="line-height: 1;">
                      <div class="strong">{{ user.name }}</div>
                      <div class="small one-line-truncate">{{ userIsSysAdmin ? 'Admin Ninja' : (currentBusiness ? currentBusiness.businessName : '') }}</div>
                    </div>
                  </div>
                </div>
                <SnuiWrapper>
                  <div>
                    <button class="pad-3 hover-bg-shaded mar-is-2" style="margin: calc(var(--sp-3) * -1); border-radius: var(--br-2);"  data-popover1-trigger="toggle" aria-controls="popover-user-menu" aria-expanded="false">
                      <span class="sr-only">Open User Menu</span>
                      <Icon name="horizontal-menu-square" custom-class="icon-size-1 block" />
                    </button>
                    <!-- user menu -->
                    <div id="popover-user-menu" class="popover1 attach-outside-bs align-ie click-outside-close"
                      data-popover1-state="closed" role="dialog" aria-labelledby="popover-user-menu-title">
                      <div class="mar-be-3 color-context-neutral-dark"
                        style="inline-size: calc(var(--sidebar-inline-size) - (var(--sp-4) * 2)); border-radius: var(--br-3); border-color: var(--color-fg-normal); overflow: hidden; margin-inline-end: calc(var(--sp-4) * -1);">
                        <h2 class="sr-only" id="popover-user-menu-title">User Menu</h2>
                        <div class="grid mar-b-2">                          
                          <button class="block pad-b-2 pad-i-4 hover-bg-shaded" data-popover1-trigger="close"
                            aria-controls="popover-user-menu" aria-expanded="false" @click="$router.push('/profile')">Profile</button>
                          <button class="block pad-b-2 pad-i-4 hover-bg-shaded" data-popover1-trigger="close"
                            aria-controls="popover-user-menu" aria-expanded="false" @click="logout">Logout</button>
                        </div>
                      </div>
                    </div>
                  </div><!-- div -->
                </SnuiWrapper>
              </div>
            </div><!-- popover1-anchor -->
          </template>
              
          <template v-slot:submenu>


            <h3 v-if="showSubMenuBusinessName" class="submenu-title">{{ currentBusiness?.businessName }}</h3>
            

            <!-- Supplier settings -->
            <template v-if="current.mode == 1 && current.supplier">
              <div class="submenu-heading">Pricing Settings</div>
              <div class="submenu-links">
                
                <SubmenuLink text="Items" :routePath="`/supplier/${current.supplier.id}/itemManager`" />
                <SubmenuLink text="Pricing Models" :routePath="`/supplier/${current.supplier.id}/pricing`"
                  :query="{ section: 'pricingModel' }" />
                <SubmenuLink text="Pricing Levels" :routePath="`/supplier/${current.supplier.id}/pricing`"
                  :query="{ section: 'pricingLevels' }" />
                <SubmenuLink text="Sales Tax" :routePath="`/supplier/${current.supplier.id}/taxes`"/>
                <SubmenuLink text="Logistics" :routePath="`/supplier/${current.supplier.id}/pricing`"
                  :query="{ section: 'logistics' }" />
                <SubmenuLink text="Fabrication" :routePath="`/supplier/${current.supplier.id}/pricing`"
                  :query="{ section: 'fabrication' }" />
                <SubmenuLink text="Beams" :routePath="`/supplier/${current.supplier.id}/pricing`"
                  :query="{ section: 'beams' }" />
                <SubmenuLink text="Fasteners" :routePath="`/supplier/${current.supplier.id}/pricing`"
                  :query="{ section: 'fasteners' }" />
              </div><!-- submenu-links -->
              <!-- etc... -->
            </template>
            <!-- Settings for all modes -->
            <div class="submenu-heading">Business Settings</div>
            <div class="submenu-links">
              
              <template v-if="current.mode == 1 && current.supplier">
                <SubmenuLink text="Colors" :routePath="`/supplier/${current.supplier.id}/colors`" />
                <SubmenuLink text="Business Info." :routePath="`/supplier/${current.supplier.id}/settings`" />
                <!-- <SubmenuLink
                  text="Subscription"
                  :routePath="`/supplier/${current.supplier.id}/subscription`"
                  />
                  <SubmenuLink
                    text="Users"
                    :routePath="`/supplier/${current.supplier.id}/users`"
                  /> -->
              </template>
              <template v-if="mode == 2 && current.contractor">
                <SubmenuLink text="Business Info." :routePath="`/contractor/${current.contractor.id}/settings`" />
              </template>
            </div>
            <!-- <div v-if="current.mode == 1">
              <div v-if="userIsSupAdminOrSysAdmin">Color</div>
              <div>Business Settings</div>  
            </div>

            <div v-if="current.mode == 2">
              <div v-if="userIsSupAdminOrContAdmin">Some Contractor Admin setting</div>
              <div>Business Settings</div>  
            </div> -->
          </template>
        </Sidebar2>
        <div class="main-content"  :class="{designer: isDesigner}" style="overflow-y: auto; height: 94vh;">
          
            <div v-if="showBackToBusinesses" class="grid gap-5" style="grid-template-columns: auto 1fr;" >
            <div>
              <button type="button" class="btn btn-secondary" @click="backToBusinesses">
                <Icon name="line-arrow-left" ></Icon>                
                <span>Back to {{ businessTermPlural }}</span>
              </button>
            </div>
            <div></div>
          </div>

          <router-view   :key="refreshKey+$route.fullPath" />
        </div>
      </div><!-- page-grid -->
    </div><!-- page-layout-1 -->
  </div>
</template>

<script>
import ContextLogic from './lib/contextLogic';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
//import SidebarLayout from './components/SidebarLayout.vue'
import SidebarLayout from './components/SidebarLayout.vue'
import {CORE} from './constants.js'
import Sidebar2 from './components/Sidebar2.vue';
import Sidebar2Link from './components/Sidebar2Link.vue';
import Sidebar2Section from './components/Sidebar2Section.vue';
import ProjectView from './views/ProjectView.vue';
import ProjectManagerView from './views/ProjectManagerView.vue';
import SubmenuLink from './components/SubmenuLink.vue';
import Icon from './components/Icon.vue';

export default{
  name: 'App',
  data(){
    return{
      routePath: this.$route?.path || window.location.pathname,
      logo:''    
    }
  },
  components:{
    SidebarLayout,
    Sidebar2,
        Sidebar2Link,
        Sidebar2Section,
        SubmenuLink
  },
  watch:{
    async currentBusiness(v){

      console.log('current entity is now', v )
      if(!v)
        return;
        this.logo = await this.getCompanyImage(v.id);  
    },
    $route(to, from) {
      // React to route changes...
      console.log('Route changed from', from.path, 'to', to.path)
      // Perform any necessary actions here
      this.routePath = this.$route.path; // update this to force showSidebar to recalculate
    }
  },
  computed:{      
    ...mapState('contextModule', ['current','user', 'userBusiness', 'refreshKey' ]),
    ...mapGetters('contextModule', ['currentBusiness', 'userIsSysAdmin', 'userIsSupAdminOrSysAdmin', 'userIsContAdminOrSysAdmin']),
    showSubMenuBusinessName(){
      if(this.userIsSysAdmin)
        return true;
      if(this.supplierViewingContractor)
        return true;
      return false;

    },
    supplierViewingContractor(){
      // is a supplier looking at a contractor
      if(!this.userBusiness)
        return false;
      if(this.userBusiness.type != 1)
        return false;
      if(this.userBusiness.type == 1 && this.mode == 2)
        return true;
      else
        return false;
      
    },
    supplierViewingSelf(){
      // is a supplier looking at a supplier (self)
      if(!this.userBusiness)
        return false;
      if(this.userBusiness.type != 1)
        return false;
      if(this.mode == 1)
        return true;
      return false;
    },
    contractorViewingSelf(){
      // is a contractor looking at a contractor (Self)
      if(!this.userBusiness)
        return false;
      if(this.userBusiness.type != 2)
        return false;
      if(this.mode == 2)
        return true;
      return false;
    },
    businessTermPlural(){
      if (this.userIsSysAdmin)
        return 'Businesses'
      return 'Contractors'
    },    
    displayAsAdmin(){
      return this.userIsSysAdmin;
    },
    displayAsSupplier(){
      return this.userBusiness?.type==1
    },
    displayAsContractor(){
      return this.userBusiness?.type==2
    },
    pageNeedsGrid(){
      return !this.isDesigner && !this.$route.path.includes('login')
    },
    isDesigner(){
      return this.$route.path.includes('design')
    },
    
    mode(){
      return this.current.mode
    },
    
    showSidebar(){
      console.log('showSidebar called', {
        route: this.$route?.path,
        routePath: this.routePath,
        auth: !!this.$auth,
        isAuthenticated: this.$auth?.isAuthenticated
      });
      
      if(!this.$auth || !this.$auth.isAuthenticated) {
        console.log('showSidebar: auth check failed');
        return false;
      }
      
      // Initialize routePath if it's not set
      if (!this.routePath) {
        this.routePath = this.$route.path;
      }
      
      let pathsWithoutSidebar = ['design', 'login'];
      let result = !pathsWithoutSidebar.some(path => this.routePath.includes(path));
      console.log('showSidebar returning:', result);
      return result;
    },
    
    toggleLabel(){
      return this.collapsed?"]>":"<]";
    },

    showBackToBusinesses(){
      if(this.supplierViewingSelf)
        return false;
      if(this.contractorViewingSelf)
        return false;
      
      if(this.supplierViewingContractor || this.userIsSysAdmin){
          let showForRouteNames = [
            'itemManager',
            'settings',
            'pricing',
            'taxes',
            'itemEditor',
            'colors',
          ]
        return showForRouteNames.some( x => x == this.$route?.name);
      }
      return false;
    },
    
    sdkClientLoaded(){
      //Check that the SDK client is not currently loading before accessing is methods
      // console.log('isLoading',this.$auth.loading)
      // console.log('isAuth', this.$auth.isAuthenticated)
      return !this.$auth.loading;
    }
  },
  methods: {
    ...mapActions('businessModule', ['getBusinessSetting']),    
    backToBusinesses(){
        // this needs to become fully centralized if every settings page is going to need it
        ContextLogic.clearBusinessContext(this.$store);

        if(this.userBusiness.type==1)
            this.$router.push({path: `/supplier/${this.userBusiness.id}/contractors`})
        else
            this.$router.push({path: `/businesses/list`})
    },
    clearBusinessContext(){
      ContextLogic.clearBusinessContext(this.$store);
      this.$router.push('/');

    },
    logout() {
      console.log('app: logout')
      console.log('logging out, redirecting to: ', `${process.env.VUE_APP_SITE_BASE_URL}/login`)
      
      ContextLogic.logout();

      this.$auth.logout({
        logoutParams: {
          returnTo: `${process.env.VUE_APP_SITE_BASE_URL}/login`
        }
      });
    },
    
    async getCompanyImageSetting(businessId, settingName){
      return await this.getBusinessSetting({businessId: businessId, settingName});
    }, 
    async getCompanyImage(businessId){
      
      let response = await this.getCompanyImageSetting(businessId, 'logo_Sml');
      if(!response.isError)
      {
          if (!response.value) 
          {
            response = await this.getCompanyImageSetting(businessId, 'logo_Lrg');
            if(!response.isError)
            {
                if (!response.value) 
                  return ''
                else
                  return response.value;
            }
          }          
          else
            return response.value;
      }
      else
        return '';      
    }, 
      projectManagerRoutePath() {
      if (this.$route.name == 'dashboard') {
        return '/projects'
      }
      else
        return `${this.$route.path}/projects`
    },
    entityManagerRoutePath() {
      if (this.$route.name == 'dashboard') {
        return '/entities/list'
      }
      else {
        return `${this.$route.path}/contractors`
      }
    },

    toggleSidebar(){
      this.collapsed=!this.collapsed;
      localStorage.setItem('sidebar-collapsed', JSON.stringify(this.collapsed))
    },
    
    ...mapMutations('contextModule', ['setMode']),
    
    
    //async setCurrentContextForUser(){
      // let currentUser = this.fetch(this.$auth.user.sub);
      // sessionStorage.setItem('user', currentUser);
      
      // let entity = this.fetchBusiness(currentUser.entityId);
      // ContextLogic.initContext(this);
      // if(this.current.mode == CORE.entities.enterprise)
      //   this.dashboardPath = '/projects';
      // else if(this.current.mode == CORE.entities.supplier)
      //   this.dashboardPath = `/company/${JSON.parse(sessionStorage.getItem('user')).entityId}/projects`;
      // else
      //   this.dashboardPath = `/contractor/${JSON.parse(sessionStorage.getItem('user')).entityId}/projects`;

      //}
  },
  async created() {
    console.log('App created, route:', this.$route.path);
    console.log('app created')
    
  },
   async mounted(){
    console.log('App mounted', {
      route: this.$route?.path,
      auth: !!this.$auth,
      isAuthenticated: this.$auth?.isAuthenticated
    });
    
    // If auth isn't ready yet, wait for it
    if (this.$auth?.loading) {
      await new Promise(resolve => {
        const checkAuth = setInterval(() => {
          if (!this.$auth.loading) {
            clearInterval(checkAuth);
            resolve();
          }
        }, 100);
      });
    }
    
    this.routePath = this.$route.path;
  }
}
 
</script>

<style lang="scss">
  .page-layout-1 {
    height: 50%;
  }
  // #app {
  //   font-family: Avenir, Helvetica, Arial, sans-serif;
  //   -webkit-font-smoothing: antialiased;
  //   -moz-osx-font-smoothing: grayscale;
  //   text-align: center;
  //   color: #2c3e50;
  //   background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  //   height: 100vh;
  // }

  // nav {
  //   a {
  //     font-weight: bold;
  //     color: #2c3e50;

  //     &.router-link-exact-active {
  //       color: #42b983;
  //     }
  //   }

  // }
  // body, html {
  //   margin: 0;
  //   padding: 0;
  //   height: 100%;
  //   font-family: Arial, sans-serif;
  // }
  // #main {
  // display:inline-block;
  //   flex-grow: 1;
  //   padding: 20px;
  // }
  // #sidebar.collapsed {
  //   width: 50px;
  // }
  // .stickyBottom {
  //   position: fixed;
  //   left: 10px;
  //   bottom: 10px;
  //   z-index: 1;
  // }

  // .container {
  //   display: flex;
  //   min-height: 100vh;
  // }

  // .sidebar {
  //   width: 250px;
  //   background-color: #f0f0f0;
  //   padding: 20px;
  // }


  // @media (max-width: 768px) {
  //   .container {
  //       flex-direction: column;
  //   }
  //   .sidebar {
  //       width: 100%;
  //   }
  // }

  body {
    min-height:95%;
    overflow:hidden;
  }

.ellipsis{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.designer{
  padding-block: 0;
  padding-inline: 0;
}

</style>

