<template><div>
    <v-dialog transition="pop-out" />
    <modal name="project-tax-option-modal" :height="400" :adaptive="true">
        <div class="content">
            <h3 class="modal-header">Reassign Project Tax Option</h3>
            <div class="modal-body">
                <!-- <div v-if="changedTaxRateProjectPairs.length > 0">
                    
                    
                    <ul>
                        <li v-for="pair in changedTaxRateProjectPairs">{{ pair.option.name }}</li>
                    </ul>
                </div> -->
                <div v-if="disabledDeletedTaxOptProjectPairs.length > 0">
                    <span>Please assign a new tax option to the project(s), then try
                            disabling or deleting the tax option(s) again.
                        </span>

                        <br/>
                    <div v-for="pair in disabledDeletedTaxOptProjectPairs">
                        <p>"{{ pair.option.name }}" is currently being used by {{ pair.projects.length }} project(s). Reassign project(s) tax option from <b>{{ pair.option.name }} - {{ pair.option.rate }}%</b> to

                            <select id="taxOptions" :value="pair.option.id" @change="updateProjectsTaxOption(pair.option.id, $event.target.value)">
                            <option v-for="opt in enabledTaxOptions" :key="opt.value" :value="opt.value">
                                {{ opt.name }} - {{ opt.rate }}%
                            </option>
                        </select>
                    </p>
                        
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <span>
                    <button v-on:click="closeModal" type="button" class="btn btn-primary">Cancel</button>
                    <button style="background-color: green;" :disabled="taxRateUpdate.inProgress" type="button" v-on:click="saveChanges" class="btn btn-primary">Update and Flag Projects</button><StatusIndicator ref="si_updateTaxOptsForProjects" :successText="'Saved'"></StatusIndicator>
                </span>
            </div>
        </div>
    </modal>
</div></template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import VModal from 'vue-js-modal'
import Vue from 'vue'
import Vuex from 'vuex'
import StatusIndicator from '@/components/StatusIndicator.vue';
import UserSettingDropdown from './user-setting-dropdown.vue';
import api from '@/api';
Vue.use(Vuex)
Vue.use(VModal, {dialog:true});
export default{
    components:{
        StatusIndicator,
        UserSettingDropdown
    },
    props: {
        disabledDeletedTaxOptProjectPairs: {
            type: Array,
        },
        // changedTaxRateProjectPairs:{
        //     type: Array
        // },
        enabledTaxOptions:{
            type: Array
        },
        showProjectTaxOptModal: {
            type: Boolean
        }
    },
    data(){
        return{
            taxRateUpdate:{
                showResultMessage: false,
                inProgress: false,
                successful: true,
            },
            input: {
                error:{
                    show: false,
                    msg: ""
                }
            },
        }
    },
    computed:{
        ...mapState('contextModule', ['contractorParentData']),
    },
    methods: {
        ...mapActions('projectModule', ['fetchProject', 'copyProject']),
        ...mapActions('businessModule', ['fetchBusiness', 'fetchAllSuppliers', 'fetchUser', 'fetchContractorsByRep']),
        ...mapActions('userModule', ['fetchUser']),
        closeModal(){
            console.log('hit close customer modal')
            this.showProjectTaxOptModal = false;
            this.$modal.hide('project-tax-option-modal');
        },
        updateProjectsTaxOption(originalOptId, newOptId){
            let projectOptPair = this.disabledDeletedTaxOptProjectPairs.find(pair => pair.option.id == originalOptId);
            projectOptPair.projects.forEach(project => {
                project.taxOptionId = Number(newOptId);
                project.outdatedPricing = true;
            });

            // this.changedTaxRateProjectPairs.forEach(project =>{
            //     project.outdatedPricing = true;
            // })
        },
        async saveChanges(){
            let projectsToUpdate = [];
            this.disabledDeletedTaxOptProjectPairs.forEach((pair)=>{
                let projects = pair.projects;
                projectsToUpdate.push(...projects);
            })
            // this.changedTaxRateProjectPairs.forEach(project =>{
            //     projectsToUpdate.push(project);
            // })
            
            let response = await api.handleOutdatedPricing(projectsToUpdate);
            
            if(response.isError){
                this.$toast.fail("Could not update tax rates for projects.")
            }
            else{
                this.$modal.hide('project-tax-option-modal');
                this.$toast.success("Projects tax options updated successfully!")
            }

        }
    },
    watch:{
        'showProjectTaxOptModal'(newVal) {
            console.log('showProjectTaxOptModal', newVal)
            if (newVal) {
                this.$modal.show('project-tax-option-modal');
            }
        },
    },
    async created(){
    }
}

</script>

<style lang="scss" scoped>

.modal-header{
    padding: 2%;
    border-bottom: 1px solid lightgrey;
    text-align: center;
}

.modal-body{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
}

.modal-footer{
    margin-top: 5%;
}

.btn{
    padding: 2%;
    margin: 2%;
}

</style>