<template>
    <div class='viewOpt' v-on:click="toggle">
        <font-awesome-icon v-if="visible" icon="fa-duotone fa-eye" style='padding:0 1px 0 1px;' />
        <font-awesome-icon v-if="!visible" icon="fa-duotone fa-eye-slash"      />
        <label style='display:inline;padding-left: 5px'>{{text}}</label>
    </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
export default {
  props: ['vis', 'text', 'layer'],
  data: function(){
      return {
      };
  },
  computed:{
...mapGetters('designModule',[
    'currentLayers'
]),
      visible(){
          return this.currentLayers[this.layer];
      }
  },
  methods: {
    ...mapMutations('designModule',[
        'toggleLayer'
    ]),
      toggle: function(){
          this.toggleLayer(this.layer);          
      }
  }
}
</script> 

<style scoped>
/* start nitty-gritty-override */
label {
  color: black;
}
/* end nitty-gritty-override */


</style>


