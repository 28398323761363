<template>
    <div>
        <template v-if="mode == 1 || mode == 2">
                <div class="settingsGroup">
                    <h1 class="h1">Business Info</h1>
                    <div class="settingsGroupBody">
                        <div class="setting">
                            <label for="legalName">Legal Name</label>
                            <input type="text" class="w-l" id="legalName" v-model="entity.legalName" required @input="dbEntityInfo('legalName')" :disabled="!canUpdateBusinessInfo"/>
                            <StatusIndicator ref="si_legalName" :successText="'Saved'"></StatusIndicator>
                        </div>
                    </div>
                    <div class="settingsGroupBody">
                        <div class="setting">
                            <label for="businessName">D.B.A Name</label>
                            <input type="text" class="w-l" v-model="entity.businessName" id="businessName" @input="dbEntityInfo('businessName')" :disabled="!canUpdateBusinessInfo"/>
                            <StatusIndicator ref="si_businessName" :successText="'Saved'"></StatusIndicator>
                        </div>
                    </div>
                    <div class="settingsGroupBody">
                        <div class="setting">
                            <label for="phoneNumber">Phone Number</label>
                            <input id="phoneNumber" type="number" v-model="entity.phoneNumber" @input="dbEntityInfo('phoneNumber')" :disabled="!canUpdateBusinessInfo"/>
                            <StatusIndicator ref="si_phoneNumber" :successText="'Saved'"></StatusIndicator>
                        </div>
                    </div>
                    <div class="settingsGroupBody">
                        <div class="setting">
                            <label for="url">Website</label>
                            <input id="url" class="w-m" type="text" v-model="entity.url" @input="dbEntityInfo('url')" :disabled="!canUpdateBusinessInfo"/>
                            <StatusIndicator ref="si_url" :successText="'Saved'"></StatusIndicator>
                        </div>
                    </div>

                    <div class="section-group-title"><b>Mailling Address</b></div>
                    <div class="settingsGroupBody">
                        <div class="setting">
                            <label for="mailingAddress1">Address 1</label>
                            <input id="mailingAddress1" type="text" class="w-m" v-model="entity.mailingAddress1" @input="dbEntityInfo('mailingAddress1')" :disabled="!canUpdateBusinessInfo"/>
                            <StatusIndicator ref="si_mailingAddress1" :successText="'Saved'"></StatusIndicator>
                        </div>
                    </div>
                    <div class="settingsGroupBody">
                        <div class="setting">
                            <label for="mailingAddress2">Address 2</label>
                            <input id="mailingAddress2" type="text" class="w-m" v-model="entity.mailingAddress2" @input="dbEntityInfo('mailingAddress2')" :disabled="!canUpdateBusinessInfo"/>
                            <StatusIndicator ref="si_mailingAddress2" :successText="'Saved'"></StatusIndicator>                            
                        </div>
                    </div>
                    <div class="settingsGroupBody">
                        <div class="setting">
                            <label for="city">City</label>
                            <input id="mailingAddressCity" type="text" class="w-m" v-model="entity.mailingAddressCity" @input="dbEntityInfo('mailingAddressCity')" :disabled="!canUpdateBusinessInfo"/>
                            <label for="state">State</label>
                            <input id="mailingAddressState" type="text" class="w-m" v-model="entity.mailingAddressState" @input="dbEntityInfo('mailingAddressState')" :disabled="!canUpdateBusinessInfo"/>
                            <label for="zipcode">Zipcode</label>
                            <input id="mailingAddressZip" type="text" class="w-s" v-model="entity.mailingAddressZip" @input="dbEntityInfo('mailingAddressZip')" :disabled="!canUpdateBusinessInfo"/>
                            
                        </div>
                        <StatusIndicator ref="si_cityStateZip" :successText="'Saved'"></StatusIndicator>                
                    </div>
                    <div class="section-group-title"><b>Branding</b></div>
                    <div class="settingsGroupBody">
                        <div class="setting" style="display:flex">
                            <div>
                                <label for="logoUpload">Banner Logo</label><br/>                           
                                <label style="font-size:15px" >A larger and wider image, usually with your DBA name</label><br/>
                                <p style="font-size:15px" >A 3-to-1 aspect ratio and 72ppi resolution is recommended</p>
                                <p style="font-size:15px" >Example: 300 px wide x 100 px tall</p>
                            </div>
                            <div class="logo-input">
                                <input  type="file" id="logoUpload" @change="handleLogoUpload($event, 'logo_Lrg')" :disabled="!canUpdateBusinessInfo"/>
                                <StatusIndicator ref="si_logoLrg" :successText="'Saved'"></StatusIndicator>    
                                <div class="logo-lrg">
                                    <img v-if="logo_Lrg" :src="logo_Lrg" alt="Logo" style="height:100px"/>
                                    <span v-else>None</span>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                    <div class="settingsGroupBody">
                        <div class="setting" style="display:flex">
                            <div>
                                <label for="logoUpload">Brand Symbol</label><br/>                           
                                <label style="font-size:16px" >A smaller and more square image that represents your brand.</label><br/>
                                <label style="font-size:15px" >An aspect ratio (width-to-height) of 1-to-1 and 72ppi resolution is recommended</label>
                            </div>

                            <div class="logo-input">
                                <input  type="file" id="logoUpload" @change="handleLogoUpload($event, 'logo_Sml')" :disabled="!canUpdateBusinessInfo"/>
                                <StatusIndicator ref="si_logoSml" :successText="'Saved'"></StatusIndicator>    
                                <div class="logo-sml">
                                    <img v-if="logo_Sml" :src="logo_Sml" alt="Logo" />
                                    <span v-else>None</span>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div> <!-- end settings group -->
        </template>
        
        <template v-if="mode == 1 || mode == 2">
            <div class="settingsGroup">
                <div class="settingsGroupHeader"><b>Project Settings</b></div>   
                <div class="settingsGroupBody">
                        <div class="setting">
                            <div class="" style="display: inline;">Roof Purlin Max Spacing<StatusIndicator ref="si_defaultMaxRoofPurlinSpacing"></StatusIndicator></div>
                            <div class="settingValue num"><input type="number" class="digit3" @input="dbEntitySettings({id:'qt_defaultMaxPurlinSpacing',val:qt_defaultMaxPurlinSpacing})" v-model="qt_defaultMaxPurlinSpacing" min="0" max="24" :disabled="true || !canUpdateSupplierSettings && !canUpdateContractorSettings"/></div>
                            <div class="settingDescription"> feet</div>       
                            Coming soon!                 
                        </div>
                    </div>
                    <div class="settingsGroupBody">
                        <div class="setting">
                            <label for="des_taxes">Roof Purlin Condition</label>
                            <user-setting-dropdown @change="setBusinessSetting" :customStyle="{display:'inline'}"
                                            ref="qt_defaultRoofPurlinCondition"
                                            id="qt_defaultRoofPurlinCondition"
                                            :val="qt_defaultRoofPurlinCondition"
                                            name=""
                                            :disable="!canUpdateSupplierSettings"
                                            :options="[
                                            {name:'Flush', value:'Flush'},
                                            {name:'Bypass', value:'Bypass'},
                                            ]">                                        
                            </user-setting-dropdown>
                            <StatusIndicator ref="si_defaultRoofPurlinCondition" :successText="'Saved'"></StatusIndicator>    
                        </div>
                    </div>
                    <div class="settingsGroupBody" v-if="showSupplierOnlySetting">
                        <div class="setting">
                            <div class="" style="display: inline;">Peak Purlin Space<StatusIndicator ref="si_peakSpaceSelection"></StatusIndicator></div>
                            <div class="settingValue num"><input type="number" class="digit3" @input="dbEntitySettings({id:'qt_peakSpaceSelection',val:qt_peakSpaceSelection})" v-model="qt_peakSpaceSelection" min="0" max="24" :disabled="true || !canUpdateSupplierSettings"/></div>
                            <div class="settingDescription"> feet</div>    
                            Coming soon!                    
                        </div>
                    </div>
                    <div class="settingsGroupBody" v-if="showSupplierOnlySetting">
                        <div class="setting">
                            <div class="" style="display: inline;">Taper Frame Width<StatusIndicator ref="si_startTaperFrameWidth"></StatusIndicator></div>
                            <div class="settingValue num"><input type="number" class="digit3" @input="dbEntitySettings({id:'qt_startTaperFrameWidth',val:qt_startTaperFrameWidth})" v-model="qt_startTaperFrameWidth" min="0" max="24" :disabled="!canUpdateSupplierSettings"/></div>
                            <div class="settingDescription"> feet</div>    
                            Coming soon!                    
                        </div>
                    </div>
                    <div class="settingsGroupBody">
                        <div class="setting">
                            <div class="" style="display: inline;">Wall Girt Max Spacing<StatusIndicator ref="si_defaultMaxGirtSpaceFt"></StatusIndicator></div>
                            <div class="settingValue num"><input type="number" class="digit3" @input="dbEntitySettings({id:'qt_defaultMaxGirtSpaceFt',val:qt_defaultMaxGirtSpaceFt})" v-model="qt_defaultMaxGirtSpaceFt" min="0" max="24" :disabled="true || !canUpdateSupplierSettings && !canUpdateContractorSettings"/></div>
                            <div class="settingDescription"> feet</div>    
                            Coming soon!                    
                        </div>
                    </div>
                    <div class="settingsGroupBody">
                        <div class="setting">
                            <label for="des_taxes">Wall Girt Condition</label>
                            <user-setting-dropdown @change="setBusinessSetting" :customStyle="{display:'inline'}"
                                            ref="qt_defaultWallGirtCondition"
                                            id="qt_defaultWallGirtCondition"
                                            :val="qt_defaultWallGirtCondition"
                                            name=""
                                            :disable="true || !canUpdateSupplierSettings && !canUpdateContractorSettings"
                                            :options="[
                                            {name:'Flush', value:'Flush'},
                                            {name:'Bypass', value:'Bypass'},
                                            ]">                                        
                            </user-setting-dropdown>
                            Coming soon!
                            <StatusIndicator ref="si_defaultWallGirtCondition" :successText="'Saved'"></StatusIndicator>    
                        </div>
                    </div>
                <div class="settingsGroupBody">
                    <div class="setting">
                        <label for="des_taxes">Default Tax Setting</label>
                        <user-setting-dropdown @change="setBusinessSetting" :customStyle="{display:'inline'}"
                                        ref="des_taxes"
                                        id="des_taxes"
                                        :val="des_taxes"
                                        name=""
                                        :disable="!canUpdateSupplierSettings && !canUpdateContractorSettings"
                                        :options="[
                                        {name:'No - Exempt', value:'No - Exempt'},
                                        {name:'No - Out of State', value:'No - Out of State'},
                                        {name:'Yes - City', value:'Yes - City'},
                                        {name:'Yes - Outside City', value:'Yes - Outside City'},
                                        ]">                                        
                        </user-setting-dropdown>
                        <StatusIndicator ref="si_taxes" :successText="'Saved'"></StatusIndicator>    
                    </div>
                </div>
            
                <div class="settingsGroupBody">
                    <div class="setting">
                        <label for="des_gutter">Gutters</label>
                    <user-setting-checkbox @change="setBusinessSetting" :customStyle="{display:'inline'}"
                                            ref="des_gutter"
                                            id="des_gutter"
                                            :val="des_gutter"                                            
                                            :disable="!canUpdateSupplierSettings && !canUpdateContractorSettings"
                                            >
                    </user-setting-checkbox>
                    <StatusIndicator ref="si_gutter" :successText="'Saved'"></StatusIndicator>    
                    </div>
                </div>
                <div class="settingsGroupBody">
                    <div class="setting">
                        <label for="des_dripTrim">Drip Trim</label>
                        <user-setting-checkbox  @change="setBusinessSetting" :customStyle="{display:'inline'}"
                                                ref="des_dripTrim"
                                                id="des_dripTrim"
                                                :val="des_dripTrim"
                                                :disable="!canUpdateSupplierSettings && !canUpdateContractorSettings"
                                                description="">
                        </user-setting-checkbox>
                        <StatusIndicator ref="si_dripTrim" :successText="'Saved'"></StatusIndicator>    
                    </div>
                </div>
                <div class="settingsGroupBody">
                    <div class="setting">
                        <label for="des_tubePorchColTrim">Wrap Tube Porch Columns with Trim</label>
                        <user-setting-checkbox  @change="setBusinessSetting" :customStyle="{display:'inline'}"
                                                ref="des_tubePorchColTrim"
                                                id="des_tubePorchColTrim"
                                                :val="des_tubePorchColTrim"
                                                :disable="!canUpdateSupplierSettings && !canUpdateContractorSettings"
                                                description="">
                        </user-setting-checkbox>
                        <StatusIndicator ref="si_tubePorchColTrim" :successText="'Saved'"></StatusIndicator>    
                    </div>
                </div>
                <div class="settingsGroupBody">
                    <div class="setting">
                        <label for="des_deadboltPrep">Deadbolt Prep on Walk 3o7o and 4o7o Walks</label>
                        <user-setting-checkbox  @change="setBusinessSetting" :customStyle="{display:'inline'}"
                                                ref="des_deadboltPrep"
                                                id="des_deadboltPrep"
                                                :val="des_deadboltPrep"
                                                :disable="!canUpdateSupplierSettings && !canUpdateContractorSettings"
                                                description="">
                        </user-setting-checkbox>
                        <StatusIndicator ref="si_deadboltPrep" :successText="'Saved'"></StatusIndicator>    
                    </div>
                </div>
                <div class="settingsGroupBody">
                    <div class="setting">
                        <label for="des_jambCounterFlashing">Jamb Counter Flashing</label>
                        <user-setting-checkbox  @change="setBusinessSetting" :customStyle="{display:'inline'}"
                                                ref="des_jambCounterFlashing"
                                                id="des_jambCounterFlashing"
                                                :val="des_jambCounterFlashing"
                                                :disable="!canUpdateSupplierSettings && !canUpdateContractorSettings"
                                                description="">
                        </user-setting-checkbox>
                        <StatusIndicator ref="si_jambCounterFlashing" :successText="'Saved'"></StatusIndicator>    
                    </div>
                </div>
                <div class="settingsGroupBody">
                    <div class="setting">
                        <label for="des_ohJambLiner">Overhead Jamb Liner</label>
                        <user-setting-checkbox  @change="setBusinessSetting" :customStyle="{display:'inline'}"
                                                ref="des_ohJambLiner"
                                                id="des_ohJambLiner"
                                                :val="des_ohJambLiner"
                                                :disable="!canUpdateSupplierSettings && !canUpdateContractorSettings"
                                                description="">
                        </user-setting-checkbox>
                        <StatusIndicator ref="si_ohJambLiner" :successText="'Saved'"></StatusIndicator>    
                    </div>
                </div>
                <div class="settingsGroupBody">
                    <div class="setting">
                        <label for="des_tackyTapeRoof">Tacky Tape Roof</label>
                        <user-setting-checkbox  @change="setBusinessSetting" :customStyle="{display:'inline'}"
                                                ref="des_tackyTapeRoof"
                                                id="des_tackyTapeRoof"
                                                :val="des_tackyTapeRoof"
                                                :disable="!canUpdateSupplierSettings && !canUpdateContractorSettings"
                                                description="">
                        </user-setting-checkbox>
                        <StatusIndicator ref="si_tackyTapeRoof" :successText="'Saved'"></StatusIndicator>    
                    </div>
                </div>
                <!-- <div class="settingsGroupBody">
                    <div class="setting">
                        <label for="qt_tackyTapeAll">Tacky Tape Over 2:12</label>
                        <user-setting-checkbox  @change="setBusinessSetting" :customStyle="{display:'inline'}"
                                                ref="qt_tackyTapeAll"
                                                id="qt_tackyTapeAll"
                                                :val="qt_tackyTapeAll"
                                                :disable="!canUpdateContractorSettings"
                                                description="">
                        </user-setting-checkbox>
                        <StatusIndicator ref="si_tackyTapeAll" :successText="'Saved'"></StatusIndicator>    
                    </div>
                </div> -->
                <div class="settingsGroupBody">
                    <div class="setting">
                        <label for="des_touchUpPaint">Touch Up Paint</label>
                        <user-setting-checkbox  @change="setBusinessSetting" :customStyle="{display:'inline'}"
                                                ref="des_touchUpPaint"
                                                id="des_touchUpPaint"
                                                :val="des_touchUpPaint"
                                                :disable="!canUpdateSupplierSettings && !canUpdateContractorSettings"
                                                description="">
                        </user-setting-checkbox>
                        <StatusIndicator ref="si_touchUpPaint" :successText="'Saved'"></StatusIndicator>    
                    </div>
                </div>
               
                <div class="settingsGroupBody">
                    <div class="setting">
                        <label for="des_plusGirtsCutToLength">PLUS pkg Girts Cut to length</label>
                        <user-setting-checkbox  @change="setBusinessSetting" :customStyle="{display:'inline'}"
                                                ref="des_plusGirtsCutToLength"
                                                id="des_plusGirtsCutToLength"
                                                :val="des_plusGirtsCutToLength"
                                                :disable="!canUpdateSupplierSettings && !canUpdateContractorSettings"
                                                description="">
                        </user-setting-checkbox>
                        <StatusIndicator ref="si_plusGirtsCutToLength" :successText="'Saved'"></StatusIndicator>    
                    </div>
                </div>
                <div class="settingsGroupBody">
                    <div class="setting">
                        <label for="des_levelOfDetail">Rendering Detail Level</label>
                        <user-setting-dropdown  @change="setBusinessSetting" :customStyle="{display:'inline'}"
                                                ref="des_levelOfDetail"
                                                id="des_levelOfDetail"
                                                :val="des_levelOfDetail"
                                                name="Rendering Detail Level"
                                                :disable="!canUpdateSupplierSettings && !canUpdateContractorSettings"
                                                :options="[{name:'Low', value:'low'},{name:'High', value:'high'}]">
                        </user-setting-dropdown>
                        <StatusIndicator ref="si_levelOfDetail" :successText="'Saved'"></StatusIndicator>    
                    </div>
                </div>
                <div class="settingsGroupBody">
                    <div class="setting">
                        <label for="des_insulationWall">Wall Insulation</label>
                        <user-setting-dropdown  @change="setBusinessSetting" :customStyle="{display:'inline'}"
                                                ref="des_insulationWall"
                                                id="des_insulationWall"
                                                :val="des_insulationWall"
                                                
                                                :disable="!canUpdateSupplierSettings && !canUpdateContractorSettings"
                                                :options="[{name:'None', value:'None'},{name:'VR.3_(R_10)', value:'VR.3_(R_10)'},{name:'VR.4_(R_13)', value:'VR.4_(R_13)'},{name:'VR.6_(R_19)', value:'VR.6_(R_19)'}]">
                        </user-setting-dropdown>
                        <StatusIndicator ref="si_insulationWall" :successText="'Saved'"></StatusIndicator>    
                    </div>
                </div>
                <div class="settingsGroupBody">
                    <div class="setting">
                        <label for="des_insulationRoof">Roof Insulation</label>
                        <user-setting-dropdown  @change="setBusinessSetting" :customStyle="{display:'inline'}"
                                                ref="des_insulationRoof"
                                                id="des_insulationRoof"
                                                :val="des_insulationRoof"
                                                
                                                :disable="!canUpdateSupplierSettings && !canUpdateContractorSettings"
                                                :options="[{name:'None', value:'None'},{name:'VR.3_(R_10)', value:'VR.3_(R_10)'},{name:'VR.4_(R_13)', value:'VR.4_(R_13)'},{name:'VR.6_(R_19)', value:'VR.6_(R_19)'}]">
                        </user-setting-dropdown>
                        <StatusIndicator ref="si_insulationRoof" :successText="'Saved'"></StatusIndicator>    
                    </div>
                </div>
                <div class="settingsGroupBody">
                    <div class="setting">
                        <label for="des_windowStyle">Window Style</label>
                        <user-setting-dropdown  @change="setBusinessSetting" :customStyle="{display:'inline'}"
                                                ref="des_windowStyle"
                                                id="des_windowStyle"
                                                :val="des_windowStyle"
                                                
                                                :disable="!canUpdateSupplierSettings && !canUpdateContractorSettings"
                                                :options="[{name:'Double Hung', value:'doubleHung'},{name:'Picture', value:'picture'}]">
                        </user-setting-dropdown>
                        <StatusIndicator ref="si_windowStyle" :successText="'Saved'"></StatusIndicator>    
                    </div>
                </div>
               

                <!-- <div class="settingsGroupBody">
                    <div class="setting">
                        <div class="" style="display: inline;">Peak Purlin Space <StatusIndicator ref="si_peakSpace"></StatusIndicator></div>
                        <div class="settingValue num"><input type="number" class="digit2" @input="dbEntitySettings({id:'des_peakSpace',val:des_peakSpace})" v-model="des_peakSpace" max="12" min="3" :disabled="!canUpdateSupplierSettings && !canUpdateContractorSettings"/></div>
                        <div class="settingDescription">inches from peak</div>
                    </div>
                </div> -->

                <div class="settingsGroupBody">
                    <div class="setting">
                        <div class="" style="display: inline;">Overhead Door Margins<StatusIndicator ref="si_marginOhd"></StatusIndicator></div>
                        <div class="settingValue num"><input type="number" class="digit3" @input="dbEntitySettings({id:'des_marginOhdSide',val:des_marginOhdSide})" v-model="des_marginOhdSide" min="0" :disabled="!canUpdateSupplierSettings && !canUpdateContractorSettings"/></div>
                        <div class="settingDescription">Sides (inches)</div>
                        <div class="settingValue num"><input type="number" class="digit3" @input="dbEntitySettings({id:'des_marginOhdTop',val:des_marginOhdTop})" v-model="des_marginOhdTop" min="0" :disabled="!canUpdateSupplierSettings && !canUpdateContractorSettings"/></div>
                        <div class="settingDescription">Top (inches)</div>                        
                    </div>
                </div>
                
                <div class="settingsGroupBody">
                    <div class="setting">
                        <div class="" style="display: inline;">Window Margins<StatusIndicator ref="si_marginWindowEdge"></StatusIndicator></div>
                        <div class="settingValue num"><input type="number" class="digit3" @input="dbEntitySettings({id:'des_marginWindowEdge',val:des_marginWindowEdge})" v-model="des_marginWindowEdge" min="0" max="24" :disabled="!canUpdateSupplierSettings && !canUpdateContractorSettings"/></div>
                        <div class="settingDescription">Perimeter (inches)</div>                        
                    </div>
                </div>
                <div class="settingsGroupBody">
                    <div class="setting">
                        <label for="qt_defaultRoofPanel">Default Roof Panel</label>
                        <user-setting-dropdown  @change="setBusinessSetting" :customStyle="{display:'inline'}"
                                                ref="qt_defaultRoofPanel"
                                                id="qt_defaultRoofPanel"
                                                :val="qt_defaultRoofPanel"
                                                :disable="!canUpdateSupplierSettings && !canUpdateContractorSettings"
                                                :options="supplierPanelItems">
                        </user-setting-dropdown>
                        <StatusIndicator ref="si_defaultRoofPanel" :successText="'Saved'"></StatusIndicator>    
                    </div>
                </div>
                <div class="settingsGroupBody">
                    <div class="setting">
                        <label for="qt_defaultWallPanel">Default Wall Panel</label>
                        <user-setting-dropdown  @change="setBusinessSetting" :customStyle="{display:'inline'}"
                                                ref="qt_defaultWallPanel"
                                                id="qt_defaultWallPanel"
                                                :val="qt_defaultRoofPanel"
                                                :disable="!canUpdateSupplierSettings && !canUpdateContractorSettings"
                                                :options="supplierPanelItems">
                        </user-setting-dropdown>
                        <StatusIndicator ref="si_defaultWallPanel" :successText="'Saved'"></StatusIndicator>    
                    </div>
                </div>
                <div class="settingsGroupBody">
                    <div class="setting">
                        <label for="qt_defaultSoffitPanel">Default Soffit Panel</label>
                        <user-setting-dropdown  @change="setBusinessSetting" :customStyle="{display:'inline'}"
                                                ref="qt_defaultSoffitPanel"
                                                id="qt_defaultSoffitPanel"
                                                :val="qt_defaultRoofPanel"
                                                :disable="!canUpdateSupplierSettings && !canUpdateContractorSettings"
                                                :options="supplierPanelItems">
                        </user-setting-dropdown>
                        <StatusIndicator ref="si_defaultSoffitPanel" :successText="'Saved'"></StatusIndicator>    
                    </div>
                </div>
                <div class="settingsGroupBody">
                    <div class="setting">
                        <div class="" style="display: inline;">Wainscot Height <StatusIndicator ref="si_wainscotHeight"></StatusIndicator></div>
                        <div class="settingValue num"><input type="number" class="digit2" @input="dbEntitySettings({id:'des_wainscotHeight',val:des_wainscotHeight})" v-model="des_wainscotHeight" min="12" :disabled="!canUpdateContractorSettings"/></div>
                        <div class="settingDescription">inches tall</div>
                    </div>
                </div>
                <div class="section-group-title"><b>Trim Profiles</b></div>
                    <div class="settingsGroupBody" v-for="item in trimProfilesCategories" :key="item.settingName">
                        <div class="setting">
                            <label for="item.settingName">{{ item.type }}</label>
                            <user-setting-dropdown  @change="setBusinessSetting" :customStyle="{display:'inline'}"
                                                    :ref="item.settingName"
                                                    :id="item.settingName"
                                                    :val="getDynamicSettingValue(item.settingName)"
                                                    :disable="!canUpdateSupplierSettings && !canUpdateContractorSettings"
                                                    :options="supplierTrimProfiles">
                            </user-setting-dropdown>
                            <StatusIndicator :ref="statusRefs[item.settingName]" :successText="'Saved'"></StatusIndicator>    
                        </div>
                </div>
                <div class="section-group-title"><b>Color Configuration</b></div>
                <div class="settingsGroupBody">
                    <div class="setting">
                        <label for="des_wainscotTrimColor">Wainscot Trim Color</label>
                        <user-setting-dropdown  @change="setBusinessSetting" :customStyle="{display:'inline'}"
                                                ref="des_wainscotTrimColor"
                                                id="des_wainscotTrimColor"
                                                :val="des_caulkColor"
                                                :disable="!canUpdateSupplierSettings && !canUpdateContractorSettings"
                                                :options="[{name:'Wainscot', value:'Wainscot'},{name:'Wall', value:'Wall'},{name:'Gutter', value:'Gutter'}]">
                        </user-setting-dropdown>
                        <StatusIndicator ref="si_caulkColor" :successText="'Saved'"></StatusIndicator>    
                    </div>
                </div>
                <div class="settingsGroupBody">
                    <div class="setting">
                        <label for="des_caulkColor">Caulk Color</label>
                        <user-setting-dropdown  @change="setBusinessSetting" :customStyle="{display:'inline'}"
                                                ref="des_caulkColor"
                                                id="des_caulkColor"
                                                :val="des_caulkColor"
                                                :disable="!canUpdateSupplierSettings && !canUpdateContractorSettings"
                                                :options="[{name:'Clear', value:'Clear'},{name:'Wall', value:'Wall'},{name:'Gutter', value:'Gutter'}]">
                        </user-setting-dropdown>
                        <StatusIndicator ref="si_caulkColor" :successText="'Saved'"></StatusIndicator>    
                    </div>
                </div>
                <div class="settingsGroupBody">
                    <div class="setting">
                        <div class="" style="display: inline;">Colors <StatusIndicator ref="si_color"></StatusIndicator></div>
                            
                            <div class="settingDescription">Roof </div>
                            <div class="settingValue">
                                <color-select2 v-model="des_colorRoof"
                                                :settingName="'des_colorRoof'"
                                                :selected="des_colorRoof"
                                                :colors="colorPalette"
                                                :disable="!canUpdateSupplierSettings && !canUpdateContractorSettings"
                                                @change="setBusinessSetting">
                                </color-select2>
                            </div>
                            <div class="settingDescription">Wall </div>
                            <div class="settingValue">
                                <color-select2 v-model="des_colorWall"
                                                :settingName="'des_colorWall'"
                                                :selected="des_colorWall"
                                                :colors="colorPalette"
                                                :disable="!canUpdateSupplierSettings && !canUpdateContractorSettings"
                                                @change="setBusinessSetting">
                                </color-select2>
                            </div>
                            <div class="settingDescription">Wainscoting </div>
                            <div class="settingValue">
                                <color-select2 v-model="des_colorWainscoting"
                                                :settingName="'des_colorWainscoting'"
                                                :selected="des_colorWainscoting"
                                                :colors="colorPalette"
                                                :disable="!canUpdateSupplierSettings && !canUpdateContractorSettings"
                                                @change="setBusinessSetting">
                                </color-select2>
                            </div>
                            <div class="settingDescription">Trim </div>
                            <div class="settingValue">
                                <color-select2 v-model="des_colorTrim"
                                                :settingName="'des_colorTrim'"
                                                :selected="des_colorTrim"
                                                :colors="colorPalette"
                                                :disable="!canUpdateSupplierSettings && !canUpdateContractorSettings"
                                                @change="setBusinessSetting">
                                </color-select2>
                            </div>
                        </div>
                </div>
                <div class="settingsGroupBody" v-for="item in trimColorCategories" :key="item.settingName">
                    <div class="setting">
                        <label for="item.settingName">{{ item.type }}</label>
                        <user-setting-dropdown  @change="setBusinessSetting" :customStyle="{display:'inline'}"
                                                :ref="item.settingName"
                                                :id="item.settingName"
                                                :val="getDynamicSettingValue(item.settingName)"
                                                :disable="!canUpdateSupplierSettings && !canUpdateContractorSettings"
                                                :options="colorInheritanceOptions">
                        </user-setting-dropdown>
                        <StatusIndicator :ref="statusRefs[item.settingName]" :successText="'Saved'"></StatusIndicator>    
                    </div>
                </div>
            </div>
        </template>
      
    </div>
  </template>
<script>
// @ is an alias to /src
import SwmAddAddress from '@/components/swm-add-address.vue';
import swmbox  from '@/components/swmbox.vue';
import userSettingCheckbox from '@/components/user-setting-checkbox.vue';
import userSettingDropdown from '@/components/user-setting-dropdown.vue';
import colorSelect2 from '@/components/color-select2.vue';
import ContextLogic from '@/lib/contextLogic';
import { mapState, mapActions, mapMutations } from 'vuex';
import Vue from 'vue'
import Vuex from 'vuex'
import StatusIndicator from '@/components/StatusIndicator.vue';
import api from '@/api';
import Util from '../lib/utility.js'
Vue.use(Vuex)


export default {
  name: 'HomeView',
  components:{
    SwmAddAddress,
    swmbox,
    userSettingCheckbox,
    userSettingDropdown,
    colorSelect2,
    StatusIndicator
  },
  data(){
    return {  
        entity: {},
        supplierPanelItems: [],
        supplierTrimProfiles:[],
        editing: false,
        colorPalette: [                    
        { id: `B0000-1106158207`, name: `Brown`, code: `BR`, hex: `4B3D2A`, classes: `general, panel, trim`, bang: `#4B3D2A`, tjs: `0x4B3D2A` },
        { id: `180000-1109185637`, name: `Burnished Slate`, code: `BS`, hex: `40433D`, classes: `general, panel, trim`, bang: `#40433D`, tjs: `0x40433D` },
        { id: `100000-1106606089`, name: `Charcoal`, code: `CH`, hex: `333333`, classes: `general, panel, trim`, bang: `#333333`, tjs: `0x333333` },
        { id: `190000-1110397371`, name: `Colony Green`, code: `CG`, hex: `627F70`, classes: `general, panel, trim`, bang: `#627F70`, tjs: `0x627F70` },
        { id: `230000-1125427965`, name: `Crimson`, code: `CR`, hex: `FF3333`, classes: `general, panel, trim`, bang: `#FF3333`, tjs: `0xFF3333` },
        { id: `290000-1150993119`, name: `Desert Sand`, code: `DS`, hex: `88826E`, classes: `general, panel, trim`, bang: `#88826E`, tjs: `0x88826E` },
        { id: `210000-1123619118`, name: `Fern Green`, code: `FG`, hex: `4A4F44`, classes: `general, panel, trim`, bang: `#4A4F44`, tjs: `0x4A4F44` },
        { id: `260000-1129214940`, name: `Gallery Blue`, code: `GB`, hex: `2E465F`, classes: `general, panel, trim`, bang: `#2E465F`, tjs: `0x2E465F` },
        { id: `110000-1107183390`, name: `Galvalume`, code: `GL`, hex: `B9B9B9`, classes: `general, panel, trim`, bang: `#B9B9B9`, tjs: `0xB9B9B9` },
        { id: `F0000-1106174911`, name: `Gray`, code: `GR`, hex: `9A928A`, classes: `general, panel, trim`, bang: `#9A928A`, tjs: `0x9A928A` },
        { id: `1D0000-1112805104`, name: `Hawaiian Blue`, code: `HB`, hex: `1E7498`, classes: `general, panel, trim`, bang: `#1E7498`, tjs: `0x1E7498` },
        { id: `90000-1106157308`, name: `Hunter Green`, code: `HG`, hex: `294922`, classes: `general, panel, trim`, bang: `#294922`, tjs: `0x294922` },
        { id: `D0000-1106158270`, name: `Lt Stone`, code: `LS`, hex: `D0CBA8`, classes: `general, panel, trim`, bang: `#D0CBA8`, tjs: `0xD0CBA8` },
        { id: `E0000-1106161169`, name: `Rustic Red`, code: `RR`, hex: `AB3824`, classes: `general, panel, trim`, bang: `#AB3824`, tjs: `0xAB3824` },
        { id: `C0000-1106158223`, name: `Tan`, code: `TN`, hex: `BBA373`, classes: `general, panel, trim`, bang: `#BBA373`, tjs: `0xBBA373` },
        { id: `A0000-1106157318`, name: `White`, code: `WH`, hex: `FFFFFF`, classes: `general, panel, trim`, bang: `#FFFFFF`, tjs: `FFFFFF` },
        { id: `80000080-1289334086`, name: `Copper Metallic`, code: `CM`, hex: `F5AD38`, classes: `general, panel, trim`, bang: `#F5AD38`, tjs: `0xF5AD38` },
        { id: `80000036-1176479161`, name: `Black`, code: `BL`, hex: `000000`, classes: `general, panel, trim`, bang: `#111111`, tjs: `0x111111` }
        /*{ id: `80000087-1310579219`, name: `SMP-Color`, code: `SC`, hex: `FFFFFF`, classes: `general, panel, trim`, bang: `#FFFFFF`, tjs: `0xFFFFFF` }*/
        ],    
        
        colorInheritanceOptions:[{name: "Roof Panel Color", value: "Roof_Panel_Color"}, 
                                 {name:"Wall Panel Color", value: "Wall_Panel_Color"}, 
                                 {name:"Trim Color", value:"Trim_Color"}, 
                                 {name:"Wainscot Panel Color", value:"Wainscot_Panel_Color"}],
        trimColorCategories: [
            {type:'Trim Angle',settingName: "des_trimAngleColor"},
            {type:'Trim Corner',settingName: "des_trimCornerColor"},
            {type:'Trim Downspout',settingName: "des_trimDownspoutColor"},
            {type:'Trim Drip',settingName: "des_trimDripColor"},
            {type:'Trim Eave',settingName: "des_trimEaveColor"},
            {type:'Trim Gutter',settingName: "des_trimGutterColor"},
            {type:'Trim Head',settingName: "des_trimHeadColor"},
            {type:'Trim Jamb',settingName: "des_trimJambColor"},
            {type:'Trim JambLiner',settingName: "des_trimJambLinerColor"},
            {type:'Trim Partition',settingName: "des_trimPartitionColor"},
            {type:'Trim Rake',settingName: "des_trimRakeColor"},
            {type:'Trim Ridge',settingName: "des_trimRidgeColor"},
            {type:'Trim Transition',settingName: "des_trimTransitionColor"},
            {type:'Trim Wainscot',settingName: "des_trimWainscotColor"},
            {type:'Trim Valley',settingName: "des_trimValleyColor"}
        ],
        trimProfilesCategories:[
            {type:'Rake Profile',settingName: "qt_trimRakeProfile"},
            {type:'Ridge Profile',settingName: "qt_trimRidgeProfile"},
            {type:'Gutter Profile',settingName: "qt_trimGutterProfile"},
            {type:'Downspout Profile',settingName: "qt_trimDownspoutProfile"},
            {type:'Inside Corner Profile',settingName: "qt_trimInsideCornerProfile"},
            {type:'Outside Profile',settingName: "qt_trimOutsideCornerProfile"},
            //{type:'Head Profile',settingName: "qt_trimHeadProfile"},
            //{type:'Jamb Profile',settingName: "qt_trimJambProfile"},
            {type:'Drip/Rat Profile',settingName: "qt_trimDripRatProfile"},
            {type:'Eave Profile',settingName: "qt_trimEaveProfile"},

        ],      
        statusRefs: {   // settingName : ref
             "legalName": "si_legalName",
              "businessName": "si_businessName",
              "phoneNumber": "si_phoneNumber",
              "url": "si_url",
              "mailingAddress1": "si_mailingAddress1",
              "mailingAddress2": "si_mailingAddress2",
              "mailingAddressCity": "si_cityStateZip",
              "mailingAddressState": "si_cityStateZip",
              "mailingAddressZip": "si_cityStateZip",

              "logo_Sml": "si_logoSml",
              "logo_Lrg": "si_logoLrg",

              "qt_defaultMaxPurlinSpacing": "si_defaultMaxRoofPurlinSpacing",
              "qt_defaultMaxGirtSpaceFt": "si_defaultMaxGirtSpaceFt",
              "qt_defaultWallGirtCondition": "si_defaultWallGirtCondition",
              "qt_defaultRoofPurlinCondition": "si_defaultRoofPurlinCondition",
              "qt_peakSpaceSelection": "si_peakSpaceSelection",
              "qt_startTaperFrameWidth": "si_startTaperFrameWidth",
              
              "des_taxes": "si_taxes",
              "des_gutter": "si_gutter",
              "des_dripTrim": "si_dripTrim",
              "des_tubePorchColTrim": "si_tubePorchColTrim",
              "des_deadboltPrep": "si_deadboltPrep",
              "des_jambCounterFlashing": "si_jambCounterFlashing",
              "des_ohJambLiner": "si_ohJambLiner",
              "des_tackyTapeRoof": "si_tackyTapeRoof",
              "des_plusGirtsCutToLength": "si_plusGirtsCutToLength",
              "des_levelOfDetail": "si_levelOfDetail",
              "des_insulationWall": "si_insulationWall",
              "des_insulationRoof": "si_insulationRoof",
              "des_windowStyle": "si_windowStyle",
              "des_wainscotHeight": "si_wainscotHeight", 
              "des_peakSpace": "si_peakSpace",
              "des_marginOhdSide": "si_marginOhd",
              "des_marginOhdTop": "si_marginOhd",
              "des_marginWindowEdge": "si_marginWindowEdge",
              "des_colorRoof": "si_color",
              "des_colorWall": "si_color",
              "des_colorWainscoting": "si_color",
              "des_colorTrim": "si_color",

              "des_tackyTapeAll": "si_tackyTapeAll",
              "des_caulkColor": "si_caulkColor",
              "des_touchUpPaint": "si_touchUpPaint",     
              "des_wainscotTrimColor": "si_wainscotTrimColor",
              "qt_defaultRoofPanel": "si_defaultRoofPanel",
              "qt_defaultWallPanel": "si_defaultWallPanel",
              "qt_defaultSoffitPanel": "si_defaultSoffitPanel",

              "qt_trimRakeProfile": "si_trimRakeProfile",
              "qt_trimRidgeProfile": "si_trimRidgeProfile",
              "qt_trimGutterProfile": "si_trimGutterProfile",
              "qt_trimDownspoutProfile": "si_trimDownspoutProfile",
              "qt_trimInsideCornerProfile": "si_trimInsideCornerProfile",
              "qt_trimOutsideCornerProfile": "si_trimOutsideCornerProfile",
            //   "qt_trimHeadProfile": "si_trimHeadProfile",
            //   "qt_trimJambProfile": "si_trimJambProfile",
              "qt_trimDripRatProfile": "si_trimDripRatProfile",
              "qt_trimEaveProfile": "si_trimEaveProfile",
  
              "des_trimAngleColor": "si_angleColor",
              "des_trimCornerColor": "si_cornerColor",
              "des_trimDownspoutColor": "si_downspoutColor",
              "des_trimDripColor": "si_dripColor",
              "des_trimEaveColor": "si_eaveColor",
              "des_trimGutterColor": "si_gutterColor",
              "des_trimHeadColor": "si_headColor",
              "des_trimJambColor": "si_jambColor",
              "des_trimJambLinerColor": "si_jambLinerColor",
              "des_trimPartitionColor": "si_partitionColor",
              "des_trimRakeColor": "si_rakeColor",
              "des_trimRidgeColor": "si_ridgeColor",
              "des_trimTransitionColor": "si_transitionColor",
              "des_trimWainscotColor": "si_wainscotColor",
              "des_trimValleyColor": "si_valleyColor",
        },
      classes: {
        container: "settingsGroup",
        header: "settingsGroupHeader",
        body: "settingsGroupBody",
        setting: "setting",
        value: "settingValue",
        description: "settingDescription"
      },
      isAuthenticated: false,
        showSupplierOnlySetting: false,
        canUpdateBusinessInfo: false,
        canUpdateSupplierSettings: false,
        canUpdateContractorSettings: false,

        qt_defaultMaxPurlinSpacing: 5.1,
        qt_defaultMaxGirtSpaceFt: 5,
        qt_defaultWallGirtCondition: "Flush",
        qt_defaultRoofPurlinCondition: "Bypass",
        qt_peakSpaceSelection: 1,
        qt_startTaperFrameWidth: 60,
        
        qt_directFabCostPerDay: 650,
        qt_boltPurlinPcsPerDay: 50,
        qt_boltBeamPcsPerDay: 20,
        qt_detailCostPerHour: 70,
        qt_fabLaborSellPerLb: 1.02,
        qt_beamGalvCostPerLb: 0.50,
        qt_beamGalvSellPerLb: 0.60,
        qt_purlinGalvAdd: 1.20,
        qt_fastenerLongLifeCost: 0.12,
        qt_FastenerLongLifeMult: 2.23,
        qt_roofFieldScrewsPerSq: 40,
        qt_roofLapScrewsPerSq: 20,
        qt_wallFieldScrewsPerSq: 40,
        qt_wallLapScrewsPerSq: 30,
        qt_tackyTapeAll: false,
        des_caulkColor: "Gutter",
        des_touchUpPaint: true,
        des_wainscotTrimColor: "Wall",
        qt_defaultRoofPanel: "PBR26C",
        qt_defaultWallPanel: "PBR26C",
        qt_defaultSoffitPanel: "RBU26C",
        des_angleColor: "Trim_Color",
        des_cornerColor: "Trim_Color",
        des_downspoutColor: "Trim_Color",
        des_dripColor: "Wall_Panel_Color",
        des_eaveColor: "Trim_Color",
        des_gutterColor: "Trim_Color",
        des_headColor: "Trim_Color",
        des_jambColor: "Trim_Color",
        des_jambLinerColor: "Trim_Color",
        des_partitionColor: "Wall_Panel_Color",
        des_rakeColor: "Trim_Color",
        des_ridgeColor: "Roof_Panel_Color",
        des_transitionColor: "Roof_Panel_Color",
        des_wainscotColor: "Wainscot_PanelColor",
        des_valleyColor: "Roof_Panel_Color",
        qt_ninjaFee: "Add to subtotal",
        qt_redOxide: "1",
        qt_trimRakeProfile: "RA-Com",
        qt_trimRidgeProfile: "RC10-s",
        qt_trimGutterProfile: "GU-Com",
        qt_trimDownspoutProfile: "DS-k",
        qt_trimInsideCornerProfile: "IC",
        qt_trimOutsideCornerProfile: "OU",
        // qt_trimHeadProfile: "RHE",
        // qt_trimJambProfile: "RHE",
        qt_trimDripRatProfile: "Base",
        qt_trimEaveProfile: "LEA",
    //   qt_trimCostLb: 7,
    //   qt_kynarTrimFactor: 0.42,

        logo_Sml: '',
        logo_Lrg: '',
        des_dripTrim: false,
        des_gutter: true,
        des_wainscotHeight: 42,
        des_tubePorchColTrim: true,
        des_deadboltPrep: true,
        des_jambCounterFlashing: false,
        des_ohJambLiner: true,
        des_tackyTapeRoof: false,
        des_plusGirtsCutToLength: false,
        pro_price: false,
        des_insulationRoof: 'VR.3_(R_10)',
        des_insulationWall: 'VR.3_(R_10)',
        des_windowStyle: 'doubleHung',
        des_levelOfDetail: 'low',
        des_peakSpace: 12,
        des_marginOhdSide: 4,
        des_marginOhdTop: 15,
        des_marginWindowEdge: 9,
        des_colorRoof: {
            code: "HG",
            hex: "294922",
            id: "90000-1106157308",
            name: "Hunter Green",
            number: 4869956
        },
        des_colorWall: {
            code: "LS",
            hex: "D0CBA8",
            id: "D0000-1106158270",
            name: "Lt Stone",
            number: 4869956
        },
        des_colorWainscoting: {
            code: "HG",
            hex: "294922",
            id: "90000-1106157308",
            name: "Hunter Green",
            number: 4869956
        },
        des_colorTrim: {
            code: "HG",
            hex: "294922",
            id: "90000-1106157308",
            name: "Hunter Green",
            number: 4869956
        },
        des_taxes: 'Yes - City',
    }
  },
  computed:{
    ...mapState('contextModule',[
      'user'
      ,'userBusiness'
      ,'current'
    ]),
    mode(){
      return this.current.mode
    },
    async getToken(){
      let token = await this.$auth.getIdTokenClaims();
      console.log(token)
      console.log(this.$auth)
      return token.userRoles[0];
    },
  },
  methods: {
    ...mapActions('businessModule', ['getBusinessSettings','updateBusinessInfo', 'fetchBusiness', 'updateBusinessInfo','getBusinessTrimProfiles']),
    backToBusinesses(){
        // this needs to become fully centralized if every settings page is going to need it
        ContextLogic.clearBusinessContext(this.$store);

        if(this.userBusiness.type==1)
            this.$router.push({path: `/supplier/${this.userBusiness.id}/contractors`})
        else
            this.$router.push({path: `/businesses/list`})
    },
    getDynamicSettingValue(settingName){
        return this[settingName];
    },
    async updateEntity(settingName) {
        let si = this.$refs[this.statusRefs[settingName]];
        if(si != undefined)
            si.loading();

        let response = await this.updateBusinessInfo(this.entity);

        if(response){
            si.fail();
        }
        else{
            si.success();
        }
    },
    handleLogoUpload(event, settingName) {
        const file = event.target.files[0];
        if (!file) return;
        
        let logo = file;
        const reader = new FileReader();
        
        reader.onload = (e) => {
            if(settingName.includes("Sml")){
                this.logo_Sml = e.target.result;
                let data = {id: settingName, val:this.logo_Sml};
                this.setBusinessSetting(data);
            }
            else if(settingName.includes("Lrg")){
                this.logo_Lrg = e.target.result;
                let data = {id: settingName, val:this.logo_Lrg};
                this.setBusinessSetting(data);
            }
        }
        reader.readAsDataURL(logo)
    },
    async setBusinessSetting(data){
        var val;
        // if the object has a hex property, it's a color setting, 
        if (data.val.hex)
            val = data.val.id; // use color.id for colors (changed week of 2023.11.07)
        else{
            val = `${data.val}`
        }
            
        let settingsGroup = {
            businessId: this.entity.id,
            name: data.id,
            value: val
        }

        let statusRefId = this.statusRefs[data.id]; // use the setting name to know the setting status indicator name
        let si = this.$refs[statusRefId]; // use the setting status indicator name to get the status indicator component
        if(si != undefined)
        {
            if(Array.isArray(si)){ 
                // Vue takes overwhen refs are in an v-for context and makes the Ref an array no matter what.
                si= si[0];
            }
            si.loading();
        }

        let response = await api.setBusinessSetting(settingsGroup);

        if(response.isError){
            si.fail();
        }
        else{
            si.success();
        }
    },
    async saveChanges(){
        let response = await this.updateBusinessInfo(this.entity);
        if(response.isError)
            this.commitDetailsError(response.data)
        else{
            this.editing = false;
            this.$router.push(this.$route.path.replace('/edit', ''));
        }
    },
    commitDetailsError(errData){
    this.$modal.show('dialog', {
        title: 'Warning',
        text: `${errData.msg}`,
        buttons:[
        {
            title: 'OK',
            handler: () => {
            this.$modal.hide('dialog')
            }
        },
        ]
    })
    },
    defaultAllSettings() {
      this.qt_defaultMaxPurlinSpacing = 5.1;
      this.qt_defaultMaxGirtSpaceFt = 5;
      this.qt_defaultWallGirtCondition = "Flush";
      this.qt_defaultRoofPurlinCondition = "Bypass";
      this.qt_peakSpaceSelection = 1;
      this.qt_startTaperFrameWidth= 60,
      
      this.qt_directFabCostPerDay = 650;
      this.qt_boltPurlinPcsPerDay = 50;
      this.qt_boltBeamPcsPerDay = 20;
      this.qt_detailCostPerHour = 70;
      this.qt_fabLaborSellPerLb = 1.02;
      this.qt_beamGalvCostPerLb = 0.50;
      this.qt_beamGalvSellPerLb = 0.60;
      this.qt_purlinGalvAdd = 1.20;
      this.qt_fastenerLongLifeCost = 0.12;
      this.qt_FastenerLongLifeMult = 2.23;
      this.qt_roofFieldScrewsPerSq = 40;
      this.qt_roofLapScrewsPerSq = 20;
      this.qt_wallFieldScrewsPerSq = 40;
      this.qt_wallLapScrewsPerSq = 30;
      this.qt_tackyTapeAll = false;
      this.des_caulkColor = "Gutter";
      this.des_touchUpPaint = true;
      this.des_wainscotTrimColor = "Wall";
      this.qt_defaultRoofPanel = "PBR26C";
      this.qt_defaultWallPanel = "PBR26C";
      this.qt_defaultSoffitPanel = "RBU26C";
      this.des_angleColor = "Trim_Color";
      this.des_cornerColor = "Trim_Color";
      this.des_downspoutColor = "Trim_Color";
      this.des_dripColor = "Wall_Panel_Color";
      this.des_eaveColor = "Trim_Color";
      this.des_gutterColor = "Trim_Color";
      this.des_headColor = "Trim_Color";
      this.des_jambColor = "Trim_Color";
      this.des_jambLinerColor = "Trim_Color";
      this.des_partitionColor = "Wall_Panel_Color";
      this.des_rakeColor = "Trim_Color";
      this.des_ridgeColor = "Roof_Panel_Color";
      this.des_transitionColor = "Roof_Panel_Color";
      this.des_wainscotColor = "Wainscot_PanelColor";
      this.des_valleyColor = "Roof_Panel_Color",
      this.qt_ninjaFee= "Add to subtotal";
      this.qt_redOxide= "1";
      this.qt_trimRakeProfile = "RA-Com";
      this.qt_trimRidgeProfile = "RC10-s";
      this.qt_trimGutterProfile = "GU-Com";
      this.qt_trimDownspoutProfile = "DS-k";
      this.qt_trimInsideCornerProfile = "IC";
      this.qt_trimOutsideCornerProfile = "OU";
    //   this.qt_trimHeadProfile = "RHE";
    //   this.qt_trimJambProfile = "RHE";
      this.qt_trimDripRatProfile = "Base";
      this.qt_trimEaveProfile = "LEA";
    //   this.qt_trimCostLb = 7;
    //   this.qt_kynarTrimFactor = 0.42;

      this.logo_Sml = '';
      this.logo_Lrg = '';
      this.des_gutter = true;
      this.des_wainscotHeight = 42;
      this.des_tubePorchColTrim = true;
      this.des_deadboltPrep = true;
      this.des_JambCounterFlashing = false;
      this.des_ohJambLiner = true;
      this.des_tackyTapeRoof = false;
      this.des_plusGirtsCutToLength = false;
      this.pro_prices = false;
      this.des_insulationRoof = 'VR.3_(R_10)';
      this.des_insulationWall = 'VR.3_(R_10)';
      this.des_windowStyle = 'doubleHung';
      this.des_levelOfDetail = 'low';
      this.des_peakSpace = 12
      this.showProposed = false
      this.showPrices = false
      this.des_marginOhdSide = 4
      this.des_marginOhdTop = 15
      this.des_marginWindowEdge = 9
      this.des_colorRoof = {
          code: "HG",
          hex: "294922",
          id: "90000-1106157308",
          name: "Hunter Green",
          number: 4869956
      }
      this.des_colorWall = {
          code: "LS",
          hex: "D0CBA8",
          id: "D0000-1106158270",
          name: "Lt Stone",
          number: 4869956
      }
      this.des_colorWainscoting = {
          code: "HG",
          hex: "294922",
          id: "90000-1106157308",
          name: "Hunter Green",
          number: 4869956
      }
      this.des_colorTrim = {
          code: "HG",
          hex: "294922",
          id: "90000-1106157308",
          name: "Hunter Green",
          number: 4869956
      }

      this.des_taxes= 'Yes - City';
      
    },
    async loadSettingOptions(){
        let pathParts = this.$route.path.split("/");
        let businessId = pathParts[2];
        let response = await this.getBusinessTrimProfiles(businessId);
        if(response.isError){
            this.$toast.error(response.data.msg);
            console.error(response);
        }
        else
        {
            this.supplierTrimProfiles=response.map(x => ({name: x.entityCode, value: x.systemCode}));
        }

    },
    async loadSettingValues(){
        let pathParts = this.$route.path.split("/");
        let businessId = pathParts[2];

        this.defaultAllSettings();
        let response = await this.getBusinessSettings(businessId);
        if(response.isError){
            this.$toast.error(response.data.msg);
        }
        else{
            let settings = response;
            this.initSettings(settings);
        }

    },
    initSettings(settings) {
      // assign the data values
      settings.forEach((settingsGroup) => {
          this.initSetting(settingsGroup);
      })               
      
      // assigning the logo value won't set the image, so apply the base64 data to the img src

    },
    initSetting(setting) {
        if (setting.value === "true" || setting.value === "false")
            setting.value = setting.value === 'true';

        if (setting.name === "des_colorRoof" || setting.name === "des_colorWall" || 
            setting.name === "des_colorWainscoting" || setting.name === "des_colorTrim") {
            this.colorPalette.forEach(c => {
                // match the itemID in the setting value to the itemID of a color in the color palette
                if (c.id === setting.value) {
                    // use that color
                    setting.value = c;
                }
            });
        }
        if (typeof setting.value !== 'undefined') {
            this[setting.name] = setting.value;
        }
    },
  },
  created() {
    this.dbEntityInfo = Util.debounce(this.updateEntity, 1000);
    this.dbEntitySettings = Util.debounce(this.setBusinessSetting,1000);
  },
  async mounted() {
    this.loadSettingOptions();
    this.loadSettingValues();

    await ContextLogic.initContext(this);
    this.isAuthenticated = this.$auth.isAuthenticated;
    let isContractorAdmin = this.user.roles.includes(2);
    let isSupplierAdmin = this.user.roles.includes(1);
    let isSystemAdmin = this.user.roles.includes(0);
    console.log(`contractor admin: ${isContractorAdmin} || supplier admin: ${isSupplierAdmin} || system admin: ${isSystemAdmin}`)
    this.showSupplierOnlySetting = this.mode == 1;
    this.canUpdateSupplierSettings = isSupplierAdmin || isSystemAdmin;
    this.canUpdateContractorSettings = isContractorAdmin || isSystemAdmin;
    this.canUpdateBusinessInfo = this.canUpdateSupplierSettings || this.canUpdateContractorSettings;
    console.log(`can update contractor settings: ${this.canUpdateContractorSettings}`)
    console.log(`can update supplier settings: ${this.canUpdateSupplierSettings}`)
    console.log(`can update entity info: ${this.canUpdateBusinessInfo}`)



    let pathParts = this.$route.path.split("/");
    this.entity = await this.fetchBusiness(pathParts[2]);
  }
}
</script>
<style scoped>


   @media (max-width: 4924px) {
       .settingsGroup {
           font-size: 1.3em;
       }
   }
   @media (max-width: 1280px) {
       .settingsGroup {
           font-size: 1.2em;
       }
   }
   @media (max-width: 1024px) {
       .settingsGroup {
           font-size: 1.1em;
       }
   }
   @media (max-width: 768px) {
       .settingsGroup {
           font-size: 1.0em;
       }
   }
   @media (max-width: 640px) {
       .settingsGroup {
           font-size: 1.0em;
       }
   }
   .settingGroupList {
       width: 90%;
       margin: auto;
   }

   .settingsContainer {
       display: flex;
       flex-flow: row wrap;
       justify-content: space-evenly;
       align-items: flex-start;
   }

   .settingsItem {
       display: block;
       margin: 5px;
       padding: 5px;
       margin-left: 20px;
       border: 1px solid lightgrey;        
       width:100%;
       text-align: left;
   }


   /* parent  */
   
   .settingsContainer {
       flex-grow: 2;
   }


   .settingsGroupList {
       display: flex;
       flex-flow: row wrap;

   }

   /* item */
   .settingsGroup {
       display: block;
       margin: 5px;
       padding: 2px;
   }

   .settingsGroup .settingsGroupBody {
       display: block;
       flex-grow: 1;
       color: black;
       flex-shrink: 2;
       text-align: left;       
   }

   .flex {
       display: flex;
       flex-flow: row wrap;
   }

   .settingsGroup .settingsGroupHeader {
       display: block;
       font-weight: bold;
       width:100%;
       background: lightgray;
       padding: 10px;
       text-align: left;
   }

   .setting {
       display: inline-block;
       margin: 10px;
       vertical-align: top;
   }

   .settingsGroup .settingDescription {
       display: inline-block;
       margin: 3px 0 0 5px;
       font-size: .8em;
       vertical-align: top;
       
   }

   .settingsGroup .settingValue {
       flex-grow: 1;
       text-align: left;
       display: inline-block;
       margin-left: 10px;
       
   }        


   .settingValue input {       
       flex-shrink: 1;
   }

   .settingValue .designLogoSml {
       margin: 3px;
       display: block;
       text-align: left
   }

   .settingValue .designLogoSml img {
       margin: 3px;
       max-width: 150px;
       box-shadow: 0px 0px 2px black;
       display: inline-block;
       text-align: center;
       margin-left: auto;
       margin-right: auto;
       background: black
   }

   .settingValue .designLogoLrg img {
       margin: 3px;
       max-width: 480px;
       box-shadow: 0px 0px 2px black;
       display: inline-block;
       text-align: center;
       margin-left: auto;
       margin-right: auto;
   }

   .hidden {
       display: none;
   }

   .logoTypeLabel {
       width: 120px;
       display: block;
       font-size: .7em;
   }

   .horsep {
       border-top: 1px solid lightgray;
       display:inline-block;
       margin:auto;
       height:10px;
       width:95%;
       text-align:center;
   }

   .digit2 {
       width: 51px;
   }
   .digit3 {
       width: 76px;
   }

   input[type=checkbox] {
       transform: scale(1.3);
       margin-right: 10px;     
       margin-bottom: 3px;
   }

   .logo-input{
        display: inline-flex;
        margin: 20px 50px;
    }

    .logo-lrg {
        width: 300px;
        
    }

    .logo-sml {
      width: 80px;
      border-radius: 15px;
      /* background-color: #f0f1f5; */
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      
      img {
        max-width: 100%;
        /* border-radius: 50%; */
      }
    }

    .section-group-title{
        margin: 15px 10px;
        text-align: left;
        background: rgb(231, 231, 231)
    }

    input.w-s{
        width:100px;
    }
    input.w-m{
        width:350px;
    }
    input.w-l{
        width:500px;
    }



</style>
