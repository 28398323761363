<template>
    <div>
      <span class="select">
      <select :disabled="disable" v-model="selectedUser" @change="$emit('user-selected', selectedUser)">
        <option value="">Select a user</option>
        <option v-for="user in users" :key="user.id" :value="user.id">
          {{ user.name }}
        </option>
      </select>
    </span>
    </div>
  </template>
  
  <script>
  export default {
    name: 'UserPicker',
    props: {
      users: {
        type: Array,
        required: true
      },
      disable: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        selectedUser: '',
        // isDisabled: false
      }
    },
    watch:{
  
    },
    created(){
    }
  }
  </script>