<template>
  <div class="html-test">
    <h1 class="h1" >HTML Test Page</h1>
    <!-- Add test HTML patterns here -->
    <p class="mar-bs-5 mar-be-4">Here is an icon rendered with the Icon component.</p>
    <Icon name="cube" custom-class="icon-size-4" custom-style="" />
    <SnuiWrapper>
      <div class="mar-bs-5">
        <p class="p mar-be-4">Here we use the SnuiWrapper component to wrap a div that contains a modal and it's button. This wrapper initializes the snui.js library features within it.</p>
        <button class="btn" data-open-modal="#modal-small">Modal Small</button>
        <dialog class="modal modal-small modal-fade" id="modal-small" autofocus>
          <div class="modal-content">
            <button type="button" class="modal-close" aria-label="close" data-close-modal>
              <Icon name="delete-1" custom-class="icon-size-1" />
            </button>
            <div class="rich-text remove-outer-children-margin">
              <h2>Modal Small</h2>
              <p>Has a small max width.</p>
            </div>
          </div>
        </dialog>
      </div>
    </SnuiWrapper>
    
    <SnuiWrapper>
      <div class="mar-bs-5">
          <p class="p mar-be-4">Here is a collapse example.</p>
					<div class="collapse">
						<button class="text-link collapse-toggle" aria-expanded="false">Basic Collapse</button>
						<div class="collapse-content">
							<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam odio lectus, condimentum sit amet luctus sed, consequat et tortor. Etiam non tortor eu nunc euismod aliquet vitae a odio.</p>
						</div>
					</div>
				</div>
    </SnuiWrapper>
  
  </div>
</template>

<script>
export default {
  name: 'HtmlTest',
  data() {
    return {
      // Add reactive data here as needed
    }
  }
}
</script>

<style scoped>
.html-test {
  padding: 20px;
}
</style> 