<template>
  <nav class="sidebar-nav color-context-neutral-shaded-2" style="--display-xs: none; --display-md: flex;">
    <div class="main-menu">
      <div class="main-menu-top">
        <slot></slot>
      </div>
      <div class="main-menu-bottom">
        <slot name="main-menu-bottom"></slot>
      </div><!-- main-menu-bottom -->
    </div><!-- main-menu -->
    <div id="submenu" class="submenu" :class="{ 'submenu-hidden': !showSubmenu }">
      <div class="submenu-inner">
        <slot name="submenu"></slot>
      </div>
    </div>
  </nav><!-- sidebar-nav -->
</template>
  
  <script>
  export default {
    name: 'Sidebar2',
    props: {
      submenuRoutes: {
        type: Array,
        default: () => []
      }
    },
    computed: {
      showSubmenu() {
        if (!this.$route) return false;
        
        return this.submenuRoutes.some(pattern => {
          // Convert route pattern (e.g. '/supplier/:id/settings') to regex pattern
          // Replace :param with regex to match any chars except /
          const regexPattern = pattern
            .replace(/:\w+/g, '[^/]+') // Replace :id, :ref etc with [^/]+ to match any non-slash chars
            .replace(/\//g, '\\/');    // Escape forward slashes for regex

          // Create regex that matches entire path exactly
          const regex = new RegExp(`^${regexPattern}$`);
          return regex.test(this.$route.path);
        });
      }
    }
  };
  </script>
  
  <style scoped>
  
  </style>