<template>
    <div class="" v-if="mode == 1">
        <div class="">
            <template>
                <div class="settingsGroup" v-if="section == sections.itemManager">
                    <div class="settingsGroupHeader"><b>Items</b></div>
                    <div class="settingsGroupBody">
                        <div class="setting">
                            <ItemManagerView></ItemManagerView>
                            <router-link :to="{ path: itemManagerRoutePath }">
                                Edit Items
                            </router-link>
                        </div>
                    </div>
                </div> <!-- end settings group -->
                <div class="settingsGroup" v-if="section == sections.pricingModel">
                    <h1 class="h1">Pricing Configuration</h1>
                    <div style="text-align: left;font-size:medium">
                        <p>Pricing models determine how quoted item prices are calculated. There are two models:
                        <ul>
                            <li>Component Pricing</li>
                            <li>Cost Markup</li>
                        </ul>

                        The <b>Component Pricing</b> pricing uses the specific item's configured price per unit. Component Pricing does <i>not</i> rely on a Pricing Level.
                        <br/><br/>
                        The <b>Cost Markup</b> pricing applies a markup to the cost of items. A <b>Pricing Level</b> allows distinct markup margins to be configured per item category.  
                        Each structure type (bolt up vs weld up) has a configurable Pricing Level.  The margin used for an item depends on the Pricing Level for the structure type and the category of an item. 
                        <br/>

                        </p>
                    </div>
                    <div class="schemes-container">
                        <ContractorPricing></ContractorPricing>
                    </div>
                </div> <!-- end settings group -->
                <div class="settingsGroup" v-if="section == sections.pricingLevels">
                    <h1 class="h1">Pricing Levels</h1>
                    <CategoryMarkup
                            ref="pricingLevels"
                        />
                </div> <!-- end settings group -->
                <div class="settingsGroup" v-if="section == sections.logistics"> 
                    <h1 class="h1">Logistics</h1>
                    <!-- <div class="settingsGroupBody">
                        <div class="setting">
                            <label for="qt_ninjaFee">Ninja Fee</label>
                            <user-setting-dropdown @change="setBusinessSetting" :customStyle="{display:'inline'}"
                                            ref="qt_ninjaFee"
                                            id="qt_ninjaFee"
                                            :val="qt_ninjaFee"
                                            name=""
                                            :disable="!canUpdateSupplierSettings"
                                            :options="[
                                            {name:'Add to subtotal', value:'Add to subtotal'},
                                            {name:'Pay as company fee', value:'Pay as company fee'},
                                            ]">                                        
                            </user-setting-dropdown>
                            <StatusIndicator ref="si_ninjaFee" :successText="'Saved'"></StatusIndicator>    
                        </div>
                    </div> -->
                    <div class="settingsGroupBody">
                        <div class="setting">
                            <label for="qt_ninjaFee">Red Oxide</label>
                            <user-setting-dropdown @change="setBusinessSetting" :customStyle="{display:'inline'}"
                                            ref="qt_redOxide"
                                            id="qt_redOxide"
                                            :val="qt_redOxide"
                                            name=""
                                            :disable="!canUpdateSupplierSettings"
                                            :options="[
                                                {name:'1', value:'1'},
                                                {name:'5', value:'5'},
                                            ]">                                        
                            </user-setting-dropdown>
                            <div class="settingDescription"> Gal</div>   
                            <StatusIndicator ref="si_ninjaFee" :successText="'Saved'"></StatusIndicator>    
                        </div>
                    </div>
                
                    
                    <div class="settingsGroupBody">
                        <div class="setting">
                            <div class="" style="display: inline;">Origin Zipcode<StatusIndicator ref="si_zipHome"></StatusIndicator></div>
                            <input type="text" class="w-s" v-model="qt_zipHome" @input="dbBusinessSettings({id:'qt_zipHome',val:qt_zipHome})" :disabled="!canUpdateSupplierSettings"/>             
                        </div>
                    </div>
                    <div class="settingsGroupBody">
                        <div class="setting">
                            <div class="" style="display: inline;">Maximum Distance<StatusIndicator ref="si_maxLocalDelivery"></StatusIndicator></div>
                            <div class="settingValue num"><input type="number" class="digit3" @input="dbBusinessSettings({id:'qt_maxLocalDelivery',val:qt_maxLocalDelivery})" v-model="qt_maxLocalDelivery" min="0"  :disabled="!canUpdateSupplierSettings"/></div>
                            <div class="settingDescription"> miles</div>                        
                        </div>
                    </div>
                    <div class="settingsGroupBody">
                        <div class="setting">
                            <div class="" style="display: inline;">Minimum Cost<StatusIndicator ref="si_truckMinCost"></StatusIndicator></div>
                            <div class="settingValue num"><input type="number" class="digit3" @input="dbBusinessSettings({id:'qt_truckMinCost',val:qt_truckMinCost})" v-model="qt_truckMinCost" min="0"  :disabled="!canUpdateSupplierSettings"/></div>
                            <div class="settingDescription"> dollars</div>                        
                        </div>
                    </div>
                    <div class="settingsGroupBody">
                        <div class="setting">
                            <div class="" style="display: inline;">Mileage Rate (Company Truck)<StatusIndicator ref="si_mileageRateCompanyTruck"></StatusIndicator></div>
                            <div class="settingValue num">$<input type="number" class="digit3" @input="dbBusinessSettings({id:'qt_mileageRateCompanyTruck',val:qt_mileageRateCompanyTruck})" v-model="qt_mileageRateCompanyTruck" min="0"  :disabled="!canUpdateSupplierSettings"/></div>
                            <div class="settingDescription"> / mile</div>                        
                        </div>
                    </div>
                    <div class="settingsGroupBody">
                        <div class="setting">
                            <div class="" style="display: inline;">Mileage Rate (Outside Truck)<StatusIndicator ref="si_mileageRateOutsideTruck"></StatusIndicator></div>
                            <div class="settingValue num">$<input type="number" class="digit3" @input="dbBusinessSettings({id:'qt_mileageRateOutsideTruck',val:qt_mileageRateOutsideTruck})" v-model="qt_mileageRateOutsideTruck" min="0"  :disabled="!canUpdateSupplierSettings"/></div>
                            <div class="settingDescription"> / mile</div>                        
                        </div>
                    </div>
                    <!-- <div class="settingsGroupBody">
                        <div style="text-align: left;font-size:medium">
                            <p>Default Tax Rate <b>overrides</b> all projects' calculated tax rates. <br>
                            Examples: <br>
                            <ul>
                                <li>0.00% for pre-tax estimates</li>
                                <li>10.25% for Lousiana out of state county rate</li>
                            </ul>
                            </p>
                        </div>
                        <div class="setting">
                            <div class="" style="display: inline;">Default Tax Rate<StatusIndicator ref="si_defaultSalesTaxRate"></StatusIndicator></div>
                            <div class="settingValue num"><input type="number" class="digit3" @input="dbBusinessSettings({id:'qt_defaultSalesTaxRate',val:qt_defaultSalesTaxRate})" v-model="qt_defaultSalesTaxRate" min="0"  :disabled="!canUpdateSupplierSettings"/></div>
                            <div class="settingDescription"> %</div>                        
                        </div>
                    </div> -->
                </div> <!-- end settings group-->
                
                <div class="settingsGroup" v-if="section == sections.fabrication">
                    <h1 class="h1">Fabrication</h1>
                        
                        <div class="settingsGroupBody">
                            <div class="setting">
                                <div class="" style="display: inline;">Direct Fabrication Cost<StatusIndicator ref="si_directFabCostPerDay"></StatusIndicator></div>
                                <div class="settingValue num">$<input type="number" class="digit3" @input="dbBusinessSettings({id:'qt_directFabCostPerDay',val:qt_directFabCostPerDay})" v-model="qt_directFabCostPerDay" min="0"  :disabled="!canUpdateSupplierSettings"/></div>
                                <div class="settingDescription"> / day</div>                        
                            </div>
                        </div>
                        <div class="settingsGroupBody">
                            <div class="setting">
                                <div class="" style="display: inline;">Fabrication Labor Price<StatusIndicator ref="si_fabLaborSellPerLb"></StatusIndicator></div>
                                <div class="settingValue num">$<input type="number" class="digit3" @input="dbBusinessSettings({id:'qt_fabLaborSellPerLb',val:qt_fabLaborSellPerLb})" v-model="qt_fabLaborSellPerLb" min="0"  :disabled="!canUpdateSupplierSettings"/></div>
                                <div class="settingDescription"> / Lb</div>                        
                            </div>
                        </div>
                        <div class="settingsGroupBody">
                            <div class="setting">
                                <div class="" style="display: inline;">Detail Cost<StatusIndicator ref="si_detailCostPerHour"></StatusIndicator></div>
                                <div class="settingValue num">$<input type="number" class="digit3" @input="dbBusinessSettings({id:'qt_detailCostPerHour',val:qt_detailCostPerHour})" v-model="qt_detailCostPerHour" min="0"  :disabled="!canUpdateSupplierSettings"/></div>
                                <div class="settingDescription"> / hr</div>                        
                            </div>
                        </div>
                        <div class="settingsGroupBody">
                            <div class="setting">
                                <div class="" style="display: inline;">Bolt Purlin Pieces<StatusIndicator ref="si_boltPurlinPcsPerDay"></StatusIndicator></div>
                                <div class="settingValue num"><input type="number" class="digit3" @input="dbBusinessSettings({id:'qt_boltPurlinPcsPerDay',val:qt_boltPurlinPcsPerDay})" v-model="qt_boltPurlinPcsPerDay" min="0"  :disabled="!canUpdateSupplierSettings"/></div>
                                <div class="settingDescription"> pcs / day</div>                        
                            </div>
                        </div>
                        <div class="settingsGroupBody">
                            <div class="setting">
                                <div class="" style="display: inline;">Bolt Beam Pieces<StatusIndicator ref="si_boltBeamPcsPerDay"></StatusIndicator></div>
                                <div class="settingValue num"><input type="number" class="digit3" @input="dbBusinessSettings({id:'qt_boltBeamPcsPerDay',val:qt_boltBeamPcsPerDay})" v-model="qt_boltBeamPcsPerDay" min="0"  :disabled="!canUpdateSupplierSettings"/></div>
                                <div class="settingDescription"> pcs / day</div>                        
                            </div>
                        </div>
                    </div>
                    <div class="settingsGroup" v-if="section == sections.beams">
                        <h1 class="h1">Beams</h1>
                        <div class="settingDescription"> * These are factors used to calculate galvanized material cost relative to standard material</div>     
                        <div class="settingsGroupBody">
                            <div class="setting">
                                <div class="" style="display: inline;">Galvanized Beam Cost Factor (cost per Lb)<StatusIndicator ref="si_beamGalvCostPerLb"></StatusIndicator></div>
                                <div class="settingValue num"><input type="number" class="digit3" @input="dbBusinessSettings({id:'qt_beamGalvCostPerLb',val:qt_beamGalvCostPerLb})" v-model="qt_beamGalvCostPerLb" min="0"  :disabled="!canUpdateSupplierSettings"/></div>
                                <div class="settingDescription"></div>                        
                            </div>
                        </div>
                        <div class="settingsGroupBody">
                            <div class="setting">
                                <div class="" style="display: inline;">Galvanized Beam Sell Factor (sell per Lb)<StatusIndicator ref="si_beamGalvSellPerLb"></StatusIndicator></div>
                                <div class="settingValue num"><input type="number" class="digit3" @input="dbBusinessSettings({id:'qt_beamGalvSellPerLb',val:qt_beamGalvSellPerLb})" v-model="qt_beamGalvSellPerLb" min="0"  :disabled="!canUpdateSupplierSettings"/></div>
                                <div class="settingDescription"></div>                        
                            </div>
                        </div>
                        <div class="settingsGroupBody">
                            <div class="setting">
                                <div class="" style="display: inline;">Galvanized Purlin Cost Factor<StatusIndicator ref="si_purlinGalvAdd"></StatusIndicator></div>
                                <div class="settingValue num"><input type="number" class="digit3" @input="dbBusinessSettings({id:'qt_purlinGalvAdd',val:qt_purlinGalvAdd})" v-model="qt_purlinGalvAdd" min="0"  :disabled="!canUpdateSupplierSettings"/></div>
                                <div class="settingDescription"></div>                        
                            </div>
                        </div>
                    </div>
                    <div class="settingsGroup" v-if="section == sections.fasteners">
                        <h1 class="h1">Fasteners</h1>

                    
                        <div v-if="section == sections.fasteners">
                            <div class="settingsGroupBody">
                                <div class="setting">
                                    <div class="" style="display: inline;">Long Life Cost<StatusIndicator ref="si_fastenerLongLifeCost"></StatusIndicator></div>
                                    <div class="settingValue num"><input type="number" class="digit3" @input="dbBusinessSettings({id:'qt_fastenerLongLifeCost',val:qt_fastenerLongLifeCost})" v-model="qt_fastenerLongLifeCost" min="0"  :disabled="!canUpdateSupplierSettings"/></div>
                                    <div class="settingDescription"> dollars</div>                        
                                </div>
                            </div>
                            <!-- <div class="settingsGroupBody"> turns out this is a calculated field and not a user facing input field
                                <div class="setting">
                                    <div class="" style="display: inline;">Long Life Multiplier<StatusIndicator ref="si_fastenerLongLifeMult"></StatusIndicator></div>
                                    <div class="settingValue num"><input type="number" class="digit3" @input="dbBusinessSettings({id:'qt_FastenerLongLifeMult',val:qt_FastenerLongLifeMult})" v-model="qt_FastenerLongLifeMult" min="0"  :disabled="!canUpdateSupplierSettings"/></div>
                                    <div class="settingDescription"> dollars</div>                        
                                </div>
                            </div> -->
                        </div>
                        
                        <div class="settingsGroupBody">
                            <div class="setting">
                                <div class="" style="display: inline;">Roof Field Screws<StatusIndicator ref="si_roofFieldScrewsPerSq"></StatusIndicator></div>
                                <div class="settingValue num"><input type="number" class="digit3" @input="dbBusinessSettings({id:'qt_roofFieldScrewsPerSq',val:qt_roofFieldScrewsPerSq})" v-model="qt_roofFieldScrewsPerSq" min="0"  :disabled="!canUpdateSupplierSettings && !canUpdateContractorSettings"/></div>
                                <div class="settingDescription"> / Sq</div>                        
                            </div>
                        </div>
                        <div class="settingsGroupBody">
                            <div class="setting">
                                <div class="" style="display: inline;">Roof Lap Screws<StatusIndicator ref="si_roofLapScrewsPerSq"></StatusIndicator></div>
                                <div class="settingValue num"><input type="number" class="digit3" @input="dbBusinessSettings({id:'qt_roofLapScrewsPerSq',val:qt_roofLapScrewsPerSq})" v-model="qt_roofLapScrewsPerSq" min="0"  :disabled="!canUpdateSupplierSettings && !canUpdateContractorSettings"/></div>
                                <div class="settingDescription"> / Sq</div>                        
                            </div>
                        </div>
                        <div class="settingsGroupBody">
                            <div class="setting">
                                <div class="" style="display: inline;">Wall Field Screws<StatusIndicator ref="si_wallFieldScrewsPerSq"></StatusIndicator></div>
                                <div class="settingValue num"><input type="number" class="digit3" @input="dbBusinessSettings({id:'qt_wallFieldScrewsPerSq',val:qt_wallFieldScrewsPerSq})" v-model="qt_wallFieldScrewsPerSq" min="0"  :disabled="!canUpdateSupplierSettings && !canUpdateContractorSettings"/></div>
                                <div class="settingDescription"> / Sq</div>                        
                            </div>
                        </div>
                        <div class="settingsGroupBody">
                            <div class="setting">
                                <div class="" style="display: inline;">Wall Lap Screws<StatusIndicator ref="si_roofLapScrewsPerSq"></StatusIndicator></div>
                                <div class="settingValue num"><input type="number" class="digit3" @input="dbBusinessSettings({id:'qt_roofLapScrewsPerSq',val:qt_roofLapScrewsPerSq})" v-model="qt_roofLapScrewsPerSq" min="0"  :disabled="!canUpdateSupplierSettings && !canUpdateContractorSettings"/></div>
                                <div class="settingDescription"> / Sq</div>                        
                            </div>
                        </div>
                </div> <!-- end settings group -->
            </template>

        </div>

    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  import CategoryMarkup from '@/components/CategoryMarkup.vue';
  import ContractorPricing from '@/components/ContractorPricing.vue'
  import userSettingCheckbox from '@/components/user-setting-checkbox.vue';
  import userSettingDropdown from '@/components/user-setting-dropdown.vue';
  import swmbox  from '@/components/swmbox.vue';
  import StatusIndicator from '@/components/StatusIndicator.vue';
  import { mapState, mapGetters , mapActions, mapMutations } from 'vuex';
  import ContextLogic from '@/lib/contextLogic';
  import Util from '@/lib/utility';
  import api from '@/api';
  import Sidebar2 from '@/components/Sidebar2.vue';
  import Sidebar2Link from '@/components/Sidebar2Link.vue';
  import ItemManagerView from './ItemManagerView.vue';
  import Sidebar2Section from '@/components/Sidebar2Section.vue';
  import VModal from 'vue-js-modal'
  import Vue from 'vue'
  import Vuex from 'vuex'

Vue.use(Vuex)
Vue.use(VModal, {dialog:true});
  export default {
    components: {
        CategoryMarkup,
        userSettingCheckbox,
        userSettingDropdown,
        swmbox,
        StatusIndicator,
        Sidebar2,
        Sidebar2Link,
        ItemManagerView,
        Sidebar2Section,
        ContractorPricing,
    },
    data() {
        return {
            debug: false,            
            sections:{
                pricingLevels: 'pricingLevels',
                pricingModel: 'pricingModel',
                logistics: 'logistics',                
                fabrication: 'fabrication',
                beams: 'beams',
                fasteners: 'fasteners',
            },
            section:'',
            testMarkups: [
                {"category": "taper frame", "markup": 0.1 },
                { "category": "beam", "markup": 0.15 },
                { "category": "tube", "markup": 0.12 },
                { "category": "weld plates", "markup": 0.08 },
                { "category": "eavestrut", "markup": 0.1 },
                { "category": "purlin", "markup": 0.12 },
                { "category": "clips", "markup": 0.05 },
                { "category": "insulation", "markup": 0.1 },
                { "category": "panels", "markup": 0.15 },
                { "category": "trim", "markup": 0.09 },
                { "category": "fasteners", "markup": 0.04 },
                { "category": "accessories", "markup": 0.06 }
            ],
            boltUpScheme: {},
            weldUpScheme: {},
            categoryMarkupKind: {
                Bolt_Up: 0,
                Weld_Up: 1,
            }, 
            statusRefs: {   // settingName : ref
            "qt_directFabCostPerDay": "si_directFabCostPerDay",
            "qt_boltPurlinPcsPerDay": "qt_boltPurlinPcsPerDay",
            "qt_boltBeamPcsPerDay": "qt_boltBeamPcsPerDay",
            "qt_detailCostPerHour": "qt_detailCostPerHour",
            "qt_fabLaborSellPerLb": "si_fabLaborSellPerLb",
            "qt_beamGalvCostPerLb": "si_beamGalvCostPerLb",
            "qt_beamGalvSellPerLb": "si_beamGalvCostPerLb",
            "qt_purlinGalvAdd": "si_purlinGalvAdd",
            "qt_fastenerLongLifeCost": "si_fastenerLongLifeCost",
            "qt_FastenerLongLifeMult": "si_fastenerLongLifeMult",
            "qt_roofFieldScrewsPerSq": "si_roofFieldScrewsPerSq",
            "qt_roofLapScrewsPerSq": "si_roofLapScrewsPerSq",
            "qt_wallFieldScrewsPerSq": "si_wallFieldScrewsPerSq",
            "qt_wallLapScrewsPerSq": "si_wallLapScrewsPerSq",

            "qt_ninjaFee": "si_ninjaFee",
            "qt_redOxide": "si_redOxide",
            "qt_zipHome": "si_zipHome",
            "qt_maxLocalDelivery": "si_maxLocalDelivery",
            "qt_defaultSalesTaxRate": "si_defaultSalesTaxRate",
            "qt_truckMinCost": "si_truckMinCost",
            "qt_mileageRateCompanyTruck": "si_mileageRateCompanyTruck",
            "qt_mileageRateOutsideTruck": "si_mileageRateOutsideTruck",

            "qt_tfCostLb": "si_tfCostLb",
            "qt_beamCostLb": "si_beamCostLb",
            "qt_tubeCostLb": "si_tubeCostLb",
            "qt_eavestrutCostLb": "si_eavestrutCostLb",
            "qt_purlinCostLb": "si_purlinCostLb",
            "qt_panelCostGalvUnit": "si_panelCostGalvUnit",
            "qt_panelCostSmpUnit": "si_panelCostSmpUnit",
            "qt_panelCostKynarUnit": "si_panelCostKynarUnit",
            "qt_trimCostUnit": "si_trimCostUnit"
          },

          classes: {
            container: "settingsGroup",
            header: "settingsGroupHeader",
            body: "settingsGroupBody",
            setting: "setting",
            value: "settingValue",
            description: "settingDescription"
          },
          isAuthenticated: false,
          showSupplierOnlySetting: false,
          canUpdateBusinessInfo: false,
          canUpdateSupplierSettings: false,
          canUpdateContractorSettings: false,

          qt_directFabCostPerDay: 650,
          qt_boltPurlinPcsPerDay: 50,
          qt_boltBeamPcsPerDay: 20,
          qt_detailCostPerHour: 70,
          qt_fabLaborSellPerLb: 1.02,
          qt_beamGalvCostPerLb: 0.50,
          qt_beamGalvSellPerLb: 0.60,
          qt_purlinGalvAdd: 1.20,
          qt_fastenerLongLifeCost: 0.12,
          qt_FastenerLongLifeMult: 2.23,
          qt_roofFieldScrewsPerSq: 40,
          qt_roofLapScrewsPerSq: 20,
          qt_wallFieldScrewsPerSq: 40,
          qt_wallLapScrewsPerSq: 30,
        
          qt_ninjaFee: "Add to subtotal",
          qt_redOxide: "1",
          qt_truckMinCost: 300,
            qt_mileageRateCompanyTruck: 3,
            qt_mileageRateOutsideTruck: 5,
            qt_zipHome: "",
            qt_maxLocalDelivery: 200,
            qt_defaultSalesTaxRate: "",

        qt_tfCostLb: 1.600,         
        qt_beamCostLb: 0.638,  
        qt_tubeCostLb: 1.084,   
        qt_eavestrutCostLb: 0.880,   
        qt_purlinCostLb: 0.850,    
        qt_panelCostGalvUnit: 1.800, 
        qt_panelCostSmpUnit: 2.300,  
        qt_panelCostKynarUnit: 2.800,
        qt_trimCostUnit: 2.450, 

          
      }
    },
    computed:{
      ...mapState('contextModule',[
        'user'
        ,'userBusiness'
        ,'current'
      ]),
      ...mapGetters('contextModule',[
        'userIsSysAdmin'
        ,'userIsSupAdmin'
        ,'userIsContAdmin'
      ]),
      
      mode(){
        return this.current.mode
      },
        
      itemManagerRoutePath(){
        let pathParts = this.$route.path.split("/");
        let businessId = pathParts[2];
        return `/${pathParts[1]}/${businessId}/itemManager`
      }

    },
    methods: {
        ...mapActions('pricingModule', ['fetchPricingLevels', 'updatePricingLevels']),
        ...mapActions('businessModule', ['getBusinessSettings','getBusinessSetting', 'updateBusinessInfo', 'fetchBusiness', 'updateBusinessInfo']),
        show(name){
            this.section = name;
        },
        async updateBusiness(settingName) {
            let si = this.$refs[this.statusRefs[settingName]];
            if(si != undefined)
                si.loading();

            let response = await this.updateBusinessInfo(this.entity);

            if(response){
                si.fail();
            }
            else{
                si.success();
            }
        },
        async setBusinessSetting(data){
             ;
            var val;
            // if the object has a hex property, it's a color setting, 
            if (data.val.hex)
                val = data.val.id; // use color.id for colors (changed week of 2023.11.07)
            else{
                val = `${data.val}`
            }
                
            let settingsGroup = {
                businessId: this.entity.id,
                name: data.id,
                value: val
            }
            let si = this.$refs[this.statusRefs[data.id]];
            if(si != undefined)
                si.loading();

            let response = await api.setBusinessSetting(settingsGroup);

            if(response.isError){
                si.fail();
            }
            else{
                si.success();
            }
        },        
        defaultAllSettings() {
            // this.qt_defaultMaxPurlinSpacing = 5.1;
            // this.qt_defaultMaxGirtSpaceFt = 5;
            // this.qt_defaultWallGirtCondition = "Flush";
            // this.qt_defaultRoofPurlinCondition = "Bypass";
            // this.qt_peakSpaceSelection = 1;
            this.qt_ninjaFee= "Add to subtotal";
            this.qt_redOxide= "1";
            this.qt_truckMinCost = 300;
            this.qt_mileageRateCompanyTruck = 3;
            this.qt_mileageRateOutsideTruck = 5;
            this.qt_zipHome = "";
            this.qt_maxLocalDelivery = 200;
            this.qt_defaultSalesTaxRate = "";
            this.qt_directFabCostPerDay = 650;
            this.qt_boltPurlinPcsPerDay = 50;
            this.qt_boltBeamPcsPerDay = 20;
            this.qt_detailCostPerHour = 70;
            this.qt_fabLaborSellPerLb = 1.02;
            this.qt_beamGalvCostPerLb = 0.50;
            this.qt_beamGalvSellPerLb = 0.60;
            this.qt_purlinGalvAdd = 1.20;
            this.qt_fastenerLongLifeCost = 0.12;
            this.qt_FastenerLongLifeMult = 2.23;
            this.qt_roofFieldScrewsPerSq = 40;
            this.qt_roofLapScrewsPerSq = 20;
            this.qt_wallFieldScrewsPerSq = 40;
            this.qt_wallLapScrewsPerSq = 30;
            this.qt_tackyTapeAll = false;
            this.des_caulkColor = "Gutter";
            this.des_touchUpPaint = true;
            this.des_wainscotTrimColor = "Wall";
            this.qt_defaultRoofPanel = "PBR26C";
            this.qt_defaultWallPanel = "PBR26C";
            this.qt_defaultSoffitPanel = "RBU26C";
            this.des_angleColor = "Trim_Color";
            this.des_cornerColor = "Trim_Color";
            this.des_downspoutColor = "Trim_Color";
            this.des_dripColor = "Wall_Panel_Color";
            this.des_eaveColor = "Trim_Color";
            this.des_gutterColor = "Trim_Color";
            this.des_headColor = "Trim_Color";
            this.des_jambColor = "Trim_Color";
            this.des_jambLinerColor = "Trim_Color";
            this.des_partitionColor = "Wall_Panel_Color";
            this.des_rakeColor = "Trim_Color";
            this.des_ridgeColor = "Roof_Panel_Color";
            this.des_transitionColor = "Roof_Panel_Color";
            this.des_wainscotColor = "Wainscot_PanelColor";
            this.des_valleyColor = "Roof_Panel_Color",
            
            this.qt_trimRakeProfile = "RA-Com";
            this.qt_trimRidgeProfile = "RC10-s";
            this.qt_trimGutterProfile = "GU-Com";
            this.qt_trimDownspoutProfile = "DS-k";
            this.qt_trimInsideCornerProfile = "IC";
            this.qt_trimOutsideCornerProfile = "OU";
            this.qt_trimHeadProfile = "RHE";
            this.qt_trimJambProfile = "RHE";
            this.qt_trimDripRatProfile = "Base";
            this.qt_trimEaveProfile = "LEA";
          //   this.qt_trimCostLb = 7;
          //   this.qt_kynarTrimFactor = 0.42;

            this.qt_tfCostLb = 1.600,         
            this.qt_beamCostLb = 0.638,  
            this.qt_tubeCostLb = 1.084,   
            this.qt_eavestrutCostLb = 0.880,   
            this.qt_purlinCostLb = 0.850,    
            this.qt_panelCostGalvUnit = 1.800, 
            this.qt_panelCostSmpUnit = 2.300,  
            this.qt_panelCostKynarUnit = 2.800,
            this.qt_trimCostUnit = 2.450, 


            this.logo_Sml = '';
            this.logo_Lrg = '';
            this.des_gutter = true;
            this.des_wainscotHeight = 42;
            this.des_tubePorchColTrim = true;
            this.des_deadboltPrep = true;
            this.des_JambCounterFlashing = false;
            this.des_ohJambLiner = true;
            this.des_tackyTapeRoof = false;
            this.des_plusGirtsCutToLength = false;
            this.pro_prices = false;
            this.des_insulationRoof = 'VR.3_(R_10)';
            this.des_insulationWall = 'VR.3_(R_10)';
            this.des_windowStyle = 'doubleHung';
            this.des_levelOfDetail = 'low';
            this.des_peakSpace = 12
            this.showProposed = false
            this.showPrices = false
            this.des_marginOhdSide = 4
            this.des_marginOhdTop = 15
            this.des_marginWindowEdge = 9
            this.des_colorRoof = {
                code: "HG",
                hex: "294922",
                id: "90000-1106157308",
                name: "Hunter Green",
                number: 4869956
            }
            this.des_colorWall = {
                code: "LS",
                hex: "D0CBA8",
                id: "D0000-1106158270",
                name: "Lt Stone",
                number: 4869956
            }
            this.des_colorWainscoting = {
                code: "HG",
                hex: "294922",
                id: "90000-1106157308",
                name: "Hunter Green",
                number: 4869956
            }
            this.des_colorTrim = {
                code: "HG",
                hex: "294922",
                id: "90000-1106157308",
                name: "Hunter Green",
                number: 4869956
            }

            this.des_taxes= 'Yes - City';
          },
          async loadSettings(){
              let pathParts = this.$route.path.split("/");
              let businessId = pathParts[2];

              this.defaultAllSettings();
              let response = await this.getBusinessSettings(businessId);
              if(response.isError){
                  this.$toast.error(response.data.msg);
              }
              else{
                  let settings = response;
                  this.initSettings(settings);
              }

          },
          initSettings(settings) {
            // assign the data values
            settings.forEach((settingsGroup) => {
                this.initSetting(settingsGroup);
            })               
            
            // assigning the logo value won't set the image, so apply the base64 data to the img src

          },
          initSetting(setting) {
              if (setting.value === "true" || setting.value === "false")
                  setting.value = setting.value === 'true';

              // if (setting.name === "des_colorRoof" || setting.name === "des_colorWall" || 
              //     setting.name === "des_colorWainscoting" || setting.name === "des_colorTrim") {
              //     this.colorPalette.forEach(c => {
              //         // match the itemID in the setting value to the itemID of a color in the color palette
              //         if (c.id === setting.value) {
              //             // use that color
              //             setting.value = c;
              //         }
              //     });
              // }
              if (typeof setting.value !== 'undefined') {
                  this[setting.name] = setting.value;
              }
          },
          
    },
    async created() {
        this.debug = false;
        let pathParts = this.$route.path.split("/");
        let businessId = pathParts[2];

        this.dbBusinessSettings = Util.debounce(this.setBusinessSetting,1000);

        this.loadSettings();

        await ContextLogic.initContext(this);
        this.isAuthenticated = this.$auth.isAuthenticated;
        let isContractorAdmin = this.userIsContAdmin;
        let isSupplierAdmin = this.userIsSupAdmin;
        let isSystemAdmin = this.userIsSysAdmin;
        console.log(`contractor admin: ${isContractorAdmin} || supplier admin: ${isSupplierAdmin} || system admin: ${isSystemAdmin}`)
        this.showSupplierOnlySetting = this.mode == 1;
        this.canUpdateSupplierSettings = isSupplierAdmin || isSystemAdmin;
        this.canUpdateContractorSettings = isContractorAdmin || isSystemAdmin;
        this.canUpdateBusinessInfo = this.canUpdateSupplierSettings || this.canUpdateContractorSettings;
        console.log(`can update contractor settings: ${this.canUpdateContractorSettings}`)
        console.log(`can update supplier settings: ${this.canUpdateSupplierSettings}`)
        console.log(`can update entity info: ${this.canUpdateBusinessInfo}`)

        this.entity = await this.fetchBusiness(businessId);
        this.section = this.$route.query.section       
    },
    async mounted(){

    }


  }
  </script>
  
  <style scoped>
  .schemes-container{
    display:flex;
    flex-wrap: wrap;
  }
  .markup-configuration {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .schemes-container {
    display: flex;
    justify-content: center;
    gap: 65px;
    width: fit-content;
  }
  
  @media (max-width: 768px) {
    .schemes-container {
      flex-direction: column;
    }
  }

  @media (max-width: 4924px) {
       .settingsGroup {
           font-size: 1.3em;
       }
   }
   @media (max-width: 1280px) {
       .settingsGroup {
           font-size: 1.2em;
       }
   }
   @media (max-width: 1024px) {
       .settingsGroup {
           font-size: 1.1em;
       }
   }
   @media (max-width: 768px) {
       .settingsGroup {
           font-size: 1.0em;
       }
   }
   @media (max-width: 640px) {
       .settingsGroup {
           font-size: 1.0em;
       }
   }
   .settingGroupList {
       width: 90%;
       margin: auto;
   }

   .settingsContainer {
       display: flex;
       flex-flow: row wrap;
       justify-content: space-evenly;
       align-items: flex-start;
   }

   .settingsItem {
       display: block;
       margin: 5px;
       padding: 5px;
       margin-left: 20px;
       border: 1px solid lightgrey;        
       width:100%;
       text-align: left;
   }


   /* parent  */
   
   .settingsContainer {
       flex-grow: 2;
   }


   .settingsGroupList {
       display: flex;
       flex-flow: row wrap;

   }

   /* item */
   .settingsGroup {
       display: block;
       padding: 2px;       
   }

   .settingsGroup .settingsGroupBody {
       display: block;
       flex-grow: 1;
       color: black;
       flex-shrink: 2;
       text-align: left;       
   }

   .flex {
       display: flex;
       flex-flow: row wrap;
   }

   .settingsGroup .settingsGroupHeader {
       display: block;
       font-weight: bold;
       
       background: lightgray;
       padding: 10px;
       text-align: left;
   }

   .setting {
       display: inline-block;
       margin: 10px;
       vertical-align: top;
   }

   .settingsGroup .settingDescription {
       display: inline-block;
       margin: 3px 0 0 5px;
       font-size: .8em;
       vertical-align: top;
       
   }

   .settingsGroup .settingValue {
       flex-grow: 1;
       text-align: left;
       display: inline-block;
       margin-left: 10px;
       
   }        


   .settingValue input {       
       flex-shrink: 1;
   }

   .settingValue .designLogoSml {
       margin: 3px;
       display: block;
       text-align: left
   }

   .settingValue .designLogoSml img {
       margin: 3px;
       max-width: 150px;
       box-shadow: 0px 0px 2px black;
       display: inline-block;
       text-align: center;
       margin-left: auto;
       margin-right: auto;
       background: black
   }

   .settingValue .designLogoLrg img {
       margin: 3px;
       max-width: 480px;
       box-shadow: 0px 0px 2px black;
       display: inline-block;
       text-align: center;
       margin-left: auto;
       margin-right: auto;
   }

   .hidden {
       display: none;
   }

   .logoTypeLabel {
       width: 120px;
       display: block;
       font-size: .7em;
   }

   .horsep {
       border-top: 1px solid lightgray;
       display:inline-block;
       margin:auto;
       height:10px;
       width:95%;
       text-align:center;
   }

   .digit2 {
       width: 51px;
   }
   .digit3 {
       width: 76px;
   }

   input[type=checkbox] {
       transform: scale(1.3);
       margin-right: 10px;     
       margin-bottom: 3px;
   }

   .logo-input{
        display: inline-flex;
        margin: 20px 50px;
    }

    .logo-lrg {
        width: 300px;
        
    }

    .logo-sml {
      width: 80px;
      border-radius: 15px;
      /* background-color: #f0f1f5; */
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      
      img {
        max-width: 100%;
        /* border-radius: 50%; */
      }
    }

    .section-group-title{
        margin: 15px 10px;
        text-align: left;
        background: rgb(231, 231, 231)
    }

    input.w-s{
        width:100px;
    }
    input.w-m{
        width:350px;
    }
    input.w-l{
        width:500px;
    }




  </style>