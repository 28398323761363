<template>
  <div>

    <!-- button & search -->
    <div class="grid gap-5" style="--grid-template-columns-sm: auto 1fr;">
      <div>
        <button type="button" class="btn" @click="createNewProject">
          <Icon name="add-1" custom-class="icon icon-size-1" />          
          <span>Create New Project</span>
        </button>
      </div>
      <div>
        <label class="sr-only" for="projectSearch">Composite Field (Icon)</label>
        <label class="composite-field no-dividers darker" style="--this-border-radius: var(--br-full);">
          <div class="text">
            <Icon name="magnifying-glass" custom-class="icon icon-size-1 mar-is-4 shift-be-2" style="margin-inline-end: calc(var(--sp-1) * -1);" />
          </div>
          
          <input id="projectSearch" v-model="searchQuery" class="darker"  type="text" name="input" placeholder="Search Projects">
        </label>
      </div>
    </div>    

    <!-- heading & filter -->
    <div class="grid gap-5 mar-bs-7" style="--grid-template-columns-sm: auto 1fr; align-items: start;">
      <div>
        <h1 class="h1">Projects ({{ filteredAndSortedProjects.length }})</h1>
      </div>
      <div>
        <!-- {{ user }}
        <br />
        Admin: {{ userIsSysAdmin }}
        <br/>
        Lvl1 Filter:           {{ showFilter_Lvl1Entities }}
        <br/>
        Lvl2 Filter:           {{ showFilter_Lvl2Entities }} -->
        <div class="flex gap-5" style="justify-content: flex-end;">
          

          <!-- only super admins see this -->        
          <div v-if="showFilter_Lvl1Entities">
            <label class="sr-only" for="filter-supplier">Suppliers</label>
            <span class="select">              
              <select v-model="filter.values.lvl1EntityId" class="darker size-small" name="select" id="filter-supplier">
                <option :value="null">All Suppliers</option>
                <option v-for="supplier in filter.options.lvl1Entities" :key="supplier.id" :value="supplier.id">{{ supplier.businessName }}</option>
              </select>
            </span>
          </div>

          
          <!-- suppliers and super admins see this -->
          <div v-if="showFilter_Lvl2Entities">
            <label class="sr-only" for="filter-contractor">Contractors</label>
            <span class="select">
              <select v-model="filter.values.lvl2EntityId" class="darker size-small" name="select" id="filter-contractor">
                <option :value="null">All Contractors</option>
                <option v-for="contractor in filter.options.lvl2Entities" :key="contractor.id" :value="contractor.id">{{ contractor.businessName }}</option>
              </select>
            </span>
          </div>
          <div>
            <label class="sr-only" for="filter-status">Project Status</label>
            <span class="select">
              <select v-model="archiveFilter" class="darker size-small" name="select" id="filter-status">
                <option value="active">Active Projects</option>
                <option value="archived">Archived Projects</option>
                <option value="all">All Projects</option>
              </select>
            </span>
          </div>
          <!-- other filter selects could go here... -->
        </div>
      </div>
    </div>

    <!-- table -->
    <!-- adjust min-block-size so that table row popover menu is not cut off -->
    <div class="table-wrap mar-bs-5">

      <table class="table-1 lines-b last-column-align-end" style="--cell-pad-b: var(--sp-3); --cell-pad-is: var(--sp-5);" >                            
        <colgroup>
          <col style="width:auto;">
          <col style="width:auto;">
          <col style="width:auto;">
          <col style="width:auto;">
          <col style="width:auto;">
          <col style="width:auto;">
          <col style="width:5rem;">
        </colgroup>                            
        <thead>
          <tr>
            <th>Preview</th>
            <th @click="updateSort('name')">Name</th>
            <th @click="updateSort('price')"  class="align-end">Price</th>
            <th @click="updateSort('weight')" class="align-end">Weight</th>
            <th @click="updateSort('state')">Status</th>
            <th @click="updateSort('lastModified')">Last Modified</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody v-if="loading">
          <tr>
            <td colspan="10" class="spinner"><ProgressSpinner /></td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr v-for="project in filteredAndSortedProjects" :key="project.id" class="hover-bg-shaded-2" style="position: relative;">
            <td style="position: static;">
              <div class="overlay" style="z-index: 0;">
                <button class="size-100 no-outline-offset" @click="goToProjectDetail(project.reference)">
                  <span class="sr-only">View Project</span>
                </button>
              </div>
              <span class="img-wrap" style="aspect-ratio: 3/2; border-radius: var(--br-2); --inline-size-xs: 4rem; --inline-size-md: 6rem;">
                <img v-if="project.image" :src="project.image" style="height:90px" alt="placeholder image" >
                <img v-else src='@/assets/images/SteelNinja_horizontal_emblem.png' style="height:90px" alt="preview image" >
              </span>
            </td>
            <td @click="goToProjectDetail(project.reference)"><strong class="strong" >{{project.name}}</strong></td>
            <td @click="goToProjectDetail(project.reference)"class="align-end"><span v-if="project.outdatedPricing" title="'Project price outdated. Please requote'">⚠️</span>{{ formattedPrice(project.price) }}</td>
            <td @click="goToProjectDetail(project.reference)"class="align-end">{{ formattedWeight(project.weight) }}</td>
            <td @click="goToProjectDetail(project.reference)">{{ dictionary.getTerm(project.state)}}</td>
            <td @click="goToProjectDetail(project.reference)" >{{ formatDate(project.modified) }}</td>
            <td class="pad-ie-3" >
              <SnuiWrapper>
              <div class="popover1-anchor" >
                
                  <button class="pad-4 hover-bg-shaded inline-block" style="position: relative; z-index: 1; border-radius: var(--br-2);" data-popover1-trigger="toggle" :aria-controls="'actions-'+project.reference" aria-expanded="false">
                    <span class="sr-only">Actions Menu</span>
                    <Icon name="vertical-menu-square" custom-class="icon-size-1" />
                  </button>                  
                  <!-- popover menu -->
                  <div :id="'actions-'+project.reference" class="popover1 attach-outside-is align-bs click-outside-close click-outside-close-only" data-popover1-state="closed" role="dialog" :aria-labelledby="'actions-title-'+project.reference">
                    <div class="mar-be-3 color-context-neutral-dark" style="inline-size: max-content; border-radius: var(--br-3); border-color: var(--color-fg-normal); overflow: hidden;">
                      <h2 class="sr-only" :id="'actions-title-'+project.reference">User Actions</h2>
                      <div class="grid mar-b-2" style="font-size: var(--fs-default);">
                        <button class="block pad-b-2 pad-i-4 hover-bg-shaded" data-popover1-trigger="close" :aria-controls="'actions-'+project.reference" aria-expanded="false" @click="editDesign(project.reference)">Edit Design</button>
                        <button class="block pad-b-2 pad-i-4 hover-bg-shaded" data-popover1-trigger="close" :aria-controls="'actions-'+project.reference" aria-expanded="false" :disabled="notDesigned(project)">Open Project Folder</button>
                        <button class="block pad-b-2 pad-i-4 hover-bg-shaded" data-popover1-trigger="close" :aria-controls="'actions-'+project.reference" aria-expanded="false" @click="shareProject(project.reference)" :disabled="notDesigned(project)">Share</button>                      
                        <button class="block pad-b-2 pad-i-4 hover-bg-shaded" data-popover1-trigger="close" :aria-controls="'actions-'+project.reference" aria-expanded="false" v-if="project.state == 1" @click="requestHardQuote(project.reference)">Request Hard Quotes</button>
                        <button class="block pad-b-2 pad-i-4 hover-bg-shaded" data-popover1-trigger="close" :aria-controls="'actions-'+project.reference" aria-expanded="false" @click="copyProject(project.reference)">Copy</button>
                        <button class="block pad-b-2 pad-i-4 hover-bg-shaded" data-popover1-trigger="close" :aria-controls="'actions-'+project.reference" aria-expanded="false" @click="deleteQuote(project)">Delete</button>
                        <button class="block pad-b-2 pad-i-4 hover-bg-shaded" data-popover1-trigger="close" :aria-controls="'actions-'+project.reference" aria-expanded="false" @click="archiveProject(project.reference)">Archive</button>
                      </div>
                    </div>
                  </div>

              </div>
            </SnuiWrapper>

            </td>
          </tr>
        </tbody>
      </table>
      <!-- popover spacer: size so popover on last row has room inside of .table-wrap without causing scrolling. -->
      <div style="block-size: 10rem;"><!-- spacer --></div>
    </div>				
    <!-- <QuoteAdjustmentModal :qaData.sync="qaData"></QuoteAdjustmentModal> -->
    <ProjectCopyModal @afterCopy="updateProjectList" :copyData.sync ="copyData"></ProjectCopyModal>
    
  </div>
</template>

<script>
import { CORE } from '@/constants';
import ContextLogic from '@/lib/contextLogic';

import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import VModal from 'vue-js-modal'
import Vue from 'vue'
import Vuex from 'vuex'
import QuoteAdjustmentModal from '@/components/QuoteAdjustmentModal.vue';
import ProjectCopyModal from '@/components/ProjectCopyModal.vue';
import ProgressSpinner from '@/components/ProgressSpinner.vue';
// import Toast from "vue-toastification";
// import "vue-toastification/dist/index.css";
import Dictionary from '../Dictionary.js'
import api from '@/api.js';
import Util from '@/lib/utility.js';
import SnuiWrapper from '@/components/SnuiWrapper.vue';
Vue.use(Vuex)
Vue.use(VModal, {dialog:true});

// const toastOptions = {
//   position: "bottom-center",
//   timeout: 3000,
//   closeOnClick: false,
//   pauseOnFocusLoss: true,
//   draggable: false,
//   maxToasts: 2,

// };
// Vue.use(Toast, toastOptions);

export default {
  components: {
    QuoteAdjustmentModal,
    ProgressSpinner,
    ProjectCopyModal,
    SnuiWrapper


  },
  data() {
    return {
      dictionary: null,
      loading:true,
      searchQuery: '',
      sortBy: 'lastModified',
      sortOrder: 'desc',
      archiveFilter: 'active',
      filter:{
        options:{
          //archive
          lvl1Entities:[],
          lvl2Entities:[],          
        },
        values:{
          lvl1EntityId: null,
          lvl2EntityId: null,
          archived: false
        }
      },
      projects: [],
      qaData: {
        ref: null,
        show: false
      },
      copyData:{
        ref: null,
        show: false,
      },
      isAuthenticated: false,
      isSouthWest: false,
      input: {
        disableSave: false,
        error:{
          show: false,
          msg: ""
        }
      },
      saveInProgress: false,

    }
  },
  watch:{    
  },
  computed: {
    ...mapState('contextModule', ['user', 'userBusiness', 'current','']),
    ...mapGetters('contextModule', ['userIsSysAdmin']),
    
    showFilter_Lvl1Entities(){
      return this.userIsSysAdmin;
    },
    showFilter_Lvl2Entities(){
      return  this.userIsSysAdmin || (this.userBusiness && this.userBusiness.type==1);
    },    
    filteredAndSortedProjects() {
      if(!this.projects)
        return []
      
      /*  IMPORTANT: Reference to state variables so Vue is automatically reactive */
      this.filter.values.lvl1EntityId // reference to state variables so Vue is automatically reactive
      this.filter.values.lvl2EntityId // reference to state variables so Vue is automatically reactive


      var filteredProjects = this.projects.filter(project => this.projectPassesFilter(project));

      // Step 2. SORT
      return filteredProjects.sort((a, b) => {
        const aName = a.name.trim().toLowerCase();
        const bName = b.name.trim().toLowerCase();
        const modifier = this.sortOrder === 'asc' ? 1 : -1;
        switch (this.sortBy) {
          case 'name':
            // Convert both strings to lowercase for case-insensitive comparison
            return modifier * aName.localeCompare(bName);
          case 'price':
            return modifier * (a.price - b.price);
          case 'weight':
            return modifier * (a.weight - b.weight);
          case 'lastModified':
            return modifier == 1 ? (new Date(a.modified) - new Date(b.modified)) : (new Date(b.modified) - new Date(a.modified));
          case 'status':
            return modifier * (a.state - b.state);
          default:
            return 0;
        }
      });
    },
    projectFileURI(){
      let projectDirectory = ``
    }
  },
  methods: {
    ...mapActions('projectModule', ['fetchProject', 'fetchProjects', 'deleteProject', 'requestAHardQuote', 'updateBuildingQuote']),
    projectPassesFilter(project){

        switch (this.archiveFilter) {
          case 'archived':
            if (!project.archive) return false;
            break;
          case 'active':
            if (project.archive) return false;
            break;
          case 'all':
            // Show all projects regardless of archive status
            break;
          default:
            console.warn('Invalid archiveFilter value. Using "active" as default.');
            if (project.archive) return false;
        }

        if(this.filter.values.lvl1EntityId!=null){
          if(project.lvl1User == null || project.lvl1User.businessId != this.filter.values.lvl1EntityId)
            return false
        }
        if(this.filter.values.lvl2EntityId!=null){
          if(project.lvl2User == null || project.lvl2User.businessId != this.filter.values.lvl2EntityId)
            return false
        }

        if(this.searchQuery){
          let projectName =  project.name ? project.name.toLowerCase() : '';
          let lvl1UserName = project.lvl1User ? project.lvl1User.name.toLowerCase() : '';
          let lvl2UserName = project.lvl2User ? project.lvl2User.name.toLowerCase() : '';
          console.log(`project: ${projectName}, lvl1: ${lvl1UserName}, lvl2: ${lvl2UserName}`)

          console.log(`project: ${projectName.includes(this.searchQuery)}, lvl1: ${lvl1UserName.includes(this.searchQuery)}, lvl2: ${lvl2UserName.includes(this.searchQuery)}`)

          return (
            projectName.includes(this.searchQuery) 
            || lvl1UserName.includes(this.searchQuery)
            || lvl2UserName.includes(this.searchQuery)
          )
        }
         

        return true
      
    },
    formattedPrice(val){
      return Util.formattedPrice(val);
    },
    formattedWeight(val){
      return Util.formattedWeight(val);
    },
    updateSort(key) {
      if (this.sortBy === key) {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc'
      } else {
        this.sortBy = key
        this.sortOrder = 'asc'
      }
    },
    notDesigned(project){
      project.design === "";
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString()
    },
    goToProjectDetail(projectRef) {
      this.$router.push(`/project/${projectRef}`)
    },
    createNewProject() {
      this.$router.push('/project/new')
    },
    adjustQuote(ref){
      this.qaData.ref = ref;
      this.qaData.show = true;
    },
    async deleteQuote(project){
      await this.$modal.show('dialog', {
          title: 'Confirmation',
          text: `Are you sure you want to permanently delete this design?`,
          buttons:[
            {
              title: 'Delete',
              handler: async () => {
                let response = await this.deleteProject(project);
                this.projects = await this.fetchProjects();
                this.$toast.success(`Deleted project "${project.name}"`);
                this.$modal.hide('dialog')
              },
            },
            {
              title: 'Cancel',
              handler: async () => {
                this.$modal.hide('dialog')
              }
            },
          ]
      })
     
    },
    async updateProjectList(){
      this.projects = await this.fetchProjects();
    },
    editDesign(ref){
      //project/dkj523/design
      this.$router.push(`/project/${ref}/design`)        
    },
    async shareProject(ref){
      await navigator.clipboard.writeText('');
      try {
        // Generate the shareable link (you may need to adjust this based on your routing structure)
        const shareableLink = `${process.env.VUE_APP_API_BASE_URL}/share/${ref}`;
        
        // Copy the link to clipboard
        await navigator.clipboard.writeText(shareableLink);
        
        // Show a toast notification
        this.$toast.success(`Share link copied to clipboard!`);
      } catch (err) {
        console.error('Failed to copy link:', err);
        this.$toast.error('Failed to copy link. Please try again.');
      }
    },
    async requestHardQuote(reference){
      let response = await this.requestAHardQuote(reference);

      if(response.isError){
        this.$toast.error(`Failed to request hard quote. ${response.data.msg}`, {timeout: 10000});
      }
      else{
        this.$toast.success(`Hard quote requested!`);
      }
    },
    async openProjectFolder(ref){
      let project = this.getProjectFromList(ref);
      let link = "file:///C:/ProgramData/SteelNinja/Projects/abb84"
      window.open(project.projectDirectoryLink);
    },
    copyProject(ref){
      this.copyData.ref = ref;
      this.copyData.show = true;
    },
    async archiveProject(ref){
      let project = this.getProjectFromList(ref);
      project.archive = true;
      await this.commitDetails(project);
      //this.projects = await this.updateProjectList();

    },
    async commitDetails(project){
      this.input.error = {
          msg:'',
          show: false
      }

      this.saveInProgress = true;
      let response;
      response = await this.updateBuildingQuote(project);

      if(response.isError){ 
        this.$toast.error(`Failed to archive project "${project.name}""`);
        //this.commitDetailsError(response.data)
      }
      else{
        this.saveInProgress = false;
        this.$toast.success(`Successfully archived project "${project.name}"`);
        // console.log(response);
       }
    },
    commitDetailsError(errData){
      if(errData.locked){
        this.showAlert_QuoteTemplateLocked();
      }
      else{
        this.$modal.show('dialog', {
            title: 'Warning',
            text: `${errData.msg}`,
            buttons:[
              {
                title: 'OK',
                handler: () => {
                  this.$modal.hide('dialog')
                }
              },
            ]
        })
      }
        
    },
    showAlert_QuoteTemplateLocked(){
      this.$modal.show('dialog', {
          title: 'Warning',
          text: `Could not complete the operation becasue this design's quote file is locked. Please close the quote file and try again.`,
          buttons:[
            {
              title: 'OK',
              handler: () => {
                this.$modal.hide('dialog')
              }
            },
          ]
      })
    },
    getProjectFromList(ref){
      return this.projects.find(project => project.reference == ref);
    },
    
    async loadFilter_Lvl1Entities(){      
      this.filter.options.lvl1Entities = await api.getAllSuppliers();
    },
    async loadFilter_Lvl2Entities(){
      if(this.filter.values.lvl1EntityId)
      this.filter.options.lvl2Entities = await api.getSubBusinesses(this.filter.values.lvl1EntityId);
      else
      this.filter.options.lvl2Entities = await api.getAllContractors();
    },
    loadFilterEntities(){
      this.filter.options.lvl1Entities=[];
      this.filter.options.lvl2Entities=[];
      switch(this.userBusiness.type){
        case 0:
          // enumerate lvl2 entities into values
          this.loadFilter_Lvl1Entities();
          this.loadFilter_Lvl2Entities();
          break;
        case 1: // user works for a supplier
          // enumerate lvl2 entities into values
          this.loadFilter_Lvl2Entities();        
          break;
        case 2: // user works for a contractor
          // no entity filtering capability
          break;
      }
    }
  }, 
  async created(){

    let redirectUrl = sessionStorage.getItem('redirectUrl')
      if (redirectUrl && redirectUrl != '/') {
        console.log('in dashboard, redirecting to: ', redirectUrl)
        sessionStorage.removeItem('redirectUrl');
        this.$router.push({ path: redirectUrl }) // relative path
      }
      
    this.dictionary = new Dictionary();
    await ContextLogic.initContext(this);
    //let sub = this.current.mode === CORE.modes.system ? null : this.$auth.user.sub;

    this.projects = await this.fetchProjects(this.userBusiness.id);
    this.loading=false;

    this.isAuthenticated = this.$auth.isAuthenticated;
    
    if(this.isAuthenticated){              
        let swFeatureflag = this.userBusiness.featureFlags.find(flag => flag.name == 'isSouthwestMetal');
        this.isSouthWest = (swFeatureflag) ? swFeatureflag.enabled : false;
    }

    

    this.loadFilterEntities();
  }
}
</script>

<style lang="scss" scoped>

</style>

